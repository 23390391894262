import React, {Component} from 'react';
import '../../styles/eventmanager.css';
import Header from '../header';
import Footer from '../footer';
import EventSideMenu from '../eventManager/sideMenu';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import '../../styles/eventMobileMenu.css';
import { Link } from "react-router-dom";

class EventPromote extends Component {
  render () {
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div>
        {/*isLogged || <Redirect to="/"/>*/}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        <div className="row eventmanager-row">
          <div className="col-sm-3 ">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu" className="list-group-item " data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2" id="sub-menu">
                    <Link to="/eventover" href="#" className="list-group-item " data-parent="#sub-menu">Overview</Link>
                    <Link to="/eventreport" href="#" className="list-group-item" data-parent="#sub-menu">Report</Link>
                    <Link to="/eventorders" href="#" className="list-group-item" data-parent="#sub-menu">Manage Attendance</Link>
                </div>
              
                <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item">Edit Information<span className="caret"></span></a>
                  <div className="collapse list-group-level" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div>
                <Link to="/eventquestions" href="#" className="list-group-item">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item  active s__active" >Promote Event <span className="caret"></span></Link>
                 <Link to="/eventsettings" href="#" className="list-group-item">Additional Settings</Link>
                 
            </div>
            </div>
          </div>
          <div className="col-sm-9 event-questions-container">
          <h2>Social deals</h2>
          <p>Reach more fans by offering discount for social sharing.</p>
            <div className="event-promote">
              <div className="event-promote__bg">
                <i className="fab fa-facebook-f event-promote__social event-promote__social-fb" />
                <h4>Facebook</h4>
              </div>
              <p>
                Customize your message and choose the discount amount you will offer. This deal will appear during checkout.
              </p>
              <button className="btn btn-white-main">Share</button>
            </div>
            <div className="event-promote">
              <div className=" event-promote__bg">
                <i class="fab fa-twitter event-promote__social event-promote__social-tw" />
                <h4>Twitter</h4>
              </div>
              <p>
              Customize your tweet and choose the discount amount you will offer. This deal will appear during checkout.
              </p>
              <button className="btn btn-white-main">Share</button>
            </div>
            <div className="event-promote">
              <div className="event-promote__bg">
                <i class="fab fa-google-plus-g event-promote__social event-promote__social-g" />
                <h4>Google+</h4>
              </div>
              <p>
                Choose the discount amount you will offer. This deal will appear during checkout.
              </p>
              <button className="btn btn-white-main">Share</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (EventPromote);