import axios from 'axios';
import env from '../../env.js';

const getOneEvent = (url) => ({
  type:'GET_ONE_EVENT',
  payload:axios(url)
})

export const onGetOneEvent = (id) => async (dispatch) => {
  const url=`${env(true)}event/${id}`
  
  dispatch(getOneEvent(url))
}