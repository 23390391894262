import React, { Component } from 'react';
import $ from 'jquery';
import Header from '../components/header';
import Footer from '../components/footer';
import axios from 'axios';

export default class DiscountCode extends Component {
  constructor(props){
    super(props);
    this.state={
      email:'',
    name:'',
    submitted: false,
    age: '',
    discount: '',
    gender: '',
    count: '',
    phone: '',
    instagram: '',
    twitter: ''
    }
   }

  //  handleChange = (event) => {
		
	// 	this.setState({gender: event.target.value});
	// 	// console.log(this.state.gender)
	//   }

   handleClick = (event)=> {
    this.setState({ submitted: true });
    event.preventDefault();
    console.log(this.state)
    if(this.state.age != 0 && this.state.name != 0 && this.state.discount != 0 && this.state.gender && this.state.count != 0 && this.state.phone != 0){
    // var apiBaseUrl = "http://localhost:4000/api/";
     var apiBaseUrl = "https://www.nairabox.net/api/";
    var self = this;
    var payload={
    "age":this.state.age,
    "fullname":this.state.name,
    "discountCode": this.state.discount,
    "gender": this.state.gender,
    "count": this.state.count,
    "phone": this.state.phone,
    "instagram": this.state.instagram,
    "twitter": this.state.twitter
    }
    axios.post(apiBaseUrl+'discount', payload)
    .then(function (response) {
   // console.log(response);
    if(response.data.status === 404){
   // console.log("Transaction not found");
    alert(response.data.message)
    }
    else if(response.data.status === 200){
    alert(response.data.message)
    }
    })
    .catch(function (error) {
    console.log(error);
    });
  }
    }

    handleChange = event => {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  
    componentDidMount = () => {
      var formInputs = $ (
        'input[type="email"], input[type="text"], input[type="password"]'
      );
      formInputs.focus (function () {
        $ (this).parent ().children ('p.formLabel').addClass ('formTop');
        $ ('div#formWrapper').addClass ('darken-bg');
        $ ('div.logo').addClass ('logo-active');
      });
      formInputs.focusout (function () {
        if ($.trim ($ (this).val ()).length == 0) {
          $ (this).parent ().children ('p.formLabel').removeClass ('formTop');
        }
        $ ('div#formWrapper').removeClass ('darken-bg');
        $ ('div.logo').removeClass ('logo-active');
      });
      $ ('p.formLabel').click (function () {
        $ (this).parent ().children ('.form-style').focus ();
      });
    };
	
	render() {
    const { age, name, submitted, value, discount,gender,count,phone,email,instagram,twitter } = this.state;
		return <div>
        <Header />

        <div className="container">

            <div className="col-12 col-sm-8 m-auto">
                <div className="mb-4 mt-5">
                <h4 className="pt-4 pb-2 mt-5 text-white">Add Discount Code Information</h4>
                
                <form onSubmit={this.handleClick}>
          <div className="row">
          <div className="col-sm-12" >
            <div  className={'form-item' + (submitted && !name ? ' text-danger' : '')}>
              <p className="formLabel">Name</p>
              <input
                type="text"
                name="name"
                id="name"
                className="form-style"
                autocomplete="off"
                value={name}
                onChange={this.handleChange}
              />
               {submitted && !name &&
                            <div className="help-block">Name is required</div>
                        }
            </div>
            </div>
            <div className="col-sm-12">
            <div  className={'form-item' + (submitted && !email ? ' text-danger' : '')}>
              <p className="formLabel">Email Address</p>
              <input
                type="email"
                name="email"
                id="email"
                className="form-style"
                autocomplete="off"
                value={email}
                onChange={this.handleChange}
              />
               {submitted && !email &&
                            <div className="help-block">Email is required</div>
                        }
            </div>
            </div>
            </div>

            <div className="row">
          <div className="col-sm-6">
          <div  className={'form-item' + (submitted && !age ? ' text-danger' : '')}>
              <p className="formLabel">Age</p>
              <input
                type="text"
                name="age"
                id="age"
                className="form-style"
                autocomplete="off"
                value={age}
                onChange={this.handleChange}
              />
               {submitted && !age &&
                            <div className="help-block">Age is required</div>
                        }
            </div>
            </div>
            <div className="col-sm-6">
            <div  className={'form-item' + (submitted && !gender ? ' text-danger' : '')}>
              
              <select class="form-control"
               value={this.state.gender}
               name="gender"
              
               onChange={this.handleChange}
               id="disCode">
                 <option value="">Select Gender </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          {submitted && !gender &&
                            <div className="help-block">Gender is required</div>
                        }
            </div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12">
            <div  className={'form-item' + (submitted && !discount ? ' text-danger' : '')}>
            <p className="formLabel">Enter Discount Code</p>
              <input
                type="text"
                name="discount"
                id="discount"
                className="form-style"
                autocomplete="off"
                value={this.state.discount}
                onChange={this.handleChange}
              />
               {submitted && !discount &&
                            <div className="help-block">Discount code is required</div>
                        }
            </div>
            </div>
            <div className="col-sm-12">
            <div  className={'form-item' + (submitted && !phone ? ' text-danger' : '')}>
            <p className="formLabel">Enter Phone number</p>
              <input
                type="number"
                name="phone"
                id="phone"
                className="form-style"
                autocomplete="off"
                value={this.state.phone}
                onChange={this.handleChange}
              />
               {submitted && !phone &&
                            <div className="help-block">Phone number is required</div>
                        }
            </div>
            </div>
            <div className="col-sm-12">
            <div  className={'form-item' + (submitted && !count ? ' text-danger' : '')}>
            <p className="formLabel">Enter number of tickets</p>
              <input
                type="number"
                name="count"
                id="count"
                className="form-style"
                autocomplete="off"
                value={this.state.count}
                onChange={this.handleChange}
              />
               {submitted && !count &&
                            <div className="help-block">Quantity of tickets is required</div>
                        }
            </div>
            </div>
            <div className="col-sm-12">
            <div  className={'form-item' + (submitted && !instagram ? ' text-danger' : '')}>
            <p className="formLabel">Enter your instagram handle</p>
              <input
                type="text"
                name="instagram"
                id="instagram"
                className="form-style"
                autocomplete="off"
                value={this.state.instagram}
                onChange={this.handleChange}
              />
               {submitted && !instagram &&
                            <div className="help-block">Enter your instagram handle</div>
                        }
            </div>
            </div>
            <div className="col-sm-12">
            <div  className={'form-item' + (submitted && !twitter ? ' text-danger' : '')}>
            <p className="formLabel">Enter your twitter handle</p>
              <input
                type="text"
                name="twitter"
                id="twitter"
                className="form-style"
                autocomplete="off"
                value={this.state.twitter}
                onChange={this.handleChange}
              />
               {submitted && !twitter &&
                            <div className="help-block">Enter your twitter handle</div>
                        }
            </div>
            </div>
           </div>
           <button className="btn btn-ticket mb-5">Send</button>
          </form>
                </div>
            </div>
          



        </div>


        <Footer />
      </div>;
	}
}