import React, { Component } from 'react'
import Slider from 'react-slick'
import '../../styles/ap.css'
import { connect } from 'react-redux'
import Header from '../header'
import Footer from '../footer'
import { onGetSearchEvents } from '../../store/actions/getSearchEvents';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import getMonth from 'date-fns/get_month';
import Truncate from 'react-truncate';
import OwlCarousel from 'react-owl-carousel';
import moment from 'moment';

class Search extends Component {
  constructor(props) {
		super(props);

		this.state = {
			keyword: '',
      state: '',
      key: 'live',
		};

	}
  componentDidMount() {
    const keyword  = this.props.match.params.keyword;
    console.log('keyword', keyword + '');
    this.props.onGetSearchEvents(keyword);
  }
//  componentDidUpdate(prevProps, prevState) {
//   const keyword  = this.props.match.params.keyword
//   const state  = this.props.match.params.state
//   this.props.onGetSearchEvents(keyword,state)
//  }

  onEnter = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};
  render () {
    var settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 300,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    }
    const {searchEvents:{response: searchEventsResponse} } = this.props;

    console.log('searchEventsResponse', searchEventsResponse);

    const {keyword,state} = this.props
    const {key} = this.state
    return (

      <div className="innerSearch">
       <div className="mb-5"> <Header /></div>
        <div className='container-fluid searchContainer mb-5'>
          <div className='container-fluid'>
            <div className='categoryHeader row '>
						{/* <div className="col-sm-3 ">
							<h4 className="text-white">Search Result</h4>
						</div>
						<div className="col-sm-7 ">
							<form className="form-inline">
								<label className="sr-only" for="question">
									Question
									</label>
								<input
									type="text"
									className="form-control mb-2 search-home"
									id="question"
									name="keyword"
                  placeholder="What are you looking for?"
                  value={keyword}
									onChange={this.onEnter}
								/>

								<label className="sr-only" for="location">
									Location
									</label>
								<div className="input-group mb-2 homesearch-input">
									<div className="input-group-prepend">
										<div className="input-group-text">
											<img
												className="search-location"
												src="/image/location.svg"
												alt=""
												height="12"
											/>
										</div>
									</div>
									<input
										type="text"
										className="form-control search-placeholder search-home"
										id="location"
										name="state"
                    value="Lagos, NG"
                    value={state}
									onChange={this.onEnter}
									/>
								</div>
								<button type="search" className="btn src-btn btn-primary mb-2 search-button" >
								<Link  to={(`/search/${key}/${sta}`)}>
								Search
												</Link>
								</button>
							</form>
						</div> */}
						{/* <div className="col-sm-2">
							<div class="form-group ">
								<select className="custom-select" required>
									<option value="">All Category</option>
									<option value="1">Music</option>
									<option value="2">Art</option>
									<option value="3">Comedy</option>
								</select>
							</div>
						</div> */}
					</div>
          </div>
          <div className='container-fluid'>
            <div className='row divider'>

              {searchEventsResponse.length > 0 && searchEventsResponse.map((value, key) => (
						<div className='col-12 col-sm-6 col-md-3 mb-3 bottomSpace'>

                <div className='card event-card ' key={key}>
                  <div className='card-image-container'>
                    <img className='card-image' src={value.coverImage} alt='Events in Nigeria' />
                    <div className='details-section'>
                      <div className='event-date'>
                      <span className="event-date__day">
                            {new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')).getDate()}{' '}
												</span>
												<span className="event-month">
                            {format(new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')), 'MMM')}
												</span>{' '}
												{' '}
                      </div>
                      <div className='other-info'>
                        <div className='event-details'>
                          <Link className='event-name' to={`/event/${value._id}`}>
														<Truncate
															lines={1}
															width={220}
															ellipsis={<span className="event-title">...</span>}
														>
															{value.name}
														</Truncate>
													</Link>
                          <div className='clearfix mt-2'>
                            <p className='d-inline float-left mr-2 event-icon'>
                              {' '}
                              <img src='/image/locations.svg' alt='event-location' height='15' />
                            </p>
                            <p className='d-inline'>
                            {value.state}
                            </p>
                          </div>
                          <div className='clearfix'>
                            <p className='d-inline float-left mr-2 event-icon'>
                              {' '}
                              <img src='/image/price-tag-white.svg' alt='event-location' height='15' />
                            </p>
                            <p className='d-inline'>
                              {' '} Starting from <span className='price'>{Number(value.starting_from).toLocaleString(`en-${(value.currency || 'NGN').substring(0, 2)}`, {
                                style: 'currency',
                                currency: (value.currency || 'NGN'),
                                maximumFractionDigits: 2
                            })}  </span>
                            </p>
                          </div>
                          <Link to={`/event/${value._id}`} className="btn btn-ticket mb-3">
														Buy Ticket
													</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              ))}

              { searchEventsResponse.hasOwnProperty('message') }
              <div className='col-12 col-sm-6 col-md-3 mb-3 bottomSpace' style={{'color':'#FFFFFF'}}>
                { searchEventsResponse.message }
              </div>
            </div>
            {/* <div className='pt-2 pb-1 pagination-m'>
              <nav aria-label='Page navigation example'>
                <ul className='pagination'>
                  <li className='page-item'>
                    <a className='page-link' href='#' aria-label='Previous'><span aria-hidden='true'>«</span> <span className='sr-only'>Previous</span></a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' href='#'>1</a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' href='#'>2</a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' href='#'>3</a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' href='#' aria-label='Next'><span aria-hidden='true'>»</span> <span className='sr-only'>Next</span></a>
                  </li>
                </ul>
              </nav>
            </div> */}
            {/* second row */}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  searchEvents: state.searchEvents,
  keyword: state.keyword,
  // state: state.state
})

const mapDispatchToProps = (dispatch) => ({
  onGetSearchEvents: bindActionCreators(onGetSearchEvents, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search) )
