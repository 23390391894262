import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../styles/ap.css';
import Header from '../loggedInHeader';
import Footer from '../footer';

class NotFound extends Component {
	render() {
		var settings = {
			dots: true,
			infinite: true,
			autoplay: true,
			speed: 300,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1
		};
		return (
			<div id="notFound" className="innerSearch">
				<Header />
				<div className="container-fluid mb-5">
					<div className="container-fluid">
						<div className="categoryHeader row ">
							<div className="col-sm-3 ">
								<h4 className="text-white">Search not found</h4>
							</div>
							<div className="col-sm-7 ">
								<form className="form-inline">
									<label className="sr-only" for="question">
										Question
									</label>
									<input
										type="text"
										className="form-control mb-2 search-home"
										id="question"
										name="keyword"
										placeholder="What are you looking for?"
									/>

									<label className="sr-only" for="location">
										Location
									</label>
									<div className="input-group mb-2 homesearch-input">
										<div className="input-group-prepend">
											<div className="input-group-text">
												<img
													className="search-location"
													src="/image/location.svg"
													alt=""
													height="12"
												/>
											</div>
										</div>
										<input
											type="text"
											className="form-control search-placeholder search-home"
											id="location"
											name="state"
											value="Lagos, NG"
										/>
									</div>
									<button type="search" className="btn src-btn btn-primary mb-2 search-button">
										Search
									</button>
								</form>
							</div>
							<div className="col-sm-2">
								<div class="form-group ">
									<select className="custom-select" required>
										<option value="">All Category</option>
										<option value="1">Music</option>
										<option value="2">Art</option>
										<option value="3">Comedy</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="notFound">
							Unfortunately we can't match an event to your criteria. Try changing some filters to get
							more results.
						</div>

						<a href="" className="btn btn-main mt-3">
							Sell All Event
						</a>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default NotFound;
