import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../styles/homepage.css';
import Header from '../components/header';
import LoggedInHeader from '../components/loggedInHeader';
import Slide from '../components/home/slider';
import HomeSearch from '../components/home/homesearch';
import PopularEvents from '../components/home/popularevents';
import TrendingEvents from '../components/home/trendingevents';
import FreeEvents from '../components/home/freeEvents';
import Footer from '../components/footer';
import Categories from '../components/home/categories';
import Partners from '../components/home/partners';
import PartnersTwo from '../components/home/partnerstwo';
import PartnersThree from '../components/home/partnersthree';
import PartnersFour from '../components/home/partnersfour';
import Merch from '../components/home/merch';
import { connect } from 'react-redux';
import { onLogin } from '../store/actions/login';
import { onRegister } from '../store/actions/register';
import { bindActionCreators } from 'redux';
import { onGetEvents } from '../store/actions/getEvents';
import { onGetPopularEvents } from '../store/actions/getPopularEvents';
import { onGetFreeEvents } from '../store/actions/getFreeEvents';
import { onGetTrendingEvents } from '../store/actions/getTrendingEvents';
import Loader from '../components/loader'
import '../styles/ap.css';
import $ from 'jquery';

class Homepage extends Component {
	state = {
		loginModal: false,
		signupModal: false,
		Log: ''
	};

	toggl = () => {
		this.setState({
			loginModal: !this.state.loginModal
		});
	};
	toggla = () => {
		this.setState({
			signupModal: !this.state.signupModal
		});
	};
	componentDidMount() {
		this.props.onGetEvents();
		this.props.onGetTrendingEvents();
		this.props.onGetPopularEvents();
		this.props.onGetFreeEvents();

		this.setState({ Logged: localStorage.getItem('token') });
	}
	render() {
		if (this.state.Logged) {
			// this.setState({Log: true})
		}
		const { loginModal, signupModal } = this.state;
		var {
			login: { isLogged },
			//fetching: {fetching},
			register: { isLoggedIn },
			trendingEvents: { response: trendingEventsResponse, fetching },
			popularEvents: { response: popularEventsResponse },
			freeEvents: { response: freeEventsResponse },

		} = this.props;
		console.log(this.props)
		console.log(this.props.register.response.user)
		if (isLoggedIn) {
			//	isLogged = true
		}
		return (
			<div>



				{/*events={eventsResponse}*/}
				{fetching
					? (fetching ? <Loader /> : <Loader />)
					: <div style={{ opacity: fetching ? 0.5 : 1 }}>
						{(isLogged || isLoggedIn) ? (
							<LoggedInHeader />
						) : (
								<Header
									loginModal={loginModal}
									signupModal={signupModal}
									toggl={this.toggl}
									toggla={this.toggla}
									isLogged={isLogged}
								//	isLoggedIn={isLoggedIn}
								/>
							)}
						<Slide events={popularEventsResponse} />
						<HomeSearch />
						<PopularEvents events={popularEventsResponse} toggl={this.toggl} isLogged={isLogged} />
						<TrendingEvents events={trendingEventsResponse} toggl={this.toggl} isLogged={isLogged} />
						<FreeEvents events={freeEventsResponse} toggl={this.toggl} isLogged={isLogged} />
						<div className="row">
							<div className="col-md-4 offset-4 d-flex align-items-center justify-content-center">
								<Link to="category"><button className="btn btn-main">Show All Events</button></Link>
							</div>
						</div>

						<Categories />
						<div className="">
							<Partners />
						</div>
						{/* <div class="foot">
							<a href="https://nokia.ly/2Vfr9Vb"><img className="img-responsive" target="_blank" src="/image/Nokia 4.2 GDN 728x90.jpg" /> </a>
							<a class="footclose closebtn" href="#">CLOSE</a>
						</div> */}
						<Footer />

					</div>
				}

				{/* <Merch freeEvents={freeEventsResponse} />*/}

				{/*<div className="partners-two">
        <PartnersTwo />
        </div>
        <div className="partners-three">
        <PartnersThree />
        </div>
        <div className="partners-four">
        <PartnersFour />
        </div> */}

			</div>
		);
	}
}

//export default Homepage;

const mapStateToProps = (state) => ({
	events: state.events,
	login: state.login,
	register: state.register,
	popularEvents: state.popularEvents,
	freeEvents: state.freeEvents,
	trendingEvents: state.trendingEvents
});

const mapDispatchToProps = (dispatch) => ({
	onGetEvents: bindActionCreators(onGetEvents, dispatch),
	onGetPopularEvents: bindActionCreators(onGetPopularEvents, dispatch),
	onGetFreeEvents: bindActionCreators(onGetFreeEvents, dispatch),
	onGetTrendingEvents: bindActionCreators(onGetTrendingEvents, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
