import axios from 'axios';
import env from '../../env.js';

const updateEventClass = (url) => ({
  type:'UPDATE_EVENT_CLASS',
  payload:axios.put(url)
})

export const onUpdateEventClass = () => async (dispatch) => {
  const url=`${env(true)}events-class/:id`
  dispatch(updateEventClass(url))
}