import React, {Component} from 'react';
import '../../styles/eventmanager.css';
import Header from '../loggedInHeader';
import Footer from '../footer';
import EventSideMenu from '../eventManager/sideMenu';
import {connect} from 'react-redux';
import { onGetOverviewEvent } from '../../store/actions/getOverviewEvent';
import { withRouter } from "react-router";
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import '../../styles/eventMobileMenu.css';
import  Loader  from '../../components/loader'

class EventOverview extends Component {
  componentDidMount() {
    const merchantID  = this.props.match.params.merchantID
   // console.log(merchantID)
    this.props.onGetOverviewEvent(merchantID)
  }
  render () {
    const {login:{isLogged}, register:{isLoggedIn}, overviewEvent: {response: overviewResponse, fetching}}=this.props;
     console.log(fetching)
    return (
      <div>
      	{fetching
  ?  <Loader /> 
  : <div style={{ opacity: fetching ? 0.5 : 1 }}>

   {isLogged || isLoggedIn ?
        <Header /> :
        <Header />}
        <div className="row eventmanager-row">
          <div className="col-sm-3 ">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu" className="list-group-item active" data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2 show" id="sub-menu">
                    {/* <Link to="/eventover" href="#" className="list-group-item s__active" data-parent="#sub-menu">Overview</Link> */}
                    {/* <Link to="/eventreport" href="#" className="list-group-item" data-parent="#sub-menu">Report</Link> */}
                    <Link to={`/eventorders/${this.props.match.params.merchantID}`}  href="#" className="list-group-item" data-parent="#sub-menu">Manage Attendance</Link>
                </div>
              
                {/* <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item ">Edit Information<span className="caret"></span></a> */}
                  {/* <div className="collapse list-group-level" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div> */}
                {/* <Link to="/eventquestions" href="#" className="list-group-item">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item" >Promote Event <span className="caret"></span></Link>
                <Link to="/eventsettings" href="#" className="list-group-item">Additional Settings</Link> */}
                
            </div>
            </div>
          </div>
          <div className="col-sm-9 mt-5  eventmanager-container">
          {/* <div className="">
            <ul className="eventmanager-navbar">
              <li className="eventmanager-navbar__unpublish">UNPUBLISH</li>
              <li className="eventmanager-navbar__items">CANCEL</li>
              <li className="eventmanager-navbar__items">DELETE</li>
            </ul>
          </div> */}
            <h3 className="eventmanager-title">Event Overview</h3>
            <div className="eventmanager-body">
              <div className="row section-one">
                <div className="col-sm-6 section-one__a">
                  <div className="section-one__a-text">
                    <p>Net Sales</p>
                    <h2>₦ {(overviewResponse.net_sales) ? new Intl.NumberFormat('en-GB', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
}).format(overviewResponse.net_sales) : <h2>undefined</h2>  }</h2>
                  </div>
                </div>
                <div className="col-sm-6 section-one__b">
                  <div className="row section-one-two">
                    <div className="col-sm-6 section-one-two__a">
                      <p>Total Number of Views</p>
                      <h2> {(overviewResponse.views) ? overviewResponse.views : <h2>0</h2>  }</h2>
                    </div>
                    <div className="col-sm-6 section-one-two__b">
                      <p>Total Tickets Sold</p>
                      <h2> {(overviewResponse.tickets_sold) ? new Intl.NumberFormat('en-GB', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
}).format(overviewResponse.tickets_sold) : 0 }</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row section-two container-fluid">
                {/* <div className="col-sm-6 section-two__a  dashboardCard2 ">
                  <h5>Tickets Sold</h5>
                  <div className="section-two__a-body">
                    <img
                      className="section-two__a-image"
                      src="/image/analytics.png"
                      alt=""
                    />
                    <p className="section-two__a-text">
                      Once you have sold tickets to your event, a table would be displayed here
                    </p>
                  </div>
                </div> */}
                <div className="col-sm-12 noLeft section-two__b dashboardCard2">
                  <h5>Sales by ticket type</h5>
                  
                  <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Sales difference</th>
                        <th scope="col">Status</th>
                        <th scope="col">End</th>
                        <th scope="col">Total sales</th>
                      </tr>
                    </thead>
                  <tbody>
                  {(overviewResponse.ticketsReport) ? overviewResponse.ticketsReport.map((value, key) =>
                    <tr>
                      <td>{value.name}</td>
                      <td>{value.sold} of {value.limit} sold</td>
                      <td>On-Sale</td>
                      <td>{overviewResponse.date}</td>
                      <td>₦ {(value.sales) ? new Intl.NumberFormat('en-GB', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
}).format(value.sales) : 0  }</td>
                    </tr>
                    ) : <h2>No sales</h2>  }
                  
                  </tbody>
                  </table>
                  </div>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </div>
}
      {/*isLogged || <Redirect to="/"/>*/}
       
                  
      </div>
    );
  }
}
const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
    overviewEvent: state.overviewEvent
});

const mapDispatchToProps = (dispatch) => ({
	onGetOverviewEvent: bindActionCreators(onGetOverviewEvent, dispatch)
});

export default withRouter(connect (mapStateToProps, mapDispatchToProps) (EventOverview));


