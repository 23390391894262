import React, {Component} from 'react';
import $ from 'jquery';
import '../styles/contactus.css';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/formtype.css';

export default class FAQ extends Component {
  componentDidMount = () => {
    var formInputs = $ (
      'input[type="email"], input[type="text"], input[type="password"]'
    );
    formInputs.focus (function () {
      $ (this).parent ().children ('p.formLabel').addClass ('formTop');
      $ ('div#formWrapper').addClass ('darken-bg');
      $ ('div.logo').addClass ('logo-active');
    });
    formInputs.focusout (function () {
      if ($.trim ($ (this).val ()).length == 0) {
        $ (this).parent ().children ('p.formLabel').removeClass ('formTop');
      }
      $ ('div#formWrapper').removeClass ('darken-bg');
      $ ('div.logo').removeClass ('logo-active');
    });
    $ ('p.formLabel').click (function () {
      $ (this).parent ().children ('.form-style').focus ();
    });
  };
  render () {
    return (
      <div>
        <Header/>
        <div className="background mb-5" >
					<div className="layer">
						<div className="faq-background-text">
							<h1>Get in touch</h1>
							<p>Questions, comments and feedback – let us know!</p>
						</div>
					</div>
					<div className="contact-background-image" />
				</div>
        <div className="col-12 col-sm-8 m-auto">
          <div className="row mb-4">
            <div className="col-12 col-sm-4 ">
              <div className="d-inline-block align-top">
              <img src="/image/home.png" height="30" className="" />
              </div>
              <div className="d-inline-block ml-2">
                <p className="text-white">Jorg Technologies Limited</p>
                <p className="addressC text-white">Lekki Phase 1, Lagos, Nigeria</p>
              </div>
            </div>
            <div className="col-12 col-sm-4 ">
              <div className="d-inline-block align-top">
              <img src="/image/call.png" height="30" />
              </div>
              <div className="d-inline-block ml-2">
                <p className="text-white">+234 90 800 00381</p>
              </div>
            </div>
            
            <div className="col-12 col-sm-4 ">
              <div className="d-inline-block align-top">
              <img src="/image/mail.png" height="30" />
              </div>
              <div className="d-inline-block ml-2">
                <p className="text-white">support@nairabox.com</p>
              </div>
            </div>




          </div>
          <div>
          <form>
          <div className="row">
          <div className="col-sm-6">
            <div className="form-item">
              <p className="formLabel">First Name</p>
              <input
                type="email"
                name="email"
                id="email"
                className="form-style"
                autocomplete="off"
              />
            </div>
            </div>
            <div className="col-sm-6">
            <div className="form-item">
              <p className="formLabel">Last Name</p>
              <input
                type="email"
                name="email"
                id="email"
                className="form-style"
                autocomplete="off"
              />
            </div>
            </div>
            </div>

            <div className="row">
          <div className="col-sm-6">
            <div className="form-item">
              <p className="formLabel">Email Address</p>
              <input
                type="email"
                name="email"
                id="email"
                className="form-style"
                autocomplete="off"
              />
            </div>
            </div>
            <div className="col-sm-6">
            <div className="form-item">
              <p className="formLabel">Phone Number</p>
              <input
                type="email"
                name="email"
                id="email"
                className="form-style"
                autocomplete="off"
              />
            </div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12">
            <div className="form-item">
            <p class="formLabel">Enter your message</p>
              <textarea 
              className="form-style col-12"
              
                name="message"
                id="c_message"
                rows="5"
                cols=""
                //placeholder="Enter your message"
                >

              </textarea>
            </div>
            </div>
           </div>
           <button className="btn btn-ticket mb-5">Send Message</button>
          </form>
          </div>
        </div>
     <Footer/>
      </div>
    );
  }
}
