import axios from 'axios';
import env from '../../env.js';

const getEventWeekly = (url) => ({
  type:'GET_EVENT_WEEKLY',
  payload:axios(url)
})

export const onGetEventWeekly = (id) => async (dispatch) => {
  const url=`${env(true)}merchant/weekly-events/${id}`
  dispatch(getEventWeekly(url))
}