import React, { Component } from 'react';
// import '../../styles/bootstrap.css'
import '../../styles/responsive.css';
import { withRouter } from "react-router";
import ReactDOM from 'react-dom'
 class Ticket extends Component {
	componentDidMount () {
		
		window.scrollTo(0, 0)
	  }
	
	  componentDidUpdate () {
	  
		ReactDOM.findDOMNode(this).scrollTop = 0
		window.scrollTo(0, 0)
	  }
	render() {
		const details = this.props.location.response.data.response
		// console.log(this.props.location.response.data.response)
		return (
			<div
				// class="modal fade"
				// id="downloadTicketModal"
				// tabindex="-1"
				// role="dialog"
				// aria-labelledby=" downloadTicketModalCenterTitle"
				// aria-hidden="true"
			>
			
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
					<div class="modal-header">
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								<div>
								<h5 class="modal-title" id="downloadTicketModalLongTitle">
									{details.event}
								</h5>
							</div>
							<div class="address">
								<p>{details.venue }  {' '}
								{ details.date}</p>
							</div>
							<div>
								<p class="ticket--info d-inline-block">
									TICKET INFORMATION <span>></span>{' '}
								</p>
								<p class="ticket-info d-inline-block">PAYMENT CONFIRMATION</p>
							</div>
						</div>
						<div class="modal-body">
							<div class="ticketInfo">
								<div class="d-inline mr-2">
									<img src="/image/sucess.png" alt="Events in Lagos" />
								</div>
								<div class="d-inline-block success-icon">Payment Successful</div>
								<p class="mt-3">
									Thank you, your payment has been successful and your ticket is now confirmed. A
									confirmation email containing your tickets has been sent to {' '}

									<span>
										<a href="">{ details.email }</a>{' '}
									</span>
								</p>
								<p> 
									<span class="Primary">
									Please contact <a href="">support@nairabox.com</a>{' '} if you do not receive a confirmation email
									containing your ticket 12 hours after payment was made.
									</span>
								</p>
								<div>
									<div class="d-inline-block">
										<div class="ticketTypeHeader">Your Order Summary</div>
									</div>
									<div class="d-inline-block ticketPrice mt-1 ">
										<div class="d-inline ">
											<img src="/image/printer.png" alt="Events in Lagos" />
										</div>
										<div class="d-inline-block ">
											<a href="">Reciept</a>{' '}
										</div>
									</div>
									<div class="border b-radius p-4 mt-3 mb-3">
										<div class="ticketInfo">
											<div class="border-bottom pb-3 ticketInfo">
												<div class="row pb-2 pt-2 ">
													<div class="col col-sm-6">Event</div>
													<div class="col col-sm-6 ticketPrice">{details.event}</div>
												</div>
												{/* <div class="row pb-2">
													<div class="col col-sm-6">1 x VIP Ticket</div>
													<div class="col col-sm-6 ticketPrice">N100,000.00</div>
												</div> */}
											</div>
											<div class="border-bottom pt-3 pb-3 ticketInfo">
												<div class="row pb-2 pt-2 ">
													<div class="col col-sm-6">Amount Paid</div>
													<div class="col col-sm-6 ticketPrice">{details.amount}</div>
												</div>
												{/* <div class="row pb-2">
													<div class="col col-sm-6">
														Service Charge <span>(online payment convenience fee)</span>
													</div>
													<div class="col col-sm-6 ticketPrice">N100.00</div>
												</div> */}
											</div>
											{/* <div class="row pb-2 pt-2 ">
												<div class="col col-sm-6">Total</div>
												<div class="col col-sm-6 ticketPrice">N150,100.00</div>
											</div> */}
											{/* <div id="ticket_image" style="margin:0 auto; text-align:center;">
								<img src="https://chart.googleapis.com/chart?chs=240x240&cht=qr&chl={details.ticketID}&chld=L|1&choe=UTF-8"  width="250" />
							</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Ticket)