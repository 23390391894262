import axios from 'axios';
import env from '../../env.js';

const getFreeEvent = (url) => ({
  type:'GET_FREE_EVENT',
  payload:axios(url)
})

export const onGetFreeEvents = () => async (dispatch) => {
  const url=`${env(true)}events/free`
  dispatch(getFreeEvent(url))
}