import axios from 'axios';
import env from '../../env.js';

const updateEvent = (url) => ({
  type:'UPDATE_EVENT',
  payload:axios.put(url)
})

export const onUpdateEvent = () => async (dispatch) => {
  const url=`${env(true)}events/:id`
  dispatch(updateEvent(url))
}