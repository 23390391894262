import React, { Component } from 'react'
import ReactDOM from 'react-dom'
// import '../App.css'
import '../styles/responsive.css'
// import '../styles/bootstrap.css'
import Header from '../components/header'
import Footer from '../components/footer'
import Background from '../components/events/eventbackground'
import Description from '../components/events/eventdescription'
import AddInfo from '../components/events/addinfo'
import SimEvents from '../components/events/simevents'
import '../styles/eventdetails.css'
import { connect } from 'react-redux'
import { onGetOneEvent } from '../store/actions/getOneEvent'
import { bindActionCreators } from 'redux'
import { onLogin } from '../store/actions/login'
import { onRegister } from '../store/actions/register'
import LoggedInHeader from '../components/loggedInHeader'
import { Redirect } from 'react-router-dom'
import { getSimilarEvents } from '../store/actions/getSimilarEvents';
import Loader from '../components/loader'
import axios from 'axios';
import env from '../env.js';

class Events extends Component {
  state = {event: {}, classes: [] }

  componentDidMount = async () => {
    localStorage.removeItem('state')
    const { eventID } = this.props.match.params
    const search = new URLSearchParams(this.props.location.search);
    window.sessionStorage && window.sessionStorage.setItem('affiliate', search.get('affiliate'));

    try{
      let res = await axios.get(`${env(true)}event/${eventID}`)
      this.setState(res.data)

    }catch(e){

    }

    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    const { oneEvent } = this.props

    console.log("------------------------------------------------------------------------", oneEvent)
    if(oneEvent.response.event && oneEvent.response.event.length ){
      let event = oneEvent.response.event[0];
      console.log("::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::", oneEvent)
      if(!event.available){
        window.location.href = "https://events.nairabox.com"
      }
    }

    ReactDOM.findDOMNode(this).scrollTop = 0
    window.scrollTo(0, 0)
  }
  render() {
    // const {login:{isLogged}}=this.props
    const { login: { isLogged }, register: { isLoggedIn }, oneEvent, similarEvents, oneEvent: { fetching } } = this.props
    console.log("IIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII", oneEvent)
    return (
      <div>

        {isLogged || isLoggedIn ? <LoggedInHeader /> : <Header />}
        
        <Background event={this.state.event} />
        
          <Description event={this.state.event} classes={this.state.classes} />
        
        <div className='container-fluid number1'>
          <section id='additionalInfo'>
            <AddInfo />
            <SimEvents similarEvents={similarEvents} />
          </section>
        </div>
        <Footer />

      </div>
    )
  }
}

const mapStateToProps = state => ({
  oneEvent: state.oneEvent,
  login: state.login,
  register: state.register,
  similarEvents: state.similarEvents
})

const mapDispatchToProps = (dispatch) => ({
  onGetOneEvent: bindActionCreators(onGetOneEvent, dispatch),
  getSimilarEvents: bindActionCreators(getSimilarEvents, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Events)
