import { combineReducers } from 'redux';
import events from './events';
import login from './login';
import register from './register';
import createEvent from './createEvent';
import popularEvents from './popularEvents';
import freeEvents from './freeEvents';
import oneEvent from './oneEvent';
import updateEvent from './updateEvent';
import updateEventClass from './updateEventClass';
import liveEvents from './liveEvents';
import pendingEvents from './pendingEvents';
import weeklyEvent from './weeklyEvent';
import monthlyEvent from './monthlyEvent';
import draftEvents from './draftEvents';
import expiredEvents from './expiredEvents'
import verify from './verify';
import trendingEvents from './trendingEvents';
import searchEvents from './searchEvent'
import similarEvents from './similarEvents'
import overviewEvent from './overviewEvent'
import dailyEvent from './dailyEvent'
import attendance from './attendance'

import categories from './categories';
import categoryEvents from './categoryEvents';

export default combineReducers({
  events,
  login,
  register,
  createEvent,
  popularEvents,
  freeEvents,
  oneEvent,
  updateEvent,
  updateEventClass,
  liveEvents,
  pendingEvents,
  weeklyEvent,
  monthlyEvent,
  draftEvents,
  expiredEvents,
  verify,
  trendingEvents,
  searchEvents,
  similarEvents,
  overviewEvent,
  dailyEvent,
  attendance,

  categories,
  categoryEvents,
});