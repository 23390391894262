import axios from 'axios';
import env from '../../env.js';

const getEventsDrafts = (url) => ({
  type:'GET_DRAFT_EVENTS',
  payload:axios(url)
})

export const onGetDraftEvents = (id) => async (dispatch) => {
  const url=`${env(true)}drafts/saved/${id}`
  dispatch(getEventsDrafts(url))
}