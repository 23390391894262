import React, { Component } from "react";
import "../../styles/basicsettings.css";
import SideMenu from "./sidemenu";
import Header from "../../components/loggedInHeader";
import Footer from "../../components/footer";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";

class BasicSettings extends Component {
  
  render() {
    const {}=this.props.user
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div className="acctSettings">
      <div>
        {isLogged || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        </div>
        <div className="row">
          <div className="col-sm-3">
            <div className="sideBar">
            <div className="account-settings">
        <nav className="nav flex-column">
          <Link to="/account" className="nav-link active" href="#">
            Basic Settings
          </Link>
          <Link to="/password" className="nav-link" href="#">
            Password
          </Link>
          <Link to="/location" className="nav-link" href="#">
            Location Settings
          </Link>
          <Link to="/email" className="nav-link" href="#">
            Email Preferences
          </Link>
          <Link to="/closeaccount" className="nav-link" href="#">
            Close Account
          </Link>
        </nav>
      </div>
            </div>
          </div>
          <div className="col-sm-9 mt-5 basicSetting">
            <h3 className="basicsettings-title">Basic Settings</h3>
            <div className="basicsettings-body">
              <h5>Profile Picture</h5>
              <div className="basicsettings-image">
                <img
                  className="basicsettings-profileimage"
                  src="/image/account__user.png"
                  alt=""
                  height="150"
                />
                <form>
                  <label class="filebutton">
                    <img src="/image/file_upload.png" alt="" />
                    <span>
                      <input type="file" id="myfile" name="myfile" />
                    </span>
                  </label>
                </form>
              </div>
              <h5>Contact Information</h5>
              <div>
                <form>
                  <div className="col-12 form-body">
                    <div className="row">
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">First Name</p>
                          <input
                            type="text"
                            name="acct_fname"
                            id="acct_fname"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">LastName</p>
                          <input
                            type="text"
                            name="acct_lname"
                            id="acct_lname"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">Email Address</p>
                          <input
                            type="email"
                            name="acct_mail"
                            id="acct_email"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">Phone Number</p>
                          <input
                            type="text"
                            name="acct_phone"
                            id="acct_phone"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-success mb-5">Update</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({user: state.login.response.user,
  login:state.login, 
  register:state.register,
});

const mapDispatchToProps = dispatch => ({
  onLogin: bindActionCreators (onLogin, dispatch),
});

export default connect (mapStateToProps, mapDispatchToProps) (BasicSettings);