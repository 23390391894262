import React, { Component } from 'react'
import '../styles/ap.css'
import { Link } from 'react-router-dom';
export default class Footer extends Component {
  render () {
    return (

      <footer id='footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-sm-6 col-lg-3'>
              <h5>More About Us</h5>
              <ul className='list-group'>
                <a href='http://'>
                  <li className=''> About </li>
                </a>
                <Link to="/comingSoon" href='http://'>
                  <li className=''> Our Outlets </li>
                </Link>
                <Link to="/comingSoon" href='http://'>
                  <li className=''> Merchandise </li>
                </Link>
                <Link to="/contact" href='http://'>
                  <li className=''> Contact Us </li>
                </Link>
              </ul>
            </div>
            <div className='col-12 col-sm-6 col-lg-3'>
              <h5>Top Categories</h5>
              <ul className='list-group'>
                <a href='http://'>
                  <li className=''> Music Events </li>
                </a>
                <a href='http://'>
                  <li className=''> Comedy Events </li>
                </a>
                <a href='http://'>
                  <li className=''> Fashion Events </li>
                </a>
                <a href='http://'>
                  <li className=''> Lifeshow Events </li>
                </a>
              </ul>
            </div>
            <div className='col-12 col-sm-6 col-lg-3'>
              <h5>Connect With Us on Social</h5>
              <ul className='list-group'>
                <a target= "_blank" href='https://www.facebook.com/nairabox/'>
                  <li className=''> Facebook </li>
                </a>
                <a target="_blank" href='https://www.instagram.com/nairabox/'>
                  <li className=''> Instagram </li>
                </a>
                <a target="new" href='https://twitter.com/nairabox'>
                  <li className=''> Twitter </li>
                </a>
              </ul>
            </div>
            <div className='col-12 col-sm-6 col-lg-3 apps'>
              <h5>Mobile App</h5>
              <div className='appsBottom'>
                <a target="new" href='http://apple.co/2981VfL' className='mr-1'><img src='../image/appstore.png' alt='nairabox apple app' /></a>
                <a target="_blank" href='http://bit.ly/29a0XEv'><img src='../image/google.png' alt='nairabox andriod app' /></a>
              </div>
            </div>
          </div>
          <div className='clearfix bottom-footer'>
            <div className='footer__copyright'>
              2018 © All Rights Reserved, Nairabox
            </div>
            <div className='footer__links'>
              <a href='/termsConditions/#refund'>Refund & Cancellation Policy</a> |
              <a href='/termsConditions/'>Terms & Conditions</a>
            </div>
          </div>
        </div>
        
      </footer>



    )
  }
}
