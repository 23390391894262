import axios from 'axios';
import env from '../../env.js';

const getEventMonthly = (url) => ({
  type:'GET_EVENT_MONTHLY',
  payload:axios(url)
})

export const onGetEventMonthly = (id) => async (dispatch) => {
  const url=`${env(true)}merchant/monthly-events/${id}`
  dispatch(getEventMonthly(url))
}