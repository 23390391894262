import React, { Component } from 'react';
import '../../App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Truncate from 'react-truncate';
import moment from 'moment';
class TrendingEvents extends Component {
  nameEvent(id) {
    var id = id
    // console.log(id)
    if (id == "5c7fe0078ead0eaa698b45d4") {
      return (
        <Link to={`/event/vcpololagos`} className='btn btn-ticket mb-3'> Buy Ticket
        </Link>
      )

    } else if (id == "5d47f5429c412a0017c18ddf") {
      return (
        <Link to={`/event/WAJE:RED-VELVET`} className="btn btn-ticket mb-3"> Buy Ticket
			 </Link>)
    }
    else {
      return (
        <Link to={`/event/${id}`} className='btn btn-ticket mb-3'> Buy Ticket
        </Link>
      )
    }
  }
  render() {
    const options = {
      responsive: {
        1000: {
          items: 4
        },

        600: {
          items: 2
        },
        0: {
          items: 1
        }
      }
    }

    const { events, toggl, history: { push } } = this.props
    return (

      <div className='container-fluid'>
        <section name='Trending Events' className='trendingEvents'>
          <div className='row pl-3'>
            <div className='d-inline-flex'>
              <h4 className='page-title '>Trending Events
              <span className="ml-2"><Link className='event-name' to={`/category`}>
                  ( View All Events )</Link></span>
              </h4>
            </div>
            {/* <div className='d-inline-flex float-right allEvents'>
                                      <a href=''><h6>See all events</h6></a>
                                    </div> */}
            {/* <OwlCarousel
              className='owl-theme'
              loop
              margin={40}
              nav
			  items={4}
			   autoplay={true} autoplayTimeout={7000} responsiveClass={true}
              dots={false}
              {...options}> */}
          </div>
          <div class="row">
            {events.slice(0, 12).map((value, key) =>
              <div class="col-12 col-md-6 col-lg-3 mb-4">
                <div className='card event-card '>
                  <div className='card-image-container'>
                    <img className='card-image' src={value.coverImage} alt='Events in Nigeria' />
                    <div className='details-section'>
                      <div className='event-date'>
                        <span className='event-date__day'>{new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')).getDate()}</span>
                        <span className='event-month'>{format((new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T'))), 'MMM')}</span>
                        {' '}
                      </div>
                      <div className='other-info'>
                        <div className='event-details'>
                          <Link className='event-name' to={`/event/${value._id}`}>
                            <Truncate lines={1} width={220} ellipsis={<span>...</span>}>
                              {value.name}
                            </Truncate>
                          </Link>
                          <div className='clearfix mt-2'>
                            <p className='d-inline float-left mr-2 event-icon'>
                              {' '}
                              <img src='/image/locations.svg' alt='event-location' height='15' />
                            </p>
                            <p className='d-inline'>
                              {value.state}
                            </p>
                          </div>
                          <div className='clearfix '>
                            <p className='d-inline float-left mr-2 event-icon'>
                              {' '}
                              <img src='/image/price-tag-white.svg' alt='event-location' height='15' />
                            </p>
                            <p className='d-inline'>
                              {' '} Starting from <span className='price'>{Number(value.starting_from).toLocaleString(`en-${(value.currency || 'NGN').substring(0, 2)}`, {
                              style: 'currency',
                              currency: (value.currency || 'NGN'),
                              maximumFractionDigits: 2
                            })}  </span>
                            </p>
                          </div>
                          {this.nameEvent(value._id)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* </OwlCarousel> */}
        </section>
      </div>
    )
  }
}

export default withRouter(TrendingEvents)
