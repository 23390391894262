import axios from 'axios';
import env from '../../env.js';

const getCategoryEvents = (url) => ({
    type: 'GET_CATEGORY_EVENTS',
    payload: axios(url)
})

export const onGetCategoryEvents = (categoryName => async (dispatch) => {
    const url = `${env(true)}events/category/${categoryName}`
    dispatch(getCategoryEvents(url))
})