
const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    response:[],
    error:{}
  };
  
  const searchEvents = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_SEARCH_EVENTS_PENDING": {
        return {
          ...state,
          fetching:true,
          fetched:false
        };
      }
      case "GET_SEARCH_EVENTS_FULFILLED": {
        return {
          ...state,
          fetching:false,
          fetched:true,
          response: action.payload.data
        };
      }
      case "GET_SEARCH_EVENTS_REJECTED": {
        return {
          ...state,
          fetching:false,
          fetched:false,
          error: action.payload
        };
      }
      default: {
        return {
          ...state
        }
      };
    }
  };
  
  export default searchEvents;