import React, { Component } from 'react';
import '../../App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import getMonth from 'date-fns/get_month';
import Truncate from 'react-truncate';
//import altImage from '../../img/life.jpg';
import moment from 'moment';

class PopularEvents extends Component {
	nameEvent(event) {
		// console.log(id)
		 if (event.category == "FREE-EVENT") {
			return (
				<Link to={`/event/${event._id}`} history={this.props.history} className="btn btn-ticket mb-3">  Register
			 </Link>)
		}
		else {
			return (
				<Link to={`/event/${event._id}`} history={this.props.history} className="btn btn-ticket mb-3"> Buy Ticket
			 </Link>
			)
		}
	}
	render() {
		const options = {
			responsive: {
				1000: {
					items: 4
				},

				600: {
					items: 2
				},
				0: {
					items: 1
				}
			}
		};

		const { events, toggl, history: { push } } = this.props;
		console.log('events show ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++', events);

		return (
			<div className="container-fluid">
				<section name="Popular Events" className="popularEvents">
					<div className="col-12 ">
						<h4 className="page-title popular-events-title">Free Events</h4>

						<OwlCarousel className="owl-theme"	loop={true}	margin={40}	nav={true} items={4} dots={false} autoplay={true} autoplayTimeout={7000} responsiveClass={true} {...options}>
							{events && events.map((value, key) => (
								<div className="card event-card " key={key}>
									<div className="card-image-container">
										<img className="card-image" src={value.coverImage} alt="Events in Nigeria" />
										<div className="details-section">
											<div className="event-date">
												<span className="event-date__day">
													{new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')).getDate()}{' '}
												</span>
												<span className="event-month">
													{format(new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')), 'MMM')}
												</span>{' '}
												{' '}
											</div>
											<div className="other-info">
												<div className="event-details">
													<Link className="event-name" to={`/event/${value._id}`}>
														<Truncate
															lines={1}
															width={170}
															// width={this.truncateRef.onResize()}
															ellipsis={<span>...</span>}
														>
															{value.name}
														</Truncate>
													</Link>
													<div className="clearfix mt-2">
														<p className="d-inline float-left mr-2 event-icon">
															{' '}
															<img
																src="/image/locations.svg"
																alt="event-location"
																height="15"
															/>
														</p>
														<p className="d-inline">
															{' '}
															{/* <Truncate lines={15} ellipsis={<span>...</span>}> */}
															{value.state}
															{/* </Truncate> */}
														</p>
													</div>
													<div className="clearfix">
														<p className="d-inline float-left mr-2 event-icon">
															{' '}
															<img
																src="../image/price-tag-white.svg"
																alt="event-location"
																height="15"
															/>
														</p>
														<p className="d-inline">
															{' '}
															Starting from <span className="price"> {Number(value.starting_from).toLocaleString(`en-${(value.currency || 'NGN').substring(0, 2)}`, {
															style: 'currency',
															currency: (value.currency || 'NGN'),
															maximumFractionDigits: 2
														})}  </span>
														</p>
													</div>
													{/*onClick={isLogged?()=>push(`/event/${value._id}`):toggl} */}
													{/* <Link to={`/event/${value._id}`} history={this.props.history} className="btn btn-ticket mb-3">
														Buy Ticket
													</Link> */}
													{this.nameEvent(value)}
												</div>
											</div>
										</div>
									</div>
								</div>
							))}

						</OwlCarousel>
					</div>
				</section>
			</div>
		);
	}
}

export default withRouter(PopularEvents);
