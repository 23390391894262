import axios from 'axios';
import env from '../../env.js';

const getAttendance = (url,uid) => ({
  type:'GET_ATTENDANCE',
  payload:axios.post(url, {uid})
})

export const onGetAttendance = (id,uid) => async (dispatch) => {
  const url=`${env(true)}merchant/attendance/${id}`
  dispatch(getAttendance(url,uid))
}







