import axios from 'axios';
import $ from 'jquery';
import env from '../../env.js';

const login = (url,email,password) => ({
  type: 'LOGIN',
  payload:axios.post(url, {
    email,password
  })
});

export const onLogin = (email,password) => async (dispatch) => {
  const url = `${env(true)}users/login`
  dispatch(login(url,email,password))
}

