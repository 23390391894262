import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../styles/ap.css';
import Header from '../loggedInHeader';
import Footer from '../footer';

class SuccessPage extends Component {
	render() {
		var settings = {
			dots: true,
			infinite: true,
			autoplay: true,
			speed: 300,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1
		};
		return (
			<div>
				<Header />
				<div className="container-fluid">
					<div className="row">
						<div className=" col-10 col-lg-5 ml-auto mr-auto">
							<div className="successCard">
								<h4>Signup Successful!!!</h4>
								<div className="text-center pt-3 mb-2">
									<img src="image/successimg.svg" alt="events in Lagos" />
								</div>
								<div className="usps">
									<Slider {...settings}>
										<div className="successlides">
											<p>Start Selling Tickets in 5mins</p>
											<p>
												{' '}
												Our quick setup experience provides simplcity in getting an event
												uploaded for sale.
											</p>
										</div>
										<div className="successlides1">
											<p>Get Paid Fast</p>
											<p>
												{' '}
												We are the quickest with settlement and reconciliation and get live
												feeds on sales.
											</p>
										</div>
										<div className="successlides2">
											<p>Reporting that Matters</p>
											<p>Proper actionable analytics wth recommendation.</p>
										</div>
										<div className="successlides3">
											<p>No More Long Queues</p>
											<p>
												{' '}
												Our quick setup experience provides simplcity in getting an event
												uploaded for sale.
											</p>
										</div>
									</Slider>
								</div>
								<div className="text-center mb-3 ">
									<a href="/" className="btn  btn-white-main">
										Take a Quick Spin
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default SuccessPage;
