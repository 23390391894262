import React from 'react';
import { css } from 'react-emotion';
// First way to import
import { BounceLoader } from 'react-spinners';

 
const override = css`
    margin: 0;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
`;
 
class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  render() {
    return (
      <div className='sweet-loading'>
        <BounceLoader
          className={override}
          sizeUnit={"px"}
          size={60}
          color={'#74aa5c'}
         loading={this.state.loading}
        />
      </div> 
    )
  }
}

export default Loader;