import React, { Component } from 'react';
//import '../App.css';
//import '../styles/ap.css';
import Login from '../components/login';
import Signup from '../components/signup';
//import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onLogin } from '../store/actions/login';
import { onRegister } from '../store/actions/register';
import { withRouter } from 'react-router-dom';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstname: '',
			lastname: '',
			phone: '',
			email: '',
			password: '',
			c_password: ''
		};
	}

	onEnter = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	componentDidMount = () => {
		var formInputs = $('input[type="email"], input[type="text"], input[type="password"]');
		formInputs.focus(function () {
			$(this).parent().children('p.formLabel').addClass('formTop');
			$('div#formWrapper').addClass('darken-bg');
			$('div.logo').addClass('logo-active');
		});
		formInputs.focusout(function () {
			if ($.trim($(this).val()).length == 0) {
				$(this).parent().children('p.formLabel').removeClass('formTop');
			}
			$('div#formWrapper').removeClass('darken-bg');
			$('div.logo').removeClass('logo-active');
		});
		$('p.formLabel').click(function () {
			$(this).parent().children('.form-style').focus();
		});
	};
	render() {
		const { firstname, lastname, phone, email, password, c_password } = this.state;
		const { loginModal, signupModal, toggl, toggla, isLogged, isLoggedIn, history: { push } } = this.props;
		return (
			<div className="">
				<header>
					<nav className="navbar navbar-expand-lg navbar-dark header-dark">
						<div className="container-fluid">
							<Link to="/" className="navbar-brand" href="#">
								<img className="main-logo" src="/image/nairabox_white.png" alt="logo" />
							</Link>
							<button
								className="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#navbarNav"
								aria-controls="navbarNav"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon" />
							</button>
							<div className="collapse navbar-collapse" id="navbarNav">
								<ul className="navbar-nav mr-auto">
									<li className="nav-item ">
										<Link to="/faq" className="nav-link" href="#">
											Help
										</Link>
									</li>
									<li className="nav-item">
										<Link to="/comingSoon" className="nav-link" href="#">
											Blog
										</Link>
									</li>
									<li className="nav-item">
										<Link to="/comingSoon" className="nav-link" href="#">
											Merch
										</Link>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											onClick={toggla}
											href="#"
											data-toggle="modal"
											data-target="#signupModal"
										>
											Signup
										</a>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											onClick={toggl}
											href="#"
											data-toggle="modal"
											data-target="#loginModal"
										>
											Login
										</a>
									</li>
									<li className="nav-item">
										<button onClick={isLogged ? () => push : toggl} className="btn btn-main">
											Create Event
										</button>

									</li>
								</ul>
							</div>
						</div>
					</nav>
				</header>
				<div>
					<Modal isOpen={loginModal} toggle={toggl}>
						<ModalHeader toggle={this.toggle}>Log in</ModalHeader>
						<ModalBody>
							<Login />
						</ModalBody>
						<ModalFooter />
					</Modal>

					<Modal isOpen={signupModal} toggle={toggla}>
						<ModalHeader toggle={this.toggle}>Signup</ModalHeader>
						<ModalBody>
							<Signup />
						</ModalBody>
						<ModalFooter />
					</Modal>
				</div>
			</div>
		);
	}
}

export default withRouter(Header);
