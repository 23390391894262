import axios from 'axios';
import $ from 'jquery';
import env from '../../env.js';

const register = (url,firstname,lastname,phone,email,password,c_password) => ({
  type: 'REGISTER',
  payload:axios.post(url, {
    firstname,lastname,email,password,phone,c_password
  })
});

export const onRegister = (firstname,lastname,phone,email,password,c_password) => async (dispatch) => {
  const url = `${env(true)}users`
  dispatch(register(url,firstname,lastname,phone,email,password,c_password))
}