
const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    response:[],
    error:{}
  };
  
  const verify = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "VERIFY_PENDING": {
        return {
          ...state,
          fetching: true,
          fetched: false
        };
      }
      case "VERIFY_FULFILLED": {
        return {
          ...state,
          fetching:false,
          fetched:true,
          response: action.payload.data
        };
      }
      case "VERIFY_REJECTED": {
        return {
          ...state,
          fetching:false,
          fetched:false,
          error: action.payload
        };
      }
    
      default: {
        return {
          ...state
        }
      };
    }
  };
  
  export default verify;