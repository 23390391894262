import axios from 'axios';
import env from '../../env.js';

const getLiveEvents = (url) => ({
  type:'GET_LIVE_EVENTS',
  payload:axios(url)
})

export const onGetLiveEvents = (id) => async (dispatch) => {
  const url=`${env(true)}merchant/live-events/${id}`
  dispatch(getLiveEvents(url))
}