import axios from 'axios';
import $ from 'jquery';

const logout = () => ({
  type: 'LOGOUT',
  payload:null
});

export const onLogout = () => async (dispatch) => {
  localStorage.clear();
  dispatch(logout())
}

