import React, { Component } from 'react'
// import '../../App.css'
import { onGetOneEvent } from '../../store/actions/getOneEvent'
import { bindActionCreators } from 'redux'

class EventBackground extends Component {
  constructor (props) {
    super(props)

    this.state = {
      event: ''
    }
   // console.log('log props here' + props)
  }
  componentDidMount = () => {
    localStorage.removeItem('state')
  };

  componentDidUpdate(prevProps, prevState) {
    localStorage.removeItem('state')
  }
  
  render () {
    const { event } = this.props
    // console.log({details})
    const { bannerImage } = event ? event : {}

    return (
      <div id='fullCarousel' className='carousel slide details-background-image' data-ride='carousel'>
        <div className='carousel-inner'>
          <div className='carousel-item active imageEvent'>
            <img src={bannerImage} alt='/image/MajorLazer.png' className='eventDescription' />
            <div className='event-overlay' />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  oneEvent: state.oneEvent
})

const mapDispatchToProps = (dispatch) => ({
  onGetOneEvent: bindActionCreators(onGetOneEvent, dispatch)
})

export default EventBackground
