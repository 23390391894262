import React, { Component } from 'react'

import { connect } from 'react-redux';
//import { onGetCategories } from '../../store/actions/getCategories';
import { onGetCategories } from '../../store/actions/getCategories'
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import '../../App.css'

class Categories extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log('categories props: ', this.props)
    this.props.onGetCategories();
  }
  render() {
    let { categories, match } = this.props;
    let { response: { data } } = categories;
    console.log('categories: ', categories);
    console.log('data: ', data);
    let categoriesJsx = data && data.map(({ category, _id }) => (
      <div className='col-4 col-md-2' key={_id}>
        <Link to={{ pathname: `/category/${category}`, state: { category } }}>
          <div className='cat-card' id={category.toLowerCase()}>
            <h5 className='cat-name'><a href=''>{category}</a></h5>
          </div>
        </Link>
      </div>

    )
    )

    let divBlock = (
      <div className='col-4 col-md-2'>
        <div className='cat-card' id='music'>
          <h5 className='cat-name'><a href=''>Music</a></h5>
        </div>
      </div>
    )

    return (
      <div className='container-fluid'>
        <section name='categories' id='categories'>
          <div className='col-12 '>
            <h4 className='page-title'>Event Categories</h4>
            <div className='row'>
              {
                categoriesJsx
              }
              {/* <div className='col-4 col-md-2'>
                <div className='cat-card' id='music'>
                  <h5 className='cat-name'><a href=''>Music</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2'>
                <div className='cat-card' id='comedy'>
                  <h5 className='cat-name'><a href=''>Comedy</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2'>
                <div className='cat-card' id='fashion'>
                  <h5 className='cat-name'><a href=''>Fashion</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2 cat-top'>
                <div className='cat-card' id='sport'>
                  <h5 className='cat-name'><a href=''>Sport</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2 cat-top'>
                <div className='cat-card' id='exhibition'>
                  <h5 className='cat-name'><a href=''>Exhibitions</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2 cat-top'>
                <div className='cat-card' id='liveshow'>
                  <h5 className='cat-name'><a href=''>Live shows</a></h5>
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-4 col-md-2'>
                <div className='cat-card' id='parties'>
                  <h5 className='cat-name'><a href=''>Parties</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2'>
                <div className='cat-card' id='business'>
                  <h5 className='cat-name'><a href=''>Business</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2'>
                <div className='cat-card' id='food'>
                  <h5 className='cat-name'><a href=''>Food & Drinks</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2 cat-top'>
                <div className='cat-card' id='social'>
                  <h5 className='cat-name'><a href=''>Social</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2 cat-top'>
                <div className='cat-card' id='plays'>
                  <h5 className='cat-name'><a href=''>Plays</a></h5>
                </div>
              </div>
              <div className='col-4 col-md-2 cat-top'>
                <div className='cat-card' id='galleries'>
                  <h5 className='cat-name'><a href=''>Galleries</a></h5>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

//export default Categories

const mapStateToProps = (state) => ({
  categories: state.categories
});

const mapDispatchToProps = (dispatch) => ({
  onGetCategories: bindActionCreators(onGetCategories, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Categories));
