import React, { Component } from 'react';
import '../../App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default class Partners extends Component {
	render() {
		const options = {
			responsive: {
				1000: {
					items: 6
				},
				800: {
					items: 4
				},

				575: {
					items: 3
				},
				375: {
					items: 2
				},
				0: {
					items: 1
				}
			}
		};
		return (
			<section name="brand-logo" id="brand_logo">
				<div className="col-12 ">
					<h4 className="page-title text-center">Few of the Brands that Engage with Us</h4>
					<div className="col-10 m-auto partnerLogo ">
						<OwlCarousel
							className="owl-theme"
							loop={true}
							margin={50}
							nav={false}
							items={6}
							dots={false}
							autoplay={true}
							autoplayTimeout={9000}
							responsiveClass={true}
							{...options}
						>
							<div class="item">
								<img src="/image/coca.png" />
							</div>
							<div class="item">
								<img src="/image/teGroup.png" />
							</div>
							<div class="item">
								<img src="/image/davido.png" />
							</div>
							<div class="item">
								<img src="/image/dmw.png" />
							</div>
							<div class="item">
								<img src="/image/thePlug.png" />
							</div>
							<div class="item">
								<img src="/image/castle.png" />
							</div>
							<div class="item">
								<img src="/image/visalogo.png" />
							</div>
						</OwlCarousel>
					</div>
				</div>
			</section>
		);
	}
}
