
const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  response:[],
  error:{}
};

const createEvents = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CREATE_EVENT_PENDING": {
      return {
        ...state,
        fetching:true,
        fetched:false
      };
    }
    case "CREATE_EVENT_FULFILLED": {
      return {
        ...state,
        fetching:false,
        fetched:true,
        response: action.payload.data
      };
    }
    case "CREATE_EVENT_REJECTED": {
      return {
        ...state,
        fetching:false,
        fetched:false,
        error: action.payload
      };
    }
    default: {
      return {
        ...state
      }
    };
  }
};

export default createEvents;