import axios from 'axios';
import env from '../../env.js';

const getTrendingEvents = (url) => ({
  type:'GET_TRENDING_EVENTS',
  payload:axios(url)
})

export const onGetTrendingEvents = () => async (dispatch) => {
  const url=`${env(true)}events/trending`
  dispatch(getTrendingEvents(url))
}