import React, { Component } from "react";
import $ from "jquery";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onLogout } from '../store/actions/logout';
import { onLogout2 } from '../store/actions/logout2';
import { onLogin } from '../store/actions/login';
import { onRegister } from '../store/actions/register';
import { Link } from "react-router-dom";

class Header extends Component {
  componentDidMount = () => {
    //console.log(this.props.userReg.firstname)
    if (this.props.user) {
      localStorage.setItem("firstname", this.props.user.firstname)
      localStorage.setItem("uid", this.props.user.uid)
      localStorage.setItem("email", this.props.user.email)
      localStorage.setItem("id", this.props.user._id);
      localStorage.setItem("token", this.props.token);
    } else {
       localStorage.setItem("firstname", this.props.userReg.firstname)

     localStorage.setItem("uid", this.props.userReg.uid)

   localStorage.setItem("email", this.props.userReg.email)

    // localStorage.setItem("id", this.props.user._id);
    // localStorage.setItem("token", this.props.token);
    }
    // this.props.user ? localStorage.setItem("firstname", this.props.user.firstname) : localStorage.setItem("firstname", this.props.userReg.firstname)

    // this.props.user ? localStorage.setItem("uid", this.props.user.uid) : localStorage.setItem("uid", this.props.userReg.uid)

    // this.props.user ? localStorage.setItem("email", this.props.user.email) : localStorage.setItem("email", this.props.userReg.email)

    // localStorage.setItem("id", this.props.user._id);
    // localStorage.setItem("token", this.props.token);
    var formInputs = $(
      'input[type="email"], input[type="text"], input[type="password"]'
    );
    formInputs.focus(function () {
      $(this)
        .parent()
        .children("p.formLabel")
        .addClass("formTop");
      $("div#formWrapper").addClass("darken-bg");
      $("div.logo").addClass("logo-active");
    });
    formInputs.focusout(function () {
      if ($.trim($(this).val()).length == 0) {
        $(this)
          .parent()
          .children("p.formLabel")
          .removeClass("formTop");
      }
      $("div#formWrapper").removeClass("darken-bg");
      $("div.logo").removeClass("logo-active");
    });
    $("p.formLabel").click(function () {
      $(this)
        .parent()
        .children(".form-style")
        .focus();
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.props.user) {
      this.props.onLogout()
    } else {
      this.props.onLogout2()
    }
   
  }
  render() {
    //console.log(this.props)
    if (this.props.user) {
      var { firstname, lastname } = this.props.user
      var { uid } = this.props.user
    } else {
     var firstname = this.props.userReg.firstname
      //this.props.register.response.user.firstname
      var uid = this.props.userReg.uid

    }

    return (
      <div className="">
        <header>
          {/* added new navbar */}
          <nav className="navbar navbar-expand-lg navbar-dark header-dark nav_bar">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand" href="#">
                <img
                  className="main-logo"
                  src="/image/nairabox_white.png"
                  alt="logo"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item ">
                    <Link to="/faq" className="nav-link" href="#">
                      Help{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/comingSoon" className="nav-link" href="#">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/comingSoon" className="nav-link" href="#">
                      Merch
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/createevent"
                      className="btn btn-primary btn-main"
                    >
                      Create Event
                    </Link>
                  </li>
                  <li className="nav-item dropdown user__">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        class="user__"
                        src="/image/user.png"
                        height="30px"
                        alt="Events in Lagos"
                      />
                      <span className="user__name">{`${firstname}`}</span>
                      <span className="caret" />
                    </a>
                    <div className="dropdown-menu">
                      <Link to={(`/eventdashboard/${uid}`)} className="dropdown-item" href="#">
                        Dashboard
                    </Link>

                      <Link to={(`/eventlist/${uid}`)} className="dropdown-item" href="#">
                        My Events
                      </Link>
                      {/* <Link to="/eventticket" className="dropdown-item" href="#">
                        My Tickets
                      </Link>
                      <div className="dropdown-divider" />
                      <Link to="/account" className="dropdown-item" href="#">
                        Account Settings
                      </Link>
                      <Link to="" className="dropdown-item" href="#">
                        Help
                      </Link> */}
                      <Link to="" className="dropdown-item" href="#" onClick={this.onSubmit}>
                        Logout
                      </Link>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.response.user,
  token: state.login.response.token,
  userReg: state.register.response.user
});

const mapDispatchToProps = dispatch => ({
  onLogout: bindActionCreators(onLogout, dispatch),
  onLogout2: bindActionCreators(onLogout2, dispatch),
  onLogin: bindActionCreators(onLogin, dispatch),
  onRegister: bindActionCreators(onRegister, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
