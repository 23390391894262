import React, { Component } from 'react';
// import '../../styles/basicsettings.css';
import '../../styles/email.css';
import SideMenu from './sidemenu';
import Header from '../../components/loggedInHeader';
import Footer from '../../components/footer';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';

class BasicAccount extends Component {
	render() {
		const {login:{isLogged}, register:{isLoggedIn}}=this.props;
		return (
			<div className="acctSettings">
				<div>
        {isLogged || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        </div>
				<div className="row">
					<div className="col-sm-3">
						<div className="sideBar">
						<div className="account-settings">
        <nav className="nav flex-column">
          <Link to="/account" className="nav-link" href="#">
            Basic Settings
          </Link>
          <Link to="/password" className="nav-link" href="#">
            Password
          </Link>
          <Link to="/location" className="nav-link" href="#">
            Location Settings
          </Link>
          <Link to="/email" className="nav-link" href="#">
            Email Preferences
          </Link>
          <Link to="/closeaccount" className="nav-link active" href="#">
            Close Account
          </Link>
        </nav>
      </div>
						</div>
					</div>
					<div className="col-sm-9 basicsettings-container">
						<h3 className="basicsettings-title description ">Close Account</h3>
						<p className="email-news pr-3">
							Thank you for using Nairabox Event. If there is anything we can do to keep you with us,
							please let us know. Please take a moment to let us know why you are leaving:
						</p>
						<div className="basicsettings-body">
							<div className="checkbox checkbox-success">
								<input id="checkbox1" type="checkbox" />
								<label for="checkbox1">I do not recall signing up for Nairabox Event</label>
							</div>
							<div className="checkbox checkbox-success">
								<input id="checkbox2" type="checkbox" />
								<label for="checkbox2">The product is too difficult to use</label>
							</div>
							<div className="checkbox checkbox-success">
								<input id="checkbox3" type="checkbox" />
								<label for="checkbox3">I do not host events</label>
							</div>
							<div className="checkbox checkbox-success">
								<input id="checkbox4" type="checkbox" />
								<label for="checkbox4">The product lacks the necessary features</label>
							</div>

							<div className="checkbox checkbox-success">
								<input id="checkbox5" type="checkbox" />
								<label for="checkbox5">I chose a different solution</label>
							</div>
							<div className="checkbox checkbox-success">
								<input id="checkbox6" type="checkbox" />
								<label for="checkbox6">Other (Please explain)</label>
							</div>
							<div>
								<div className="row">
								<div className="col-sm-8 explain">
								<textarea rows="2"  />
								</div>
								</div>
							</div>
							<div className="closeacct">
								<p>
									Please enter <span>"CLOSE"</span> and your password below to confirm that you wish
									to close your account:
								</p>
							</div>
							<form id="closeacct">
								<div className="form-group row">
									<label for="close" className="col-sm-3 col-form-label">
										Type CLOSE
									</label>
									<div className="col-sm-4">
										<input type="text" className="form-control" id="close" />
									</div>
								</div>
								<div className="form-group row">
									<label for="inputPassword" className="col-sm-3 col-form-label">
										Enter your password
									</label>
									<div className="col-sm-4">
										<input
											type="password"
											className="form-control"
											id="inputPassword"
											placeholder="Password"
										/>
									</div>
								</div>
							</form>
							<button className="btn btn-success email-save mb-5">Close Accounts</button>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (BasicAccount);