import React, { Component } from 'react'
import Slider from 'react-slick'
import '../../styles/comingSoon.css'
// import styled from 'styled-components'
import Header from '../loggedInHeader'
import Footer from '../footer'

class ComingSoon extends Component {
  render () {
    return (
      <div id='comingBg'>
        {/* <Header /> */}
        <div className='container'>
          <div className='comingSoon'>
            <div className='c__content'>
              <img src='/image/notfound.png' className='imgSoon' />
              <h4>Opps! Not Found</h4>
              <p>
                Sorry, we couldn't find the page you were looking for, We suggest that you return to the main sections
              </p>
              <a href='/' className='btn btn-white-main'>Go to main page</a>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    )
  }
}

export default ComingSoon
