import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ShareButton from 'react-social-share-buttons';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment-timezone';
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

class EventDescriptionLeft extends Component {

    render() {
        //summary, date, venue, ID, _id, bannerImage, name
        let { summary, date, startDate, endDate, time, venue, ID, _id,
            bannerImage, name, description } = this.props;

        let sdate = startDate && startDate.split('T')[0];
        console.log('props: ', this.props);

        return (
            <div className="col-lg-7 col-sm-push-5 description">
                <p className="event-desc-body"
                   dangerouslySetInnerHTML={{ __html: summary }}>
                </p>


                <div id="dateTime">
                    <h5 className="event-h5">Date and Time </h5>
                    <div className="row">
                        <div className="col-12 col-sm-6" >
                            <h5>Start Date</h5>
                            <p>
                                <Moment format="MM/DD/YYYY">
                                    {sdate}
                                </Moment>
                            </p>

                            <p>Time: {time
                            }</p>
                            <button className='btn btn-primary' onClick={() => atcb_action({
                                name,
                                description,
                                startDate: moment(startDate).format('YYYY-MM-DD'),
                                endDate: moment(endDate).format('YYYY-MM-DD'),
                                startTime: (time && time.substring(0, 5)) || '10:00',
                                endTime: (time && time.substring(0, 5)) || '10:00',
                                location: venue || '',
                                label: "Add to Calendar",
                                options: [
                                    "Apple",
                                    "Google",
                                    "Microsoft365",
                                    "MicrosoftTeams",
                                    "Outlook.com",
                                    "Yahoo"
                                ],
                                trigger: "click",
                                inline: true,
                                listStyle: "modal"
                            })}>Add to calender</button>
                        </div>

                        {(endDate !== sdate) && (<div className="col-12 col-sm-6" >
                            <h5>End Date</h5>
                            <p>
                                <Moment format="MM/DD/YYYY">
                                    {endDate}
                                </Moment>
                            </p>

                        </div>)}

                    </div>

                </div>



                <div className="location">
                    <h5 className="event-h5">Location</h5>
                    <p className="event-desc-body">{venue}</p>
                    <div className="mapLocation" />
                </div>

                <div id="tag">
                    <h5 className="event-h5">Tags</h5>
                    <div className="event-desc-body">festival</div>
                    <div className="event-desc-body">others</div>
                </div>
                <div class="event-social">
                    <h5 className="event-h5"> Share event: </h5>
                    <div class="d-block">

                        <div className="d-inline-block">
                            <ShareButton compact socialMedia={'facebook'}
                                // {`/event/${value._id}`}
                                         url={`http://events.nairabox.com/event/${_id}`}
                                         media={`${bannerImage}`}
                                         text={`${name}`}
                            />
                        </div>
                        <div className="d-inline-block">
                            <ShareButton compact
                                         socialMedia={'twitter'}
                                         url={`http://events.nairabox.com/event/${_id}`}
                                         media={'https://imgs.xkcd.com/comics/error_code.png'}
                                         text={`${name}`}
                            />
                        </div>
                        <div className="d-inline-block">
                            <ShareButton
                                compact
                                socialMedia={'google-plus'}
                                url={'https://xkcd.com/1024/'}
                                media={'https://imgs.xkcd.com/comics/error_code.png'}
                                text={`${name}`}
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default EventDescriptionLeft;