import React, {Component} from 'react';
import $ from 'jquery';
import '../styles/contactus.css';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/formtype.css';
import axios from 'axios';

export default class AsisRegister extends Component {
    constructor(props){
        super(props);
        this.state={
          firstname:'',
        email:'',
        submitted: false,
        membership_id: '',
        phone: '',
        value: ''
        }
       }
  componentDidMount = () => {
    var formInputs = $ (
      'input[type="email"], input[type="text"], input[type="password"], input[type="number"]'
    );
    formInputs.focus (function () {
      $ (this).parent ().children ('p.formLabel').addClass ('formTop');
      $ ('div#formWrapper').addClass ('darken-bg');
      $ ('div.logo').addClass ('logo-active');
    });
    formInputs.focusout (function () {
      if ($.trim ($ (this).val ()).length == 0) {
        $ (this).parent ().children ('p.formLabel').removeClass ('formTop');
      }
      $ ('div#formWrapper').removeClass ('darken-bg');
      $ ('div.logo').removeClass ('logo-active');
    });
    $ ('p.formLabel').click (function () {
      $ (this).parent ().children ('.form-style').focus ();
    });
  };
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleClick = (event)=> {
    event.preventDefault();
    this.setState({ submitted: true });
    if(this.state.firstname != 0 && this.state.email != 0 && this.state.phone != 0 && this.state.membership_id != 0){
    // var apiBaseUrl = "http://localhost:4000/api/";
     var apiBaseUrl = "https://www.nairabox.net/api/";
    var self = this;
    var payload={
    "firstname":this.state.firstname,
    "email": this.state.email,
    "phone":this.state.phone,
    "membership_id": this.state.membership_id
    }
    console.log(payload)
    axios.post(apiBaseUrl+'issueEventFree', payload)
    .then(function (response) {
   // console.log(response);
    if(response.data.status === 404){
    console.log("Transaction not found");
    alert(response.data.message)
    // var uploadScreen=[];
    // uploadScreen.push(<UploadScreen appContext={self.props.appContext}/>)
    // self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
    }
    else if(response.data.status === 200){
    
    alert(response.data.message)
    }
    // else{
    // console.log("Username does not exists");
    // alert("Username does not exist");
    // }
    })
    .catch(function (error) {
    console.log(error);
    });
}
    }
  render () {
    return (
      <div>
        <Header/>
            <div className="background create__event mt-5 mb-5 container-fluid" >
                <div><h4 className="event-header">ASIS African Security Conference 2019</h4></div>
                <div className="row container-fluid free mt-3">
                <div className="col-lg-8 col-sm-push-5 createDiv">
                    <div className="freeheader1">
                    ASIS Student Members Registration Information
                    </div>

                    <div className="container-fluid event-reg mt-4">
                    <p className="event-regh ">Your Information</p>

                    <form className="mt-3" onSubmit={this.handleClick}>
                    <div className="row">
                    <div className="col-sm-6">
                        <div className="form-item">
                        <p className="formLabel">First Name</p>
                        <input
                            type="text"
                            name="firstname"
                            id="firstname"
                            className="form-style"
                            autocomplete="off"
                            onChange={this.handleChange}
                        />
                        </div>
                        </div>
                        <div className="col-sm-6">
                        <div className="form-item">
                        <p className="formLabel">Email</p>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-style"
                            autocomplete="off"
                            onChange={this.handleChange}
                        />
                        </div>
                        </div>
                        </div>

                        <div className="row">
                    <div className="col-sm-6">
                        <div className="form-item">
                        <p className="formLabel">Phone Number</p>
                        <input
                            type="number"
                            name="phone"
                            id="phone"
                            className="form-style"
                            autocomplete="off"
                            onChange={this.handleChange}
                        />
                        </div>
                        </div>
                        <div className="col-sm-6">
                        <div className="form-item">
                        <p className="formLabel">Membership ID</p>
                        <input
                            type="text"
                            name="membership_id"
                            id="membership_id"
                            className="form-style"
                            autocomplete="off"
                            onChange={this.handleChange}
                        />
                        </div>
                        </div>
                        </div>
                        
                    
                    <button className="btn btn-ticket mb-5">Register</button>
                    </form>
                </div>
                </div>
                <div className="col-lg-4 createDiv">
                    <div className="freeheader2">
                            When and Where
                    </div>
                    <div className="event-reg">
                    <p className="event-regh mt-4 ">Date And Time</p>
                        <p className="textColor">Thurs, July 25, 2019, 8:00 AM WAT</p>

                        <p className="textColor">Fri, July 26, 2019, 8:00 AM WAT</p>

                        <p className="event-regh mt-4 ">Location</p>
                        <p className="textColor">Novotel Hotel, Port Harcourt, </p>
                        <p className="textColor">Rivers State.</p>

                       
                    </div>
                </div>
            </div>
            

					
       
        </div>
     <Footer/>
      </div>
    );
  }
}
