import React, { Component } from 'react';
import '../styles/createevent.css';
import Header from '../components/header';
import Footer from '../components/footer';
import CreateEvents from '../components/createEvent/create';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { onLogin } from '../store/actions/login';
import { onRegister } from '../store/actions/register';
import LoggedInHeader from '../components/loggedInHeader';

class CreateEvent extends Component {
  render() {
    const { login: { isLogged }, register: { isLoggedIn } } = this.props
    return (
      <div>
        {(isLogged || isLoggedIn) || <Redirect to="/" />}
        {isLogged || isLoggedIn ?
          <LoggedInHeader /> :
          <Header />}
        <CreateEvents />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({ login: state.login, register: state.register });


export default connect(mapStateToProps)(CreateEvent);
