import React, { Component } from 'react';
import '../../App.css';
import Slider from 'react-slick';

export default class Partners extends Component {
	render() {
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};
		return (
			<section name="brand-logo" id="brand_logo">
				<div className="col-12 ">
					<h4 className="page-title text-center">Few of the Brands that Engage with Us</h4>
					<section className="customer-logos sliders">
						<Slider {...settings}>
							<div className="slides">
								<img src="/image/coca.png" />
							</div>
							<div className="slides">
								<img src="/image/teGroup.png" />
							</div>
							<div className="slides">
								<img src="/image/davido.png" />
							</div>
							<div className="slides">
								<img src="/image/dmw.png" />
							</div>
							<div className="slides">
								<img src="/image/thePlug.png" />
							</div>
							<div className="slides">
								<img src="/image/thePlug.png" />
							</div>
							<div className="slides">
								<img src="/image/castle.png" />
							</div>
							<div className="slides">
								<img src="/image/visalogo.png" />
							</div>
						</Slider>
					</section>
				</div>
			</section>
		);
	}
}
