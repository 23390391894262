import axios from 'axios';
import env from '../../env.js';

// const createEvent = (url,merchant, uid, coverImage, banner, ticketSummary, ticketStartDate, ticketEndDate, ticketsAllowedMin, ticketsAllowedMax, available, eventname, ageRestriction, commission, venue, category, tag, eventType, referal, startDate, endDate, city, state, featured, eventClassName, eventPrice, maxQuantity, summary) => ({
//   type:'CREATE_EVENT',
//   payload:axios.post(url, {
//     merchant, uid, coverImage, banner, eventname, available, ageRestriction, commission, ticketSummary, ticketStartDate, ticketEndDate, ticketsAllowedMin, ticketsAllowedMax, venue, category, tag, eventType, referal, startDate, endDate, city, state, featured, eventClassName, eventPrice, maxQuantity, summary
//   })
// });

// export const onCreateEvent = (merchant, uid, coverImage, banner, available, ticketSummary, ticketStartDate, ticketEndDate, ticketsAllowedMin, ticketsAllowedMax,eventname, ageRestriction, commission, venue, category, tag, eventType, referal, startDate, endDate, city, state, featured, eventClassName, eventPrice, maxQuantity, summary) => async (dispatch) => {
//   const url=`${process.env.REACT_APP_API_NAIRABOX}/events/create`
//   dispatch(createEvent(url,merchant, uid, ticketSummary, ticketStartDate, ticketEndDate, ticketsAllowedMin, ticketsAllowedMax,coverImage, banner, available, eventname, ageRestriction, commission, venue, category, tag, eventType, referal, startDate, endDate, city, state, featured, eventClassName, eventPrice, maxQuantity, summary)).catch((err)=>console.log({err}))
// }



const createEvent = (url, eventData) => ({
  type: "CREATE_EVENT",
  payload: axios.post(url, eventData)
});



export const onCreateMyEvent = (eventData) => (dispatch) => {
  console.log('hiii');
    const url = `${env(true)}events/create`;
    dispatch(createEvent(url, eventData));
}