import React, {Component} from 'react';
import '../../styles/eventSideMenu.css';

export default class SideMenu extends Component {
  render () {
    return (
       <div id="main-menu" class="list-group">
                <a href="#sub-menu" class="list-group-item active" data-toggle="collapse" data-parent="#main-menu">Event Manager <span class="caret"></span></a>
                <div class="collapse list-group-level2 show" id="sub-menu">
                    <a href="#" class="list-group-item " data-parent="#sub-menu">Overview</a>
                    <a href="#" class="list-group-item" data-parent="#sub-menu">Report</a>
                    <a href="#" class="list-group-item" data-parent="#sub-menu">Manage Attendance</a>
                </div>
              
                <a href="#" class="list-group-item">Edit Information</a>
                <a href="#" class="list-group-item">Ticket Questons</a>
                <a href="#" class="list-group-item">Referral Code</a>
                <a href="#" class="list-group-item" >Promote Event <span class="caret"></span></a>
                 {/* <a href="#sub-asub-menu" class="list-group-item" data-toggle="collapse" data-parent="#sub-menu">Additional Settings <span class="caret"></span></a>
                    <div class="collapse list-group-level2" id="sub-asub-menu">
                        <a href="#" class="list-group-item" data-parent="#sub-sub-menu">Sub Sub Item 1</a>
                        <a href="#" class="list-group-item" data-parent="#sub-sub-menu">Sub Sub Item 2</a>
                        <a href="#" class="list-group-item" data-parent="#sub-sub-menu">Sub Sub Item 3</a>
                    </div> */}
            </div>
    );
  }
}
