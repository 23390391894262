import React, { Component } from 'react';
import '../styles/faq.css';
import Header from '../components/header';
import Footer from '../components/footer';

export default class FAQ extends Component {
	componentDidMount = () => {
		var acc = document.getElementsByClassName('accordion');
		var i;

		for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener('click', function() {
				this.classList.toggle('active');
				var panel = this.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + 'px';
				}
			});
		}
	};
	render() {
		return (
			<div>
				<Header />
				<div className="background">
					<div className="layer">
						<div className="faq-background-text">
							<h1>Insurance Policy</h1>
						</div>
					</div>
					<div className="faq-background-image" />
				</div>
				<div className="container-fluid">
					<div className="accordionBody">
						<div className="col 12 col-sm-8 m-auto" style={{color: "#ffffff"}}>
                        <p>This policy provides compensation in the event of accidental injuries sustained and medical expenses on the day of the event up to the below limit:</p>

                        <p>Permanent Disability/Death- 200,000</p>

                        <p>Medical Expenses-50,000</p> 

                        <p>Base Premium: N500 per person/events</p>

                        <p>*Please note that this compensation is valid per person/ticket/event</p>
						</div>
					</div>
				</div>

				<div className="faq-cant-find faq-background-text">
					<h1>Have any questions?</h1>
					<p>
						Email your query to to <a href="mailto:support@nairabox.com">support@nairabox.com</a>
					</p>
				</div>
				<Footer />
			</div>
		);
	}
}
