import axios from 'axios';
import env from '../../env.js';

const getSearchEvents = (url) => ({
  type:'GET_SEARCH_EVENTS',
  payload:axios.get(url)
})

export const onGetSearchEvents = (keyword) => async (dispatch) => {
  const url=`${env(true)}events/search?keyword=${keyword}`
  dispatch(getSearchEvents(url))
}

