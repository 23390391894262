import React, { Component } from "react";
import $ from "jquery";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onRegister} from '../store/actions/register';
import Login from './login';
import validate from 'jquery-validation';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class Signup extends Component {
  constructor(props){
    super(props);
  
  this.state={
    firstname:"",
    lastname:"",
    phone:"",
    email:"",
    password:"",
    c_password:""
  }
  this.toggl = this.toggl.bind(this);
}
  
  onEnter = e => {
    const {name, value} = e.target;
    this.setState ({
      [name]: value,
    });
  };
  toggl() {
    this.setState({
      loginModal: !this.state.loginModal
    });
	}
  onSubmit=(e)=>{e.preventDefault();
    const {firstname, lastname, phone, password, c_password, email} = this.state;
    if (password !== c_password) {
      /*this.setState({password_not_valid: true})*/
      return;
    }
   // const name = firstname
    this.props.onRegister(firstname,lastname,phone,email,password,c_password)
}
  componentDidMount = () => {
    $( "#myForm" ).validate({
      rules: {
        password: "required",
        c_password: {
          equalTo: "#password"
        }
      }
    });
    var formInputs = $('input[type="email"], input[type="text"], input[type="password"], input[type="number"]');
    formInputs.focus(function () {
      $(this).parent().children('p.formLabel').addClass('formTop');
      $('div#formWrapper').addClass('darken-bg');
      $('div.logo').addClass('logo-active');
    });
    formInputs.focusout(function () {
      if ($.trim($(this).val()).length == 0) {
        $(this).parent().children('p.formLabel').removeClass('formTop');
      }
      $('div#formWrapper').removeClass('darken-bg');
      $('div.logo').removeClass('logo-active');
    });
    $('p.formLabel').click(function () {
      $(this).parent().children('.form-style').focus();
    });
  };

  render() {
    const {firstname, lastname, phone, email, password,c_password}=this.state;
    console.log(this.props)
    return (
      
          <div className="modal-content">
            <div className="modal-body">
              <form id="myForm" onSubmit={this.onSubmit}>
                <div id="form">
                  <div className="form-item">
                <p class="formLabel">First Name</p>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      className="form-style"
                      autoComplete="off"
                      // placeholder="First Name"
                      value={firstname}
                      onChange={this.onEnter}
                      required
                      
                    />
                  </div>
                  <div className="form-item">
                <p class="formLabel">Last Name</p>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      className="form-style"
                      autoComplete="off"
                      // placeholder="Last Name"
                      value={lastname}
                      onChange={this.onEnter}
                      required
                       
                    />
                  </div>
                  <div className="form-item">
                <p class="formLabel">Email</p>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-style"
                      autoComplete="off"
                      // placeholder="Email"
                      value={email}
                      onChange={this.onEnter}
                      required
                     
                    />
                  </div>
                  <div className="form-item">
                <p class="formLabel">Phone Number</p>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="form-style"
                      autoComplete="off"
                      // placeholder="Phone Number"
                      value={phone}
                      onChange={this.onEnter}
                      required
                       
                    />
                  </div>
                  <div className="form-item">
                <p class="formLabel">Password</p>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-style"
                      autoComplete="off"
                      // placeholder="Password"
                      value={password}
                      onChange={this.onEnter}
                      required
                     
                    />
                  </div>
                  <div className="form-item">
                <p class="formLabel">Confirm Password</p>
                    <input
                      type="password"
                      name="c_password"
                      id="c_password"
                      className="form-style"
                      autoComplete="off"
                      // placeholder="Confirm Password"
                      value={c_password}
                      onChange={this.onEnter}
                      required
                      
                    />
                </div>
    {/*password_not_valid && <span>Password not valid</span>*/}
                  <div className="modal-btn">
                    <div className="clear-fix">
                      <input type="submit" className="login" value="Sign up" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <h4 className="signupmodalBottom">
              Already have a Nairabox Event account?{" "}
              <span>
                <a onClick={this.toggl} data-toggle="modal" data-target="#loginModal">Log in</a>
              </span>{" "}
            </h4>

            	<Modal isOpen={this.state.loginModal} toggle={this.toggl}>
          <ModalHeader toggle={this.toggle}>Log in</ModalHeader>
          <ModalBody>
						<Login  />
          </ModalBody>
          <ModalFooter>
        
          </ModalFooter>
        </Modal>
          </div>
    );
  }
}

const mapStateToProps = state => ({register: state.register});

const mapDispatchToProps = (dispatch) => ({
  onRegister: bindActionCreators (onRegister, dispatch),
});

export default connect (mapStateToProps, mapDispatchToProps) (Signup);