import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EventDescriptionPayment extends Component {
    state = {
        eventClasses: {},
        result: '',
    }

    handleSelectChange = (event) => {
        this.setState({
            result: event.target.value
        })
    }

    componentDidUpdate(prevProp) {
        //const { event: { response: { event: details, classes } }, event } = this.props;
        let { classes, event, ID, toParent } = this.props;

        if (event.fetched !== prevProp.event.fetched) {
            const eventClasses = {};
            classes.map((dclass) => (eventClasses[dclass._id] = 0));

            toParent({ eventClasses });
            this.setState({ eventClasses });

        }
    }

    onClassAmountChanged(id, e) {
        //console.log("++++++++++++++++++++", {eventClasses: { ...this.state.eventClasses, [id]: e.target.value }})
        this.props.toParent({ eventClasses: { ...this.state.eventClasses, [id]: e.target.value } });
        this.setState({ eventClasses: { ...this.state.eventClasses, [id]: e.target.value } });
        this.setState({all: {count: e.target.value, key: id}})
        this.setState({result: e.target.value})
    }


    showError = (e) => {
        alert("Select event class by selecting the number of ticket you wish to purchase");
        e.preventDefault();
    }

    render() {
        let getTableSeats = (count) => {
            var rows = [];
            rows[0] = <option>{0}</option>;
            for (var i = 0; i < count; i++) {
                if(i == (count - 1 )){
                    rows.push(<option value={count}>Buy full table</option>);
                }else{
                    rows.push(<option>{i + 1}</option>);
                }
            }
            return rows;
        }

        const getTicketOptions = count => {
            let options = [];
            for (let i = 0; i < (count + 1); i++) {
                options.push(<option>{i}</option>)
            }
            return options;
        }

        //const { event: { response: { event } } } = this.props;
        //classes 
        let { classes, event } = this.props;
        //let { eventClasses } = this.state;

        classes = classes && classes.sort((a, b) => parseInt(`${a.price || 0}`) - parseInt(`${b.price || 0}`));

        console.log('today events', event)
        console.log('--classes', classes)
        return (
            <div className="col-lg-5 ">
                <div className="event-pricing">
                    <h4>Pricing</h4>
                    <div className="priceCategory">

                        {classes && classes.map((value) => (

                                <div className="pricingCat" key={value._id}>
                                    <div className="row">
                                        <div className="col-12 col-sm-5 ticket-type">
                                        {value.class}
                                        <br/>
                                        {/* <span className="text-danger">
                                            {value.limit > value.sold ? (value.limit - value.sold) + " Ticket(s) Left" : ""}
                                        </span> */}
                                        </div>

                                        <div className="col-6 col-sm-4 ticket-price">
                                            {(value.price && !value.free) ? Number(value.price).toLocaleString(`en-${(event.currency || 'NGN').substring(0, 2)}`, {
                                                style: 'currency',
                                                currency: (event.currency || 'NGN'),
                                                maximumFractionDigits: 2
                                            }) : ""}
                                            {value.free ? "Free" : ""}
                                        </div>
                                        {value.price ? value.limit > value.sold ? ( value.tables  && value.table ? (
                                            <div className="col-6 col-sm-3 numberTicket">
                                                <select onClick={this.handleSelectChange}
                                                    onChange={this.onClassAmountChanged.bind( this, value._id )}
                                                    value={this.state.eventClasses[value._id]}
                                                    className="form-control form-control-sm"
                                                    required
                                                >

                                                    {getTableSeats(value.table)}


                                                    {/* <input type="hidden" id={this.props.id} value={this.state.starRating} /> */}
                                                </select>
                                            </div>
                                        ) : (
                                                <div className="col-6 col-sm-3 numberTicket">
                                                    <select onClick={this.handleSelectChange}
                                                        onChange={this.onClassAmountChanged.bind(
                                                            this,
                                                            value._id
                                                        )}
                                                        value={this.state.eventClasses[value._id]}
                                                        className="form-control form-control-sm"
                                                        required

                                                    >
                                                        {getTicketOptions(Number(event.quantity || 5))}
                                                        {/* <input type="hidden" id={this.props.id} value={this.state.starRating} /> */}
                                                    </select>
                                                </div>

                            )) : (
                            <div className="col-6 col-sm-3 numberTicket" placeholder="Tickets Sold Out" >
                                <img src="../../image/SO.png" height="45" className="img imng-responsive" />
                            </div>) : <div className="col-6 col-sm-3 numberTicket">
                                                    <select onClick={this.handleSelectChange}
                                                        onChange={this.onClassAmountChanged.bind(
                                                            this,
                                                            value._id
                                                        )}
                                                        value={this.state.eventClasses[value._id]}
                                                        className="form-control form-control-sm"
                                                        required

                                                    >
                                                        <option>0</option>
                                                        <option>1</option>
                                                        {/* <input type="hidden" id={this.props.id} value={this.state.starRating} /> */}
                                                    </select>
                                                </div>}

                                    </div>
                                </div>
                            ))}
                        {this.state.result != 0 ? (
                            <Link to={this.props.eventUrl + '/buyticket'} class="btn btn-buy mr-2 mb-3">
                                Buy Ticket
                            </Link>
                        ) : (
                                <button class="btn btn-buy mr-2 mb-3" onClick={this.showError}>
                                    Buy Ticket
                                </button>
                            )}

                    </div>
                </div>
            </div>
        )
    }
}

export default EventDescriptionPayment;
