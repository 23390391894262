import axios from 'axios';
import env from '../../env.js';

const onGetSimilarEvents = (url) => ({
  type:'GET_SIMILAR_EVENTS',
  payload:axios(url)
})

export const getSimilarEvents = (id) => async (dispatch) => {
  const url=`${env(true)}events/similar/${id}`
  dispatch(onGetSimilarEvents(url))
}