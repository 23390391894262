import axios from 'axios';
import $ from 'jquery';

const logout2 = () => ({
    type: 'LOGOUT2',
    payload: null
});

export const onLogout2 = () => async (dispatch) => {
    localStorage.clear();
    dispatch(logout2())
}

