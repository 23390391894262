
const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    response: [],
    error: {}
};

const categoryEvents = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GET_CATEGORY_EVENTS_PENDING": {
            return {
                ...state,
                fetching: true,
                fetched: false
            };
        }
        case "GET_CATEGORY_EVENTS_FULFILLED": {
            console.log('events in categoryEvents reducer: ', action.payload)
            return {
                ...state,
                fetching: false,
                fetched: true,
                response: action.payload
            };
        }
        case "GET_CATEGORY_EVENTS_REJECTED": {
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.payload
            };
        }
        default: {
            return {
                ...state
            }
        };
    }
};

export default categoryEvents;