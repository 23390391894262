import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Homepage from '../../container/homepage';
import BasicSetting from '../../components/account/basicsettings';
import Email from '../../components/account/email';
import BasicLocation from '../../components/account/location';
import CloseAccount from '../../components/account/closeaccount';
import Password from '../../components/account/password';
import Events from '../../container/eventdetails';
import CreateEvent from '../../container/createevent';
import Faq from '../../container/faq';
import Terms from '../../container/terms';
import ContactUs from '../../container/contactus';
import EventDashboard from '../../components/myEvents/eventDashboard';
import EventList from '../../components/myEvents/eventList';
import EventTicket from '../../components/myEvents/eventTicket';
import EventOverview from '../../components/eventManager/eventOverview';
import EventReport from '../../components/eventManager/eventReport';
import EventOrders from '../../components/eventManager/eventOrders';
import EventBasicInfo from '../../components/eventManager/eventBasicInfo';
import EventClasses from '../../components/eventManager/eventClasses';
import EventSettings from '../../components/eventManager/eventSettings';
import EventQuestions from '../../components/eventManager/eventQuestions';
import EventPromote from '../../components/eventManager/eventPromote';
import successPage from '../../components/home/successfulsignup';
import categoryPage from '../../components/otherPages/category';
import CategoryEvents from '../../components/otherPages/CategoryEvents'
import searchPage from '../../components/otherPages/search';
import notFoundPage from '../../components/otherPages/notFound';
import eventNotFoundPage from '../../components/otherPages/eventNotFound';
import Ticket from '../../components/events/ticket'
import Signup from '../../components/signup';
import Login from '../../components/login';
import EventMobileDrawer from '../../components/eventManager/eventMobileDrawer';
import comingSoon from '../../components/otherPages/comingSoon';
import termsConditions from '../../container/terms-conditions';
import ResendTicket from '../../components/events/resendTicket'
import AsisReg from '../../container/asisRegister'
import UtilityReg from '../../container/utilityRegister'
import discountCode from '../../container/discount';
import registerEvent from '../../container/asisRegister';
import eventConditions from '../../container/event-creation-agreement';
import link from '../../container/link';

const App = () => (
	<Switch>
		<Route exact path='/' component={Homepage} />
		<Route exact path='/account' component={BasicSetting} />
		{/* <Route exact path='/event' component={Events}  /> */}
		<Route path='/event/:eventID' component={Events} />
		<Route exact path='/faq' component={Faq} />
		<Route exact path='/contact' component={ContactUs} />
		<Route exact path='/createevent' component={CreateEvent} />
		<Route exact path='/utilityregister' component={UtilityReg} />
		<Route exact path='/eventdashboard/:merchantID' component={EventDashboard} />
		<Route exact path='/eventlist/:merchantID' component={EventList} />
		<Route exact path='/eventticket' component={EventTicket} />
		<Route exact path='/eventoverview/:merchantID' component={EventOverview} />
		<Route exact path='/eventreport' component={EventReport} />
		<Route exact path='/eventorders/:eventID' component={EventOrders} />
		<Route exact path='/eventbasicinfo' component={EventBasicInfo} />
		<Route exact path='/eventclasses' component={EventClasses} />
		<Route exact path='/eventsettings' component={EventSettings} />
		<Route exact path='/eventpromote' component={EventPromote} />
		<Route exact path='/eventquestions' component={EventQuestions} />
		<Route exact path='/location' component={BasicLocation} />
		<Route exact path='/password' component={Password} />
		<Route exact path='/email' component={Email} />
		<Route exact path='/closeaccount' component={CloseAccount} />
		<Route exact path="/successpage" component={successPage} />

		<Route exact path="/category" component={categoryPage} />
		<Route exact path="/category/:categoryId" component={CategoryEvents} />

		<Route exact path="/eventmenu" component={EventMobileDrawer} />
		<Route exact path="/notfound" component={notFoundPage} />
		<Route exact path="/termsConditions" component={termsConditions} />
		<Route exact path="/eventAgreement" component={eventConditions} />
		<Route exact path="/discount" component={discountCode} />
		<Route exact path="/search/:keyword?" component={searchPage} />
		<Route exact path="/eventnotfound" component={eventNotFoundPage} />
		<Route exact path="/comingSoon" component={comingSoon} />
		<Route exact path="/ticketDetails" component={Ticket} />
		<Route exact path="/resend" component={ResendTicket} />
		<Route exact path="/register" component={registerEvent} />
		<Route exact path="/asisReg" component={AsisReg} />

		<Route exact path="/paic/learn-more" component={Terms} />

		<Route exact path='/l/:id' component={link} />

	</Switch>
	// <Switch>
	// 	<Route exact path="/" component={Homepage} />
	// 	<Route exact path="/account" component={BasicSetting} />
	// 	{/* <Route exact path='/event' component={Events}  /> */}
	// 	<Route exact path="/event/:eventID" component={Events} />
	// 	<Route exact path="/faq" component={Faq} />
	// 	<Route exact path="/contact" component={ContactUs} />
	// 	<Route exact path="/createevent" component={CreateEvent} />
	// 	<Route exact path="/eventdashboard" component={EventDashboard} />
	// 	<Route exact path="/eventlist" component={EventList} />
	// 	<Route exact path="/eventticket" component={EventTicket} />
	// 	<Route exact path="/eventover" component={EventOverview} />
	// 	<Route exact path="/eventreport" component={EventReport} />
	// 	<Route exact path="/eventorders" component={EventOrders} />
	// 	<Route exact path="/eventbasicinfo" component={EventBasicInfo} />
	// 	<Route exact path="/eventclasses" component={EventClasses} />
	// 	<Route exact path="/eventsettings" component={EventSettings} />
	// 	<Route exact path="/eventpromote" component={EventPromote} />
	// 	<Route exact path="/eventquestions" component={EventQuestions} />
	// 	<Route exact path="/location" component={BasicLocation} />
	// 	<Route exact path="/category" component={categoryPage} />
	// 	<Route exact path="/search" component={searchPage} />
	// 	<Route exact path="/notfound" component={notFoundPage} />
	// 	<Route exact path="/eventnotfound" component={eventNotFoundPage} />
	// 	<Route exact path="/password" component={Password} />
	// 	<Route exact path="/email" component={Email} />
	// 	<Route exact path="/closeaccount" component={CloseAccount} />
	// 	<Route exact path="/successpage" component={successPage} />
	// 	<Route exact path="/eventmenu" component={EventMobileDrawer} />
	// 	<Route exact path="/comingSoon" component={comingSoon} />
	// </Switch>
);

export default App;
