import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../styles/ap.css';
import Header from '../header';
import Footer from '../footer';
import { onGetEvents } from '../../store/actions/getEvents';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { format } from 'date-fns';
import Truncate from 'react-truncate';
import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import moment from 'moment';

class Category extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keyword: '',
			state: '',
		};
	}

	onEnter = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	componentDidMount() {
		this.props.onGetEvents()
		window.scrollTo(0, 0)
	}

	componentDidUpdate() {
		ReactDOM.findDOMNode(this).scrollTop = 0
		window.scrollTo(0, 0)
	}
	render() {
		var settings = {
			dots: true,
			infinite: true,
			autoplay: true,
			speed: 300,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1
		};
		const { events: { response: eventsResponse } } = this.props;
		const { keyword, state } = this.state;
		return (
			<div className="innerSearch">
				<Header />
				<div className="container-fluid searchContainer">
					<div className="container-fluid">
						<div className="categoryHeader row ">
							{/* <div className="col-sm-3 ">
								<h4 className="text-white">Browse Category</h4>
							</div> */}
							<div className="col-sm-7 ">
								<form className="form-inline">
									<label className="sr-only" for="question">
										Question
									</label>
									<input
										type="text"
										className="form-control mb-2 search-home"
										id="question"
										name="keyword"
										placeholder="Name of an event"
										value={keyword}
										onChange={this.onEnter}
									/>

									<label className="sr-only" for="location">
										Location
									</label>
									<div className="input-group mb-2 homesearch-input">
										<div className="input-group-prepend">
											<div className="input-group-text">
												<img
													className="search-location"
													src="/image/location.svg"
													alt=""
													height="12"
												/>
											</div>
										</div>
										{/* <input
											type="text"
											className="form-control search-placeholder search-home"
											id="location"
											name="state"
											placeholder="State"
											value={state}
										onChange={this.onEnter}
										required
										/> */}
									</div>
									<Link to={(`/search/${keyword}`)} className="btn src-btn btn-primary mb-2 search-button">
										Search
								</Link>
								</form>
							</div>
							{/* <div className="col-sm-2">
								<div class="form-group ">
									<select className="custom-select" required>
										<option value="">All Category</option>
										<option value="1">Music</option>
										<option value="2">Art</option>
										<option value="3">Comedy</option>
									</select>
								</div>
							</div> */}
						</div>
					</div>

					<div className="container-fluid">
						<div className="row">
							{eventsResponse && eventsResponse.map((value, key) => (
								<div className="col-12 col-sm-6 col-md-3 mb-4 bottomSpace" >
									<div className="card event-card ">
										<div className="card-image-container">
											<img
												className="card-image"
												src={value.coverImage}
												alt="Events in Nigeria"
											/>
											<div className="details-section">
												<div className="event-date">
													<span className="event-date__day">
														{new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')).getDate()}{' '}
													</span>
													<span className="event-month">
														{format(new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')), 'MMM')}
													</span>{' '}
												</div>
												<div className="other-info">
													<div className="event-details">
														<Link className='event-name' to={`/event/${value._id}`}>
															<Truncate
																lines={1}
																width={220}
																ellipsis={<span className="event-title">...</span>}
															>
																{value.name}
															</Truncate>
														</Link>
														<div className="clearfix mt-2">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="../image/locations.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">{value.state}</p>
														</div>
														<div className="clearfix">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="../image/price-tag-white.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">
																{' '}
																Starting from <span className="price">{Number(value.starting_from).toLocaleString(`en-${(value.currency || 'NGN').substring(0, 2)}`, {
																style: 'currency',
																currency: (value.currency || 'NGN'),
																maximumFractionDigits: 2
															})}  </span>
															</p>
														</div>
														<Link to={`/event/${value._id}`} className="btn btn-ticket mb-3">
															Buy Ticket
													</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>


					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	events: state.events
})

const mapDispatchToProps = (dispatch) => ({
	onGetEvents: bindActionCreators(onGetEvents, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Category))
