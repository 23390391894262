export default function(url = false){

    const host = window.location.host;
    

    let dev = "live";

    let h = host.split(":")
    
    if (h[1] && h[0] == "localhost") dev = "local"

    // if (h[1] && h[0] == "localhost") dev = "heroku"

    if (host.includes("herokuapp")) dev = "heroku"

    if(url){

        if(dev == "heroku"){
            return "https://nairabox-event-api.herokuapp.com/api/"            
        } else if(dev == "local"){
            return "http://localhost:4000/api/"
        } else {
            return "https://www.nairabox.net/api/"
        }
        
    }

    console.log(dev)
    return dev
};