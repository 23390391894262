
const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    response: [],
    error: {}
};

const categories = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GET_CATEGORIES_PENDING": {
            return {
                ...state,
                fetching: true,
                fetched: false
            };
        }
        case "GET_CATEGORIES_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                response: action.payload.data
            };
        }
        case "GET_CATEGORIES_REJECTED": {
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.payload
            };
        }
        default: {
            return {
                ...state
            }
        };
    }
};

export default categories;