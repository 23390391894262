import axios from 'axios';
import env from '../../env.js';

const getPopularEvent = (url) => ({
  type:'GET_POPULAR_EVENT',
  payload:axios(url)
})

export const onGetPopularEvents = () => async (dispatch) => {
  const url=`${env(true)}events/featured`
  dispatch(getPopularEvent(url))
}