import React, { Component } from 'react';
import '../../App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default class Merch extends Component {
	render() {
		const options = {
			responsive: {
				1000: {
					items: 2
				},
				0: {
					items: 1
				}
			}
		};
		return (
			

			<div className="container-fluid">
				<section name="merch/free" id="merch_free">
					<div className="col-12 ">
						<div className="row">
							<div className="col-sm-6" id="merchandize">
								<h4 className="page-title">Merchandizes</h4>
								<OwlCarousel
									className="owl-theme"
									loop
									margin={20}
									nav
									items={2}
									//autoplay={true}
									autoplayTimeout={7000}
									responsiveClass={true}
									dots={false}
									{...options}
								>
									<div className="card event-card merch-img">
										<div className="card-image-container">
											<img id="merch-product" src="/image/merch1.png" alt="Events in Nigeria" />
											<div className="event-details">
												<a className="event-title" href="">
													Major Lazer Branded Shirt
												</a>
												<div className="clearfix ">
													<p className="d-inline float-left mr-2 event-icon">
														{' '}
														<img
															src="/image/price-tag-white.svg"
															alt="event-location"
															height="15"
														/>
													</p>
													<p className="d-inline">
														<span className="price">10,000</span>
													</p>
												</div>
												<button className="btn btn-ticket mb-3">Buy Now</button>
											</div>
										</div>
									</div>

									<div className="card event-card merch-img">
										<div className="card-image-container">
											<img id="merch-product" src="/image/merch1.png" alt="Events in Nigeria" />
											<div className="event-details">
												<a className="event-title" href="">
													Major Lazer Branded Shirt
												</a>
												<div className="clearfix ">
													<p className="d-inline float-left mr-2 event-icon">
														{' '}
														<img
															src="/image/price-tag-white.svg"
															alt="event-location"
															height="15"
														/>
													</p>
													<p className="d-inline">
														<span className="price">10,000</span>
													</p>
												</div>
												<button className="btn btn-ticket mb-3">Buy Now</button>
											</div>
										</div>
									</div>

									<div className="card event-card merch-img">
										<div className="card-image-container">
											<img id="merch-product" src="/image/merch1.png" alt="Events in Nigeria" />
											<div className="event-details">
												<a className="event-title" href="">
													Major Lazer Branded Shirt
												</a>
												<div className="clearfix ">
													<p className="d-inline float-left mr-2 event-icon">
														{' '}
														<img
															src="/image/price-tag-white.svg"
															alt="event-location"
															height="15"
														/>
													</p>
													<p className="d-inline">
														<span className="price">10,000</span>
													</p>
												</div>
												<button className="btn btn-ticket mb-3">Buy Now</button>
											</div>
										</div>
									</div>

									<div className="card event-card merch-img">
										<div className="card-image-container">
											<img id="merch-product" src="/image/merch1.png" alt="Events in Nigeria" />
											<div className="event-details">
												<a className="event-title" href="">
													Major Lazer Branded Shirt
												</a>
												<div className="clearfix ">
													<p className="d-inline float-left mr-2 event-icon">
														{' '}
														<img
															src="/image/price-tag-white.svg"
															alt="event-location"
															height="15"
														/>
													</p>
													<p className="d-inline">
														<span className="price">10,000</span>
													</p>
												</div>
												<button className="btn btn-ticket mb-3">Buy Now</button>
											</div>
										</div>
									</div>
								</OwlCarousel>
							</div>

							<div className="col-sm-6" id="free_events">
								<h4 className="page-title">Free Events</h4>
								<OwlCarousel
									className="owl-theme"
									loop
									margin={20}
									nav
									items={2}
									// autoplay={true}
									autoplayTimeout={7000}
									responsiveClass={true}
									dots={false}
									{...options}
								>
									<div className="card event-card ">
										<div className="card-image-container">
											<img
												className="card-image"
												src="/image/major.jpg"
												alt="Events in Nigeria"
											/>
											<div className="details-section">
												<div className="event-date">
													<span>5 Oct</span>{' '}
												</div>
												<div className="other-info">
													<div className="event-details">
														<a className="event-title" href="">
															Major Lazer Sound System Live{' '}
														</a>
														<div className="clearfix mt-2">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/locations.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline"> Hard Rock Beach, V.I Lagos</p>
														</div>
														<div className="clearfix">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/price-tag-white.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">
																{' '}
																Starting from <span className="price">10,000</span>
															</p>
														</div>

														<a href="/event" className="btn btn-ticket mb-3">
															Buy Ticket
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="card event-card ">
										<div className="card-image-container">
											<img
												className="card-image"
												src="/image/missnigeria.jpg"
												alt="Events in Nigeria"
											/>
											<div className="details-section">
												<div className="event-date">
													<span>5 Oct</span>{' '}
												</div>
												<div className="other-info">
													<div className="event-details">
														<a className="event-title" href="">
															Mr & Miss Nigeria International{' '}
														</a>
														<div className="clearfix mt-2">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/locations.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline"> Ruby's Garden Chisco, Lekki...</p>
														</div>
														<div className="clearfix ">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/price-tag-white.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">
																{' '}
																Starting from <span className="price">10,000</span>
															</p>
														</div>

														<a href="/event" className="btn btn-ticket mb-3">
															Buy Ticket
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="card event-card ">
										<div className="card-image-container">
											<img
												className="card-image"
												src="/image/antheme.jpg"
												alt="Events in Nigeria"
											/>
											<div className="details-section">
												<div className="event-date">
													<span>12 Oct</span>{' '}
												</div>
												<div className="other-info">
													<div className="event-details">
														<a className="event-title" href="">
															The Anthem{' '}
														</a>
														<div className="clearfix mt-2">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/locations.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">
																{' '}
																Conference Hall, Nicon Luxury Hotel
															</p>
														</div>
														<div className="clearfix">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/price-tag-white.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">
																{' '}
																Starting from <span className="price">5,000</span>
															</p>
														</div>

														<a href="/event" className="btn btn-ticket mb-3">
															Buy Ticket
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="card event-card ">
										<div className="card-image-container">
											<img className="card-image" src="/image/life.jpg" alt="Events in Nigeria" />
											<div className="details-section">
												<div className="event-date">
													<span>12 Oct</span>{' '}
												</div>
												<div className="other-info">
													<div className="event-details">
														<a className="event-title" href="">
															Life is too short <em>with the Short Family</em>{' '}
														</a>
														<div className="clearfix ">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/locations.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">Muson Family</p>
														</div>
														<div className="clearfix">
															<p className="d-inline float-left mr-2 event-icon">
																{' '}
																<img
																	src="/image/price-tag-white.svg"
																	alt="event-location"
																	height="15"
																/>
															</p>
															<p className="d-inline">
																{' '}
																Starting from <span className="price">5,000</span>
															</p>
														</div>

														<a href="/event" className="btn btn-ticket mb-3">
															Buy Ticket
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</OwlCarousel>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
