import React, { Component } from 'react'
// import '../../App.css'

export default class AddInfo extends Component {
  render () {
    return (
      <div className='container-fluid'>
        <h4 className='page-title'>Additional Information</h4>
        <div className='row'>
          <div className='col-6 col-sm-3 detailsInfo'>
            <div className='addCard'>
              <div className='InfoImage'>
                <img src='/image/directions.png' alt='Events in Nigeria' />
              </div>
              <a href=''><h6>Get Direction</h6></a>
            </div>
          </div>
          <div className='col-6 col-sm-3 detailsInfo'>
            <div className='addCard'>
              <div className='InfoImage'>
                <img className='' src='/image/parking.png' alt='Events in Nigeria' />
              </div>
              <a href=''><h6>Parking Info</h6></a>
            </div>
          </div>
          <div className='col-6 col-sm-3'>
            <div className='addCard'>
              <div className='InfoImage'>
                <img className='' src='/image/cab.png' alt='Events in Nigeria' />
              </div>
              <a href=''><h6>Get Cab</h6></a>
            </div>
          </div>
          <div className='col-6 col-sm-3 detailsInfo'>
            <div className='addCard'>
              <div className='InfoImage'>
                <img className='' src='/image/food.png' alt='Events in Nigeria' />
              </div>
              <a href=''><h6>Get Food</h6></a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
