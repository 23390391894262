import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, {Component} from 'react';
import axios from 'axios';
import AppBar from 'material-ui/AppBar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
// import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
class ResendTicket extends Component {
constructor(props){
  super(props);
  this.state={
    transactionID:'',
  name:'',
  submitted: false,
  value: ''
  }
 }
 handleClick = (event)=> {
    event.preventDefault();
    this.setState({ submitted: true });
    if(this.state.transactionID != 0 && this.state.name != 0){
    // var apiBaseUrl = "http://localhost:4000/api/";
     var apiBaseUrl = "https://www.nairabox.net/api/";
    var self = this;
    var payload={
    "transactionID":this.state.transactionID,
    "name":this.state.name
    }
    axios.post(apiBaseUrl+'resendTicket', payload)
    .then(function (response) {
   // console.log(response);
    if(response.data.status === 404){
    console.log("Transaction not found");
    alert(response.data.message)
    // var uploadScreen=[];
    // uploadScreen.push(<UploadScreen appContext={self.props.appContext}/>)
    // self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
    }
    else if(response.data.status === 200){
    
    alert(response.data.message)
    }
    // else{
    // console.log("Username does not exists");
    // alert("Username does not exist");
    // }
    })
    .catch(function (error) {
    console.log(error);
    });
}
    }

    validateForm() {
        return this.state.transactionID.length > 0 && this.state.name.length > 0;
      }
      handleChange = event => {
        this.setState({
          [event.target.name]: event.target.value
        });
      }
      handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ submitted: true });
        const { transactionID, name } = this.state;
        const { dispatch } = this.props;
        if (transactionID && name) {
           // dispatch(userActions.login(transactionID, name));
          // console.log(this.state)
        }
    }
      render() {
        const { loggingIn } = this.props;
        const { transactionID, name, submitted, value } = this.state;
        
        return (
            <div className="col-md-6 col-md-offset-3">
                <h2>Resend Email</h2>
                <form name="form" onSubmit={this.handleClick}>
               
                    <div className={'form-group' + (submitted && !transactionID ? ' text-danger' : '')}>
                        <label htmlFor="transactionID">Transaction ID</label>
                        <input type="text" className="form-control" name="transactionID" value={transactionID} onChange={this.handleChange} />
                        {submitted && !transactionID &&
                            <div className="help-block">transaction ID is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !name ? ' text-danger' : '')}>
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" name="name" value={name} onChange={this.handleChange} />
                        {submitted && !name &&
                            <div className="help-block">Name is required</div>
                        }
                    </div>
                    {/* <div className={'form-group' + (submitted && !value ? ' has-error' : '')}>
                        <label htmlFor="event">Event</label>
                        <select value={this.state.value} onChange={this.handleChange}>
            <option value="grapefruit">Grapefruit</option>
            <option value="lime">Lime</option>
            <option value="coconut">Coconut</option>
            <option value="mango">Mango</option>
          </select> */}
                        {/* <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} /> */}
                        {/* {submitted && !value &&
                            <div className="help-block"> Select Event</div>
                        }
                    </div> */}
                    <div className="form-group">
                        <button className="btn btn-primary">Resend Email</button>
                        {/* {loggingIn &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        } */}
                        {/* <Link to="/register" className="btn btn-link">Register</Link> */}
                    </div>
                </form>
            </div>
        );
      }
    }
// render() {
//     return (
//       <div>
//         <MuiThemeProvider>
//           <div>
//           <AppBar
//              title="Login"
//            />
//            <TextField
//              hintText="Enter your Username"
//              floatingLabelText="Username"
//              onChange = {(event,newValue) => this.setState({username:newValue})}
//              />
//            <br/>
//              <TextField
//                type="password"
//                hintText="Enter your Password"
//                floatingLabelText="Password"
//                onChange = {(event,newValue) => this.setState({password:newValue})}
//                />
//              <br/>
//              <RaisedButton label="Submit" primary={true} style={style} onClick={(event) => this.handleClick(event)}/>
//          </div>
//          </MuiThemeProvider>
//       </div>
//     );
//   }
// }
const style = {
 margin: 15,
};
export default ResendTicket;