import React, {Component} from 'react';
import Main from './container/main';
// import auth from './auth.ts'; // Sample authentication provider
import ReactGA from 'react-ga';


const trackingId = "UA-153493420-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

class App extends Component {
  render () {
    return (
      <div className="App">
        <Main />
      </div>
    );
  }
}

export default App;
