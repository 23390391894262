import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import axios from 'axios';
import env from '../../env';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';

import Payment from '../payment';

import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {onGetOneEvent} from '../../store/actions/getOneEvent';

class BuyTicketNew extends Component {

    constructor(props) {
        let tickets = ""
        var disabledMessage;
        super(props);
        this.state = {
            multiple: false,
            people: [],
            voucher: "",
            ticketsClasses: 0,
            tickets: "",
            showVoucherText: "",
            successCode: "Processing payment....",
            showVoucherError: "",
            runPayment: "",
            hidePaymentBtn: false,
            total: 0,
            noOfTickets: 0,
            subTotal: 0,
            serviceCharge: 0,
            classOrders: {},
            email: '', // customer email
            amount: 0, // equals NGN 1000. Minimum amount allowed NGN 1 while on production or live system, it's 10
            phone: '',
            firstname: '',
            //end others
            peopleByTicketType: {},
            person: {},

            ticketSeats: {},

            value: 'coconut',
            amount: 0, // equals NGN 1000. Minimum amount allowed NGN 1 while on production or live system, it's 10
            address: '',
            gender: '',
            age: '',
            eventID: '',
            eventName: '',
            twitter: '',
            instagram: '',
            membership_id: '',
            vouchervalue: 0,
            membership: '',
            checked: false,
            isChecked: false,
            day: '',
            eventID: '',
            venue: '',
            selectedTime: [],
            selectedDay: [],
            disabled: false,
            location: '',
            locations: [],
            locationNames: [],

            selectedLocations: [],
            selectedLocation: {venue: null, date: null, time: null},

            isAdultChecked: false,
            isInsuranceChecked: false,
            numOfInsuree: 0,
            multipleInsuraceList: [],
            accceptEventTerms: false,

            toggle_terms: false,
            toggle_terms2: false,


            terms: ""
        }
    }

    checkEmail(email) {
        if (email == "") return false;
        var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        if (regex.test(email)) return true;
        else return false;
    }


    handleChange = (event) => {
        this.setState({membership: event.target.value});
    }

    handleGender = (event) => {
        this.setState({gender: event.target.value});
    }

    handleHomecomingDay = (event) => {
        this.setState({location: event.target.value});
    }

    handleHomecomingTime = (event) => {
        this.setState({time: event.target.value});
    }

    handleDayTime = (event) => {
        this.setState({dayTime: event.target.value});

    }

    handleDay = (event) => {
        this.setState({day: event.target.value});
    }

    handleAdult = () => {
        this.setState({
            isAdultChecked: !this.state.isAdultChecked,
        });
    }

    handleCheck = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    ticketDisplay(classOrders) {
        let ma = ''
        Object.keys(classOrders).forEach(key => {
            if (classOrders[key] == 0) {
                console.log("no id")
            } else {
                ma = classOrders[key]
            }
        })
        console.log('ma: ', ma)
    }

    doRepeat(ma, compnt) {
        var ret = [compnt];
        console.log('here')
        console.log(ma)
        console.log(ret)
        if (ma <= 1) {
            console.log('mine here')
            return ret;
        }
        return ret.concat(this.doRepeat(ma - 1, compnt))
    }

    repeatThis(ma, classOrders, compnt) {
        var ret = [compnt];
        Object.keys(classOrders).forEach(key => {
            if (classOrders[key] == 0) {
                console.log("no id")
            } else {
                ma = classOrders[key]
                if (ma <= 1) {
                    return ret;
                }
                return ret.concat(this.repeatThis(ma - 1, 0, compnt))
            }
        })
        if (ma <= 1) {
            return ret;
        }
        return ret.concat(this.repeatThis(ma - 1, 0, compnt))
    }

    handleInsuranceCheck = () => {
        let {multipleInsuraceList} = this.state;
        let numOfInsuree = 0;
        const {oneEvent: {response: {event, classes}}, classOrders, history, match} = this.props;

        classes && classes.forEach((dclass) => {
            if (classOrders[dclass._id]) numOfInsuree += Number(classOrders[dclass._id])
        });

        let classesWithQty = classes && classes.map((item) => {
            return {...item, quantity: classOrders[item._id]}
        });

        let classesWithQtyNotZero = classesWithQty && classesWithQty.filter((item) => item.quantity > 0);

        classesWithQtyNotZero && classesWithQtyNotZero.forEach((item, index) => {
            let theQty = Number(item.quantity);

            ([...Array(theQty).keys()]).forEach((item2, index) => {
                console.log("yYAY", item, index)
                multipleInsuraceList.push(item._id + index);
            });
        })

        this.setState({
            isInsuranceChecked: !this.state.isInsuranceChecked,
            numOfInsuree: numOfInsuree,
            multipleInsuraceList: multipleInsuraceList
        }, () => {
            console.log("New state", this.state)
        });
    }

    handleRemoveInsurance = (e) => {
        let {numOfInsuree, multipleInsuraceList} = this.state;
        let newList = multipleInsuraceList.filter(it => it !== e.target.dataset.target);
        this.setState({numOfInsuree: numOfInsuree > 0 ? numOfInsuree - 1 : 0, multipleInsuraceList: newList}, () => {
            if (this.state.numOfInsuree === 0) {
                this.setState({isInsuranceChecked: false})
            }
            console.log("New state", this.state)
        });
    }

    handleAddInsurance = (e) => {
        let {numOfInsuree, multipleInsuraceList} = this.state;
        multipleInsuraceList.push(e.target.dataset.target)
        this.setState({numOfInsuree: numOfInsuree + 1, multipleInsuraceList: multipleInsuraceList}, () => {
            console.log("New state", this.state)
        });
    }

    async checkAll() {
        console.log("*********************************", this.state.accceptEventTerms)
        let ret = true;

        if (!this.state.multiple) {
            if (!this.state.person.firstName || !this.state.person.lastName || !this.state.person.phone || !this.state.person.email) {
                ret = false;
            }
            if (this.state.isInsuranceChecked) {
                if (!this.state.person.age || !this.state.person.nok_name || !this.state.person.nok_phone) {
                    ret = false;
                }
            }

            if (this.state.locations && this.state.locations.length) {
                if (!this.state.person.venue || !this.state.person.date_select || !this.state.person.time_select) {
                    ret = false;
                }
            }
        } else {
            let keys = Object.keys(this.state.peopleByTicketType)
            for (let i = 0; i < keys.length; i++) {
                const ele = this.state.peopleByTicketType[keys[i]];
                ele.forEach(elem => {
                    if (!elem.firstName || !elem.lastName || !elem.phone || !elem.email) {
                        ret = false;
                    }
                    if (this.state.isInsuranceChecked) {
                        if (elem.age || elem.nok_name || elem.nok_phone) {
                            if (!(elem.age && elem.nok_name && elem.nok_phone)) {
                                ret = false;
                            }
                        }
                    }

                    if (this.state.locations && this.state.locations.length) {
                        if (!elem.venue || !elem.date_select || !elem.time_select) {
                            ret = false;
                        }
                    }
                });
            }

        }

        return ret;
    }

    myFunction(yo, isMultiple, index, itemId) {
        const {oneEvent: {response: {event, classes}}, oneEvent, user} = this.props;
        for (var prop in event) {

            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.props.classOrders, classes)


            let ks = Object.keys(this.props.classOrders);
            let k = false;
            let selectedClass = false;
            ks.forEach(element => {
                if (!k && this.props.classOrders[element]) {
                    k = element;
                }
            });
            if (k) {
                classes.forEach(element => {
                    if (element._id == k) {
                        selectedClass = element;
                    }
                });
            }


            this.state.eventID = event[prop]._id
            this.state.eventName = event[prop].name

            this.state.terms = event[prop].terms

            if (selectedClass && !selectedClass.disableSelect) {
                this.state.locations = event[prop].locations
            }
            this.state.locationNames = event[prop].locationNames ? event[prop].locationNames : []
            // this.setState({locations: event[prop].locations})
        }

        let peopleByTicketType = this.state.peopleByTicketType;
        let peopleByTicketId = peopleByTicketType[itemId];

        let person = this.state.person;


        if (this.state.locations && this.state.locations.length) {


            return (

                // Venue drop down list
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <select onChange={isMultiple ? this.handleInputChange.bind(null, index, itemId) :
                            this.handleInputChangeSingle} className="form-control" name="venue" required={true}
                                placeholder="Choose a venue">
                            <option
                                value="">{this.state.locationNames.length ? this.state.locationNames[0] : "-- Select preferred venue --"}</option>
                            {
                                // Loop through locations array
                                this.state.locations.map((i, j) => {
                                    return <option value={i.venue} key={j} currentIndex={j}
                                                   tabIndex={j}>{i.venue}</option>
                                })

                            }
                        </select>
                    </div>

                    <div className="col-lg-12 mb-3">

                        {isMultiple ?
                            (this.state.selectedLocations[index] && this.state.selectedLocations[index].venue != null ?
                                <select onChange={isMultiple ? this.handleInputChange.bind(null, index, itemId) :
                                    this.handleInputChangeSingle} className="form-control" name="date_select"
                                        required={true} placeholder="Choose a date">
                                    <option
                                        value="">{this.state.locationNames.length ? this.state.locationNames[1] : "-- Select preferred date --"}</option>
                                    {
                                        // Loop through locations array
                                        this.state.locations[this.state.selectedLocations[index].venue].dates.map((i, j) => {
                                            return <option value={i.date} tabIndex={j} key={j}>{i.date}</option>
                                        })
                                    }
                                </select> :
                                null)
                            :
                            (this.state.selectedLocation.venue != null ?
                                <select onChange={this.handleInputChangeSingle} className="form-control"
                                        name="date_select" required={true} placeholder="Choose a date">
                                    <option
                                        value="">{this.state.locationNames.length ? this.state.locationNames[1] : "-- Select preferred date --"}</option>
                                    {
                                        // Loop through locations array
                                        this.state.locations[this.state.selectedLocation.venue].dates.map((i, j) => {
                                            return <option value={i.date} tabIndex={j} key={j}>{i.date}</option>
                                        })
                                    }
                                </select> :
                                null)
                        }
                    </div>

                    <div className="col-lg-12 mb-3">
                        {isMultiple ?
                            (this.state.selectedLocations[index] && this.state.selectedLocations[index].date != null ?
                                <select onChange={isMultiple ? this.handleInputChange.bind(null, index, itemId) :
                                    this.handleInputChangeSingle} className="form-control" name="time_select"
                                        required={true} placeholder="Choose a time">
                                    <option
                                        value="">{this.state.locationNames.length ? this.state.locationNames[2] : "-- Select preferred time --"}</option>
                                    {
                                        // Loop through locations array
                                        this.state.locations[this.state.selectedLocations[index].venue].dates[this.state.selectedLocations[index].date].time.map((i, j) => {
                                            return <option value={i} tabIndex={j} key={j}>{i}</option>
                                        })
                                    }
                                </select> :
                                null)
                            :
                            (this.state.selectedLocation.date != null ?
                                <select onChange={this.handleInputChangeSingle} className="form-control"
                                        name="time_select" required={true} placeholder="Choose a time">
                                    <option
                                        value="">{this.state.locationNames.length ? this.state.locationNames[2] : "-- Select preferred time --"}</option>
                                    {
                                        // Loop through locations array
                                        this.state.locations[this.state.selectedLocation.venue].dates[this.state.selectedLocation.date].time.map((i, j) => {
                                            return <option value={i} tabIndex={j} key={j}>{i}</option>
                                        })
                                    }
                                </select> :
                                null)
                        }
                    </div>
                </div>


            )
        }


    }

    showError = () => {
        alert("All fields are required....Please Check that all fields are filled correctly")
    };


    callback = async (toSend) => {
        console.log("toSend", toSend)
        toSend.affiliateID = window.sessionStorage && window.sessionStorage.getItem('affiliate');

        swal("Ticket Purchase Successful!", "Sending data to server", "success").then(() => {
        });

        try {

            let res = await axios.post(`${env(true)}issueEventFree`, toSend)


            for (let i = 0; i < res.data.responses.length; i++) {
                const ele = res.data.responses[i];


                if (ele.status) {

                    i == 0 ? window.location.href = ele.url : window.open(ele.url, '_blank');

                } else {
                    throw new Error(ele.message);
                    swal("Ticket issuing error", ele.message, "error");
                }


            }

            swal("Ticket Purchase Successful!", res.data.message, "success").then(() => {
            });

        } catch (e) {

            // if(e.response && e.response.data){
            // swal("Ticket issuing error", e.response.data.message, "error");
            // }else{
            // 	swal("Ticket issuing error", e.message, "error");
            // }


            swal("Ticket Booked", "Your ticket has been booked and awaiting confirmation", "success");


        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (!this.state.multiple && this.state.multiple !== prevState.multiple) {
        //   console.log('this.state.multiple: ', this.state.multiple);
        //   this.setState({ peopleByTicketType: {} })
        // }
    }

    async getAvailableSeats(id, count) {
        try {
            let response = await axios.get(`${env(true)}get-ticket-tables/${id}/${count}`)
            //console.log("11111111111111111111", response.data)
            return response.data;

        } catch (e) {
            //console.log("22222222222222222222", e.response)
            if (e.response && e.response.data) {

                return e.response.data
            } else {
                return {status: 0, message: e.message}
            }

        }
    }

    componentDidMount() {
        // this.setState({ classOrders: this.props.classOrders }); //copies, might not need this
        console.log("++++++++++++++++++++++++++++++++++++++++++", this.props.classOrders)
        let {classOrders} = this.props;
        let noOfTickets = Object.values(classOrders).reduce((total, curr) => {
            return total + parseInt(curr)
        }, 0)
        this.setState({noOfTickets})

        if (noOfTickets > 1) {
            this.setState({multiple: true});
        }

        Object.keys(classOrders).forEach(async (element) => {
            let response = await this.getAvailableSeats(element, classOrders[element])
            let ticketSeats = this.state.ticketSeats;
            ticketSeats[element] = response
            //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", response)
            this.setState({ticketSeats})
        });

    }

    payFree = (event, amount, firstname, phone, email, gender, age, membership_id, membership, address, eventName, eventID, classes) => {
        console.log(amount.subTotal)
        console.log(membership.membership)
        if (amount.subTotal = 0 && membership.membership == "ASIS Student Members") {
            var apiBaseUrl = `${env(true)}`;
            var self = this;
            var payload = {
                amount: amount,
                firstname: firstname,
                phone: phone,
                email: email,
                gender: gender,
                age: age,
                membership_id: membership_id,
                membership: membership,
                address: address,
                // order : {this.props.classOrders},
                // history :{this.props.history},
                eventDetails: eventName,
                eventID: eventID,
                classDetails: classes
            }
            axios.post(apiBaseUrl + 'issueEvent', payload)
                .then(function (response) {
                    // console.log(response);
                    if (response.data.status === 404) {
                        console.log("Transaction not found");
                        alert(response.data.message)
                        // var uploadScreen=[];
                        // uploadScreen.push(<UploadScreen appContext={self.props.appContext}/>)
                        // self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
                    } else if (response.data.status === 200) {

                        alert(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    pay() {
        const {oneEvent: {response: {event, classes}}, oneEvent, user, classOrders} = this.props;

        let valuesFromClasses = this.valuesAfterLopping();
        let {sTotal, serviceCharge} = valuesFromClasses
        //let subTotal = sTotal;
        let {currency, forAdult} = this.props.event;


        //const { oneEvent: { response: { event, classes } }, oneEvent, user, classOrders } = this.props;
        //let valuesFromClasses = this.valuesAfterLopping();
        //let { sTotal, serviceCharge } = valuesFromClasses
        let subTotal = this.state.vouchervalue ? this.state.vouchervalue : sTotal;
        subTotal += (this.state.isInsuranceChecked ? 500 * this.state.numOfInsuree : 0);
        //let yo = event;
        //let { address, eventID, gender, day, age, membership_id, membership, eventName, location, time, date, venue } = this.state;
        //let { peopleByTicketType } = this.state;

        //console.log('yoooooooooooooooooooooo', yo)
        //const { subTotal/*, total, serviceCharge, sTotal*/ } = this.state;
        let { /*email, firstname, phone,*/
            address,
            eventID,
            gender,
            day,
            age,
            membership_id,
            membership,
            eventName,
            location,
            time,
            date,
            venue
        } = this.state;
        let {person} = this.state;
        let {peopleByTicketType} = this.state;

        //start
        let theClassOrders = Object.keys(classOrders);
        console.log('theClassOrders: ', theClassOrders);
        this.ticketsClasses = theClassOrders.length

        //end

        let thePersonObj;
        let thePeople;
        let selectedClassesWithPerson;
        let pushedPeople = {};

        let selectedClasses
        this.state.tickets = selectedClasses
        if (theClassOrders.length) {
            selectedClasses = theClassOrders.filter(item => classOrders[item] !== 0);
            console.log('selectedClasses in if: ', selectedClasses)

        }


        if (this.state.multiple && (Object.keys(peopleByTicketType).length > 0)) {

            thePeople = peopleByTicketType;

            let firstTicketType = Object.keys(peopleByTicketType)[0];
            let firstTicketPeople = peopleByTicketType[firstTicketType]
            let firstPerson = firstTicketPeople[0];
            thePersonObj = firstPerson
            console.log('thePersonObj in multiple: ', thePeople)
        } else {
            if (this.state.person && Object.keys(this.state.person).length) {
                thePersonObj = this.state.person;
                //thePeople = this.state.person
                //this.setState({peopleByTicketType: {}})

                selectedClassesWithPerson = selectedClasses && selectedClasses.forEach(item => {
                    //start

                    let orderQty = classOrders[item];

                    console.log(`item: ${item}, classOrders[item]: ${classOrders[item]}`)

                    console.log(`selectedClasses[item]: ${selectedClasses[item]}`)
                    //thePersonObj.ticketQty = orderQty;


                    //end

                    console.log('in selectedClassesWithPerson: ', item);
                    // thePersonObj.ticketQty2 = classOrders[item];
                    item = pushedPeople[item] = [thePersonObj]
                    //[pushedPeople[item] ].tickeyQty = selectedClasses[item];
                    //pushedPeople && console.log('pushedPeople', pushedPeople)
                })
                //thePeople = { ...selectedClasses };
                let som = Object.keys(pushedPeople).forEach((item, i) => {
                    console.log('pushedPeople in keys: ', pushedPeople)
                    console.log('item ', i, item)
                    pushedPeople[item][0].isSingle = true

                    console.log('pushedPeople[item] ', i, pushedPeople[item])
                    console.log(' pushedPeople[item][0]', pushedPeople[item][0])
                    // pushedPeople[item][0].ticketQty3 = classOrders[item]
                })
                thePeople = {...pushedPeople}
                console.log('selectedClassesWithPerson: okay', thePeople)

                console.log('thePersonObj in single: ', thePersonObj)

            }


        }

        //let { firstName, email, phone } = person;
        let {person: dperson} = this.state;
        //let { firstName, email, phone } = dperson;
        //    let { firstName, email, phone } = (Object.keys(thePersonObj).length > 0) ? thePersonObj : { firstName: '', email: '', phone: '' };
        let thePersonObjTotalKeys = thePersonObj && Object.keys(thePersonObj);
        console.log('thePersonObjTotalKeys: ', thePersonObjTotalKeys)
        let firstName, lastName, email, phone
        //membership_id;
        if (thePersonObj && (thePersonObjTotalKeys.length > 0)) {
            console.log('inside if the...keys')
            firstName = thePersonObj.firstName;
            lastName = thePersonObj.lastName;
            email = thePersonObj.email;
            phone = thePersonObj.phone
            membership = thePersonObj.membership
            membership_id = thePersonObj.membership_id
            //thePersonObj.ticketQty = this.state.noOfTickets;

            console.log(`firstName: ${firstName}, email: ${email}, phone: ${phone}`)

        }
        //  let { firstName, email, phone } = dperson;
        let firstname = firstName;
        let lastname = lastName;
        //console.log('person in pay: ', thePersonObj);

        let chck = true;

        if (this.state.terms && this.state.terms.length > 7) {
            if (!this.state.accceptEventTerms) {
                //swal("TERMS AND CONDITIONS", "You have not accepted the event's Terms and Conditions", "error").then(() => {  });
                chck = false
            }
        }


        const userDetails = {
            //	txref: txref,
            classid: this.props.classOrders,
            amount: subTotal,
            firstname: firstName,
            lastname,
            email: email,
            phone: phone,
            day: day,
            date: date,
            dayTime: date + " " + time,
            person: thePersonObj,
            people: thePeople,
            venue: venue,
            time: time,
            currency
        }

        let toSend = {firstname, lastname, amount: subTotal, event: eventName, userDetails: userDetails}

        if (subTotal == 0) {
            return (
                <div>
                    {
                        (phone != 0 && isNaN(phone) == false && email != false && this.checkEmail(email) /*firstname != 0 && */ && chck && this.state.isChecked != 0 && (!forAdult || (forAdult && this.state.isAdultChecked)) && this.checkAll()) ?
                            (<button className="payButton btn btn-white-main" onClick={() => {
                                this.callback(toSend)
                            }}>Get free tickets</button>) :
                            (<button className="payButton btn btn-white-main" onClick={this.showError}>You still have
                                fields to fill</button>)
                    }
                </div>
            )
        } else if (this.state.locations && this.state.locations.length) {
            console.log("Current state", this.state)
            return (
                <div>
                    {
                        (phone != 0 && isNaN(phone) == false && email != false && (!forAdult || (forAdult && this.state.isAdultChecked)) &&
                            this.checkEmail(email) /*firstname != 0 && */ && chck && this.state.isChecked != 0 && this.checkAll()
                        ) ? (<Payment
                                amount={subTotal}
                                firstname={firstName}
                                phone={phone}
                                email={email}
                                day={day}
                                date={date}
                                time={time}
                                eventID={eventID}
                                test={false}
                                order={this.props.classOrders}
                                venue={venue}
                                dayTime={date + " " + time}
                                history={this.props.history}
                                eventDetails={eventName}
                                classDetails={classes}
                                person={thePersonObj}
                                people={thePeople}
                                currency={currency || 'NGN'}
                                isInsuranceActive={this.state.isInsuranceChecked}
                            />) :
                            (<button className="payButton btn btn-white-main" onClick={this.showError}>You still have
                                fields to fill</button>)
                    }
                </div>
            )
        } else {
            return (
                <div>
                    {
                        (phone && isNaN(phone) == false != '' && (!forAdult || (forAdult && this.state.isAdultChecked)) &&
                            email != '' && this.checkEmail(email) && firstName != '' && this.state.isChecked != 0 && chck && this.checkAll()
                        )
                            ?
                            (<Payment amount={subTotal}
                                      firstname={firstName}
                                      phone={phone} email={email}
                                      eventID={eventID}
                                      order={this.props.classOrders}
                                      history={this.props.history}
                                      eventDetails={eventName}
                                      classDetails={classes}
                                      person={thePersonObj}
                                      people={thePeople}
                                      currency={currency || 'NGN'}
                                      isInsuranceActive={this.state.isInsuranceChecked}
                            />) :
                            (
                                <button className="payButton btn btn-white-main" onClick={this.showError}>
                                    You still have fields to fill
                                </button>
                            )
                    }
                </div>
            )
        }
    }

    showVoucherTestErr() {

        const err = () => [
            <span class="text-danger">{this.state.showVoucherError}</span>, this.pay()
        ]

        return (
            err()
        )
    }

    voucher() {

        const {oneEvent: {response: {event, classes}}, classOrders, match, history} = this.props;
        let showText = "";
        const apiBaseUrl = `${env(true)}`;
        //const apiBaseUrl = "http://localhost:4000/api/"
        const showError = (str) => {
            alert(str)
        }

        const handleVoucherSubmit = (event) => {
            event.preventDefault()
            var tickets = this.tickets
            var price = this.ticketprice
            var eventid = this.state.eventID
            var voucher_box = this.state.voucher
            var ticktClass = this.state.ticketClassNum
            let toSend = {price: price, tickets: tickets, voucher: this.state.voucher, ...this.props}
            var ticketID = Object.keys(classOrders)
            console.log(ticketID, "ticketID")
            if (voucher_box.length < 1) {
                alert("Please enter a voucher to continue.")
                return;
            }
            if (tickets > 1) {
                alert("Voucher is only allowed for one ticket.")
                return;
            }
            if (ticktClass > 1) {
                alert("This voucher is only allowed for one ticket type, re-select your ticket.")
                return;
            }

            try {

                axios.post(apiBaseUrl + 'ticketvoucher', toSend)
                    .then(function (response) {
                        var server_ticket = ""
                        if (response.data.voucher.ticketID) {
                            server_ticket = response.data.voucher.ticketID
                        }
                        checkValidEvent(response, eventid, price, toSend, server_ticket, ticketID)
                    }).catch(function (error) {
                    showError(error.response.data.message || "Invalid Voucher code.")
                });
            } catch (e) {
                console.log(e)
            }

        }

        const checkValidEvent = (response, eventid, price, toSend, ticketID1, ticketID2) => {
            var event_id_ = response.data.voucher.event_id
            if (response.data.voucher.ticketClass){
                const canApply = ticketID2.some((item) => {
                    return response.data.voucher.ticketClass.includes(item)
                });   
                
                if (canApply){
                    handleResponse(response, price, toSend);
                }else{
                    this.setState({showVoucherText: ""});
                    this.setState({showVoucherError: "This voucher can not be applied to this event class"})
                }

            }else if (this.props.location.pathname.includes(event_id_)) {
                if (ticketID1) {
                    console.log(ticketID1 + ":" + ticketID2)
                    if (ticketID1 == ticketID2) {
                        handleResponse(response, price, toSend)
                    } else {
                        this.setState({showVoucherText: ""});
                        this.setState({showVoucherError: "The Event is valid, but the voucher is not for this ticket."})
                    }
                } else {
                    handleResponse(response, price, toSend)
                }
            } else {
                this.setState({showVoucherText: ""});
                this.setState({showVoucherError: "This voucher is not for this event, please contact issuer."})
            }
        }
        const handleResponse = (response, price, toSend) => {
            console.log(response)
            // showError(response.data.message)

            if (response.data.voucher.value >= price) {
                showText = response.data.message
                console.log(showText)
                this.setState({showVoucherText: response.data.message});
                makepayment(price, toSend.classOrders, response.data.voucher.value)
            } else {
                if (this.checkAll() && this.state.isChecked != 0) {
                    showText = response.data.message
                    console.log(showText)
                    this.setState({showVoucherText: response.data.message});
                    this.setState({vouchervalue: response.data.voucher.value})
                    this.setState({showVoucherText: "Voucher Applied successfully.", showVoucherError: ""})
                } else {
                    console.log("didnt go")
                    this.setState({showVoucherText: ""});
                    this.setState({showVoucherError: "All fields are required....Please Check that the table information are filled correctly. Then retry Voucher again."})
                }
            }

            console.log(this.state)
        }

        const makepayment = (price, classOrders, value) => {
            let {phone, email, firstName, voucher} = this.state;

            this.setState({amount: price})
            this.setState({classid: classes})

            var tickets = this.tickets
            var price = this.ticketprice
            var eventid = this.state.eventID
            var voucher_box = this.state.voucher
            var ticktClass = this.state.ticketClassNum
            this.setState({classOrders: classOrders})
            let toSend = this.state //{ price: price, tickets: tickets, voucher: this.state.voucher, ...this.props }


            if (this.checkAll() && this.state.isChecked != 0) {
                axios.post(apiBaseUrl + 'makevoucherpayment', toSend)
                    .then(res_ => {
                        console.log(res_)

                        this.setState({showVoucherError: ""})
                        this.state.showVoucherError = ""
                        // console.log(res_, "res_")
                        this.setState({successCode: "Do check your mail for the Ticket, Thank you!"})
                        this.setState({showVoucherText: res_.data.message});

                        for (let i = 0; i < res_.data.length; i++) {
                            const ele = res_.data[i];
                            i == 0 ? window.location.href = ele.url : window.open(ele.url, '_blank');
                        }

                        console.log(res_.data.url, "res_.data.url")
                        // window.location.href = res_.data.url
                        swal("Ticket Purchase Successful!", res_.data.message, "success").then(() => {
                        });

                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                console.log("didnt go")
                this.setState({showVoucherText: ""});
                this.setState({showVoucherError: "All fields are required....Please Check that the table information are filled correctly. Then retry Voucher again."})
            }

        }

        const handleChange = (event) => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.state.voucher = value
            this.setState({[name]: value});
        }

        const house = () => [
            <input
                type="text" name="voucher_id" width="20px" id="voucher_id" className="voucherform-style"
                autoComplete="off" placeholder="Enter your voucher...." onChange={(e) => handleChange(e)}
            />, <button className="voucher-submitBtn btn btn-white-main" onClick={(e) => handleVoucherSubmit(e)}>Apply
                Voucher</button>
            , <span class="text-success">{this.state.showVoucherText}</span>
        ]


        return (
            house()
        )
    }

    onEnter = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    sumClassTypes(val) {

        let sum = 0;
        for (let total of Object.values(val)) {
            sum += +total;
        }

        return sum;
    }

    valuesAfterLopping() {
        const {event, classes, classOrders, history, match} = this.props;
        console.log(classOrders, 'classOrders')
        var ans = this.sumClassTypes(classOrders)
        this.state.ticketClassNum = ans
        console.log("totla", ans)
        let theValues = {};
        theValues.sTotal = 0;
        let mapForValues = classes && classes.map((dclass) => {
            let unitCost;
            let yo;
            if (dclass.price && classOrders[dclass._id]) {
                console.log('classOrders[dclass._id]: ', classOrders[dclass._id])

                this.disabledMessage = false
                this.tickets = classOrders[dclass._id]
                console.log(this.state.tickets, "tickets of the seat")
                yo = event
                unitCost = dclass.free ? 0 : classOrders[dclass._id] * Number(dclass.price);
                theValues.sTotal = Number(theValues.sTotal) + Number(unitCost);

                console.log('in if dclass.price, dclass.price: ', dclass.price);
                this.ticketprice = dclass.price
            } else {
                console.log('there is no dclass.price, dclass: ', classOrders[dclass._id])
            }
        })

        if (theValues.sTotal) {
            if ((1.4 / 100) * theValues.sTotal > 2000) {
                theValues.serviceCharge = 2000;
                console.log('in 1.4 / 100 theValues.sTotal, sTotal: ', theValues.sTotal)

                theValues.total = Number(theValues.sTotal + theValues.serviceCharge)
                theValues.yo = event
                console.log('in 1.4 / 100 theValues.Total, Total: ', theValues.total)
            } else {
                console.log('is there even theValues.sTotal in else :', theValues.sTotal);
                theValues.serviceCharge = (1.4 / 100) * theValues.sTotal;
                theValues.total = theValues.sTotal + theValues.serviceCharge;
                theValues.yo = event
            }

        }

        return theValues;
    }

    handleInputChange = (index, id, e) => {
        console.log("e", e)
        let {name, value} = e.target;

        console.log(`name: ${name}, value: ${value}`)
        this.setState({[name]: value});

        this.setState(({peopleByTicketType}) => {

            let people = peopleByTicketType[id] || []
            people[index] = people[index] || {}
            people[index][name] = value

            if (Object.keys(this.state.ticketSeats).length) {
                if (this.state.ticketSeats[id] && this.state.ticketSeats[id].status) {
                    people[index]["table"] = this.state.ticketSeats[id].data[index].table
                    people[index]["seat"] = this.state.ticketSeats[id].data[index].seat
                }
            }

            if (this.state.eventID == "5d6fd2df8ead0ea9278b4576") {

                people[index]["time"] = "10:00 AM";
            }


            let person = people[index] || {}

            return {
                peopleByTicketType: {...peopleByTicketType, [id]: people}
            }
        })


        if (name == 'venue') {
            let {selectedLocations} = this.state;
            if (!selectedLocations[index]) {
                selectedLocations[index] = {venue: null, date: null, time: null};
            }
            selectedLocations[index].venue = e.target.selectedIndex - 1;
            selectedLocations[index].dateVisible = true;
            console.log("In venue ----------------------------------------------------------------------------------------------", e.target.selectedIndex - 1)
            this.state.selectedLocation = selectedLocations;
            this.setState({selectedLocations: selectedLocations});
        }


        if (name == 'date_select') {
            let {selectedLocations} = this.state;
            if (!selectedLocations[index]) {
                selectedLocations[index] = {venue: null, date: null, time: null};
            }
            selectedLocations[index].date = e.target.selectedIndex - 1;

            this.setState({selectedLocations: selectedLocations});
        }


        if (name == 'time_select') {
            let {selectedLocations} = this.state;
            if (!selectedLocations[index]) {
                selectedLocations[index] = {venue: null, date: null, time: null};
            }
            selectedLocations[index].time = e.target.selectedIndex - 1;

            this.setState({selectedLocations: selectedLocations});
        }

    }
    handleInputChangeSingle = (e) => {
        let {name, value} = e.target;
        console.log(`name: ${name}, value: ${value}`)
        this.setState({[name]: value});

        if (name === 'phone') {
            console.log('--isValidPhoneNumber', isValidPhoneNumber(value))
            this.setState(({person}) => {

                return {
                    person: {...person, [name]: isValidPhoneNumber(value) && value}
                }
            })
        } else {
            this.setState(({person}) => {

                return {
                    person: {...person, [name]: value}
                }
            })
        }

        if (this.state.eventID == "5d6fd2df8ead0ea9278b4576") {
            this.setState({time: "10:00 AM"});
        }


        if (name == 'venue') {
            let {selectedLocation} = this.state;

            selectedLocation.venue = e.target.selectedIndex - 1;
            console.log("In venue ----------------------------------------------------------------------------------------------", this.state.selectedLocation)
            this.setState({selectedLocation: selectedLocation});
        }


        if (name == 'date_select') {
            let {selectedLocation} = this.state;

            selectedLocation.date = e.target.selectedIndex - 1;

            this.setState({selectedLocation: selectedLocation});
        }


        if (name == 'time_select') {
            let {selectedLocation} = this.state;

            selectedLocation.time = e.target.selectedIndex - 1;

            this.setState({selectedLocation: selectedLocation});
        }

    }

    handleRadioChange = (e) => {
        this.setState((prev) => {
            return {multiple: !prev.multiple}
        })
    }

    onPhoneNumberChange(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({value: e.target.value})
        }
    }

    getPhoneFormat(size) {
        if (size && !isNaN(size)) {
            let format = '';
            for (let i = 0; i < size; i++) {
                format += '#';
            }
            return format;
        }
        return '###########';
    }

    displaySection() {
        const {event, classes, classOrders} = this.props;
        const yo = event;

        let {multipleInsuraceList} = this.state;

        let classesWithQty = classes.map((item) => {
            return {...item, quantity: classOrders[item._id]}
        })

        let classesWithQtyNotZero = classesWithQty.filter((item) => item.quantity > 0)

        //console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX", classesWithQtyNotZero )

        let objStructure = classesWithQtyNotZero.map((item, ind) => {

            let theQty = parseInt(item.quantity)
            //let toReturn = new Array(parseInt(item.quantity)).map((item) => (
            let toReturn = [...Array(theQty).keys()].map((item2, index) => (
                <div className="description__info-section">
                    {
                        this.state.isInsuranceChecked ?
                            multipleInsuraceList.indexOf(item._id + index) < 0 ?
                                <p class="description__section-heading" style={{float: "right"}}>
                                    <button className="btn-success btn-icon" onClick={this.handleAddInsurance}
                                            data-target={item._id + index}>
                                        <i className="fas fa-plus-circle" data-target={item._id + index}></i> Add
                                        Insurance
                                    </button>
                                </p>
                                :
                                <p class="description__section-heading" style={{float: "right"}}>
                                    <button className="btn-danger btn-icon" onClick={this.handleRemoveInsurance}
                                            data-target={item._id + index}>
                                        <i className="fas fa-minus-circle" data-target={item._id + index}></i> Remove
                                        Insurance
                                    </button>
                                </p> :
                            null
                    }
                    <p class="description__section-heading">
                        {this.state.ticketSeats[item._id] &&
                            (this.state.ticketSeats[item._id].status ? `Table: ${this.state.ticketSeats[item._id].data[index].table + 1}, Seat: ${this.state.ticketSeats[item._id].data[index].seat + 1}` : "")}
                    </p>

                    <p class="description__section-heading">
                        {item.class}
                    </p>


                    <div className="row">
                        <div className="col-lg-6 mb-3">
                            <input onChange={this.handleInputChange.bind(null, index, item._id)}
                                   type="text"
                                   class="form-control"
                                   name="firstName"
                                   placeholder="First Name"
                            />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <input onChange={this.handleInputChange.bind(null, index, item._id)}
                                   type="text"
                                   class="form-control"
                                   name="lastName"
                                   placeholder="Last Name"
                            />
                        </div>

                        <div className="col-lg-6 mb-3">

                            <PhoneInput
                                className="form-control"
                                placeholder="Enter phone numbers"
                                defaultCountry={event.countryCode || 'NG'}
                                name="phone"
                                onChange={phone => {
                                    this.handleInputChangeSingle.call(this, { target: { name: 'phone', value: phone ? phone : ''} });
                                }} />

                            {/*<NumberFormat format={this.getPhoneFormat(event.phoneSize)} name="phone" mask="_" class="form-control"*/}
                            {/*              placeholder="Enter phone number"*/}
                            {/*              onChange={this.handleInputChange.bind(null, index, item._id)}/>*/}
                        </div>


                        <div className="col-lg-6 mb-3">
                            <input onChange={this.handleInputChange.bind(null, index, item._id)}
                                   type="text"
                                   class="form-control"
                                   name="email"
                                   placeholder="Email"
                            />
                        </div>

                        {
                            this.state.isInsuranceChecked && multipleInsuraceList.indexOf(item._id + index) > -1 ?
                                [<div className="col-lg-6 mb-3" key={0}>
                                    <input type="text" className="form-control" name="age" placeholder="Age" min="1"
                                           onChange={this.handleInputChange.bind(null, index, item._id)}/>
                                </div>,
                                    <div className="col-lg-6 mb-3" key={1}>
                                        <input type="text" className="form-control" name="nok_name"
                                               placeholder="Next of kin's name"
                                               onChange={this.handleInputChange.bind(null, index, item._id)}/>
                                    </div>,
                                    <div className="col-lg-6 mb-3" key={2}>
                                        <input type="text" className="form-control" name="nok_phone"
                                               placeholder="Next of kin's mobile number"
                                               onChange={this.handleInputChange.bind(null, index, item._id)}/>
                                    </div>]
                                : null
                        }


                    </div>

                    <div>
                        {this.myFunction(yo, true, index, item._id)}
                    </div>

                </div>
            ))

            return toReturn;
        })

        return objStructure;
    }

    render() {
        // const ext1 = (
        //   <input onChange={this.handleInputChangeSingle}
        //     type="text"
        //     class="form-control"
        //     name="phone"
        //     placeholder="Phone Number"
        //   />)
        // const ext2 = (
        //   <input onChange={this.handleInputChange.bind(null, index, item._id)}
        //     type="text"
        //     class="form-control"
        //     name="phone"
        //     placeholder="Phone Number"
        //   />)
        const {multiple} = this.state;
        const {event, classes, classOrders, match, history} = this.props;
        //let event = this.props.event
        //let classes = this.props.classes
        //const { name, venue, date } = event ? event[0] : {};
        var yo = event

        //console.log('yoooooooooo', yo)

        const {email, firstname, phone} = this.state;

        let valuesFromClasses = this.valuesAfterLopping();
        const {total, sTotal, serviceCharge} = valuesFromClasses
        //yo = event


        let theClassOrders = Object.keys(classOrders);
        console.log('theClassOrders: ', theClassOrders);
        // this.ticketsClasses = theClassOrders.length

        if (theClassOrders.length) {
            let noSelectedClass = theClassOrders.some(item => classOrders[item] !== 0);

            if (noSelectedClass === false) {
                let prev = match.url.split('/');
                let poped = prev.pop();
                let joinedPath = prev.join('/');

                history.push(joinedPath);
            }
            console.log('noSelectedClass: ', noSelectedClass)
        }


        let toReturn = classes && classes.map((dclass) => {
            if (!classOrders[dclass._id]) {
                return;
            }
            let unitCost = classOrders[dclass._id] * Number(this.state.vouchervalue ? this.state.vouchervalue : dclass.price);

            if (dclass.free) unitCost = 0;

            return (
                <div className="pricingCat">
                    <div className="row">
                        <div className="col-12 col-sm-9 ticket-type">
                            {classOrders[dclass._id]} x{' '}
                            {dclass.class}
                        </div>
                        <div className="col-6 col-sm-3 ticket-price">
                            {/* {yo[0].currency ? yo[0].currency : "₦"} */}
                            {/* ₦ */}
                            {Number(unitCost || 0).toLocaleString(`en-${(event.currency || 'NGN').substring(0, 2)}`, {
                                style: 'currency',
                                currency: (event.currency || 'NGN'),
                                maximumFractionDigits: 2
                            })}
                        </div>
                    </div>
                </div>
            );
        })


        let singleHtml = (
            <div className="description__info-section">

                <p class="description__section-heading">
                    {Object.keys(this.state.ticketSeats).length && "Ticket information will be sent to you"}
                </p>

                <p class="description__section-heading">
                    Buyer Information
                </p>
                {/* <p>
          Checkout as a guest or{" "}
          <a href="#/signin">Sign in with your account</a>
        </p> */}
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <input onChange={this.handleInputChangeSingle}
                               type="text"
                               class="form-control"
                               name="firstName"
                               placeholder="First Name"
                        />
                    </div>
                    <div className="col-lg-6 mb-3">
                        <input onChange={this.handleInputChangeSingle}
                               type="text"
                               class="form-control"
                               name="lastName"
                               placeholder="Last Name"
                        />
                    </div>
                    <div className="col-lg-6 mb-3">

                        <PhoneInput
                            className="form-control"
                          placeholder="Enter phone number"
                            defaultCountry={event.countryCode || 'NG'}
                          name="phone"
                          onChange={phone => {
                              this.handleInputChangeSingle.call(this, { target: { name: 'phone', value: phone ? phone : ''} });
                          }} />

                        {/*<NumberFormat format={this.getPhoneFormat(event.phoneSize)} name="phone" mask="_" class="form-control"*/}
                        {/*              placeholder="Enter phone number" onChange={this.handleInputChangeSingle}/>*/}

                    </div>
                    <div className="col-lg-6 mb-3">
                        <input onChange={this.handleInputChangeSingle}
                               type="email"
                               class="form-control"
                               name="email"
                               placeholder="Email"
                        />
                    </div>

                    {
                        this.state.isInsuranceChecked ?
                            [<div className="col-lg-6 mb-3" key={0}>
                                <input type="text" className="form-control" name="age" placeholder="Age" min="1"
                                       onChange={this.handleInputChangeSingle}/>
                            </div>,
                                <div className="col-lg-6 mb-3" key={1}>
                                    <input type="text" className="form-control" name="nok_name"
                                           placeholder="Next of kin's name" onChange={this.handleInputChangeSingle}/>
                                </div>,
                                <div className="col-lg-6 mb-3" key={2}>
                                    <input type="text" className="form-control" name="nok_phone"
                                           placeholder="Next of kin's mobile number"
                                           onChange={this.handleInputChangeSingle}/>
                                </div>]
                            : null
                    }


                </div>

                <div>
                    {this.myFunction(yo, false, null, null)}
                </div>


            </div>

        )

        return (

            <div>

                <div className="" style={{marginTop: "2rem"}}>
                    <div className=" row">
                        <div className="col-lg-7 col-sm-push-5 buyticket-description">
                            <div className="description__ticket-select">
                                <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                        <input onChange={this.handleRadioChange}
                                               type="radio"
                                               name="multiple"
                                               checked={!!multiple === false}
                                               id="single_recipient"
                                               value={false}
                                        />
                                        <label htmlFor="single_recipient">Send to One Email</label>
                                    </div>

                                    <div className="col-md-6 col-xs-12">
                                        <input onChange={this.handleRadioChange}
                                               type="radio"
                                               name="multiple"
                                               checked={!!multiple === true}
                                               id="multiple_recipient"
                                               value={true}
                                        />
                                        <label htmlFor="multiple_recipient">
                                            Send to multiple Emails
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div style={{color: 'red'}}>* Please ensure all forms are filled</div>

                            {
                                multiple ? this.displaySection() : singleHtml
                            }
                        </div>


                        <div className="col-lg-5 ">
                            <div className="event-pricing">
                                <h4>Ticket Order</h4>
                                <div className="priceCategory">
                                    {toReturn}
                                    <div className="pricingCat">
                                        <div className="row">
                                            <div className="col-12 col-sm-9 ticket-type"> Sub Total</div>
                                            <div className="col-6 col-sm-3 ticket-price">
                                                {Number(this.state.vouchervalue || sTotal).toLocaleString(`en-${(event.currency || 'NGN').substring(0, 2)}`, {
                                                    style: 'currency',
                                                    currency: (event.currency || 'NGN'),
                                                    maximumFractionDigits: 2
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="pricingCat bottom-line">*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-12 col-sm-9 ticket-type">*/}
                                    {/*            Service Charge*/}
                                    {/*            <small id="passwordHelpBlock" className="form-text text-muted">(Online*/}
                                    {/*                Payment Convenience Fee)</small>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-6 col-sm-3 ticket-price">*/}
                                    {/*            {Number(serviceCharge || 0).toLocaleString(`en-${(event.currency || 'NGN').substring(0, 2)}`, {*/}
                                    {/*                style: 'currency',*/}
                                    {/*                currency: (event.currency || 'NGN'),*/}
                                    {/*                maximumFractionDigits: 2*/}
                                    {/*            })}*/}

                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {
                                        this.state.isInsuranceChecked ?
                                            <div className="pricingCat bottom-line">
                                                <div className="row">
                                                    <div className="col-12 col-sm-9 ticket-type">
                                                        {this.state.numOfInsuree} x Personal accident insurance
                                                        <small className="form-text text-muted">(&#8358;500 per persons
                                                            / event)</small>
                                                    </div>
                                                    <div
                                                        className="col-6 col-sm-3 ticket-price">&#8358;{this.state.numOfInsuree * 500}</div>
                                                </div>
                                            </div>
                                            : null
                                    }

                                    <div className="pricingCat">
                                        <div className="row total-price">
                                            <div className="col-12 col-sm-9">Total</div>
                                            <div className="col-6 col-sm-3">
                                                {Number(
                                                    (this.state.vouchervalue && (this.state.vouchervalue + (this.state.isInsuranceChecked ? 500 * this.state.numOfInsuree : 0))) ||
                                                    (sTotal && (sTotal + (this.state.isInsuranceChecked ? 500 * this.state.numOfInsuree : 0))) || 0
                                                ).toLocaleString(`en-${(event.currency || 'NGN').substring(0, 2)}`, {
                                                    style: 'currency',
                                                    currency: (event.currency || 'NGN'),
                                                    maximumFractionDigits: 2
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {this.voucher()}
                                    </div>
                                    <div>{this.state.showVoucherError ? this.showVoucherTestErr() : (this.state.vouchervalue ? this.pay() : this.state.showVoucherText ?
                                        <span class="text-success">{this.state.successCode}</span> : this.pay())}</div>
                                    <div class="form-item">
                                        {event.forAdult && <label>
                                            <p className="mt-4 ticketTerm" style={{fontSize: 15}}>
                                                <input type="checkbox" name="checked"
                                                       checked={this.state.isAdultChecked}
                                                       onChange={this.handleAdult}/>
                                                &nbsp;&nbsp; I agree that I am an adult (18+), I am old enough to
                                                purchase this ticket.
                                            </p>
                                        </label>}

                                        <label>
                                            <p className="mt-3 ticketTerm" style={{fontSize: 15}}>
                                                <input type="checkbox" name="checked" checked={this.state.isChecked}
                                                       onChange={this.handleCheck}/>
                                                &nbsp;&nbsp; I accept the terms and conditions for using this service,
                                                and hereby
                                                confirm I that i am <span style={{ fontWeight: 'bold'}}>above 18 years</span> of age and have read the
                                                <Link to={`/termsConditions`}> privacy policy.</Link>
                                            </p>
                                        </label>


                                        {(this.state.terms && this.state.terms.length > 7) && (<label>
                                            <p className="mt-4 ticketTerm2" style={{fontSize: 15}}>
                                                <input type="checkbox" id="terms" name="terms"
                                                       value={this.state.accceptEventTerms} onChange={(e) => {
                                                    this.setState({accceptEventTerms: e.target.checked}, () => {
                                                        console.log("UUUUUU######UUUUUU", this.state.accceptEventTerms)
                                                    });
                                                }}/>
                                                &nbsp;&nbsp; I accept the terms and conditions for this event, and
                                                hereby
                                                confirm I have read the
                                                <a href="#" onClick={(e) => {
                                                    this.setState({toggle_terms: true})
                                                }}>Terms & Condition</a>
                                                <Modal isOpen={this.state.toggle_terms}
                                                       toggle={this.state.toggle_terms2}>
                                                    <ModalHeader toggle={this.state.toggle_terms2}>Event Terms &
                                                        Condition</ModalHeader>
                                                    <ModalBody>
                                                        <div dangerouslySetInnerHTML={{__html: this.state.terms}}></div>
                                                        <br/>
                                                        <div style={{display: "block", width: "100%"}}>
                                                            <button
                                                                style={{backgroundColor: "red", marginRight: "20px"}}
                                                                onClick={() => {
                                                                    this.setState({toggle_terms: false})
                                                                }} class="payButton btn ">Cancel
                                                            </button>
                                                            <button onClick={() => {
                                                                this.setState({
                                                                    accceptEventTerms: true,
                                                                    toggle_terms: false
                                                                });
                                                                document.getElementById("terms").checked = true;
                                                            }} class="payButton btn btn-white-main">Accept
                                                            </button>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                    </ModalFooter>
                                                </Modal>
                                            </p>
                                        </label>)}

                                        <label>
                                            <p className="mt-2 ticketTerm" style={{fontSize: 15}}>
                                                <input type="checkbox" name="insurance"
                                                       checked={this.state.isInsuranceChecked}
                                                       onChange={this.handleInsuranceCheck}/>
                                                &nbsp;&nbsp; Include Personal Accident Insurance for Events
                                                (OPTIONAL) <Link to={'/paic/learn-more'}>Learn more.</Link>
                                            </p>
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    oneEvent: state.oneEvent,
});

const mapDispatchToProps = (dispatch) => ({
    onGetOneEvent: bindActionCreators(onGetOneEvent, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyTicketNew));
