import axios from 'axios';
import env from '../../env.js';

const getCategories = (url) => ({
    type: 'GET_CATEGORIES',
    payload: axios(url)
})

export const onGetCategories = () => async (dispatch) => {
    const url = `${env(true)}events/categories`
    dispatch(getCategories(url))
}