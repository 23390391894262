import axios from 'axios';
import env from '../../env.js';

const getPendingEvents = (url) => ({
  type:'GET_PENDING_EVENTS',
  payload:axios(url)
})

export const onGetPendingEvents = (id) => async (dispatch) => {
  const url=`${env(true)}merchant/pending-events/${id}`
  dispatch(getPendingEvents(url))
}