import React, {Component} from 'react';
import '../../styles/eventmanager.css';
import Header from '../header';
import Footer from '../footer';
import EventSideMenu from '../eventManager/sideMenu';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import '../../styles/eventMobileMenu.css';

class EventReport extends Component {
  render () {
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div>
       {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        <div className="row eventmanager-row">
          <div className="col-sm-3 ">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu" className="list-group-item active" data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2 show" id="sub-menu">
                    <Link to="/eventover" href="#" className="list-group-item " data-parent="#sub-menu">Overview</Link>
                    <Link to="/eventreport" href="#" className="list-group-item s__active" data-parent="#sub-menu">Report</Link>
                    <Link to="/eventorders" href="#" className="list-group-item" data-parent="#sub-menu">Manage Attendance</Link>
                </div>
              
                <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item ">Edit Information<span className="caret"></span></a>
                  <div className="collapse list-group-level" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div>
                <Link to="/eventquestions" href="#" className="list-group-item">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item" >Promote Event <span className="caret"></span></Link>
                  <Link to="/eventsettings" href="#" className="list-group-item">Additional Settings</Link>
                
            </div>
            </div>
          </div>
          <div className="col-sm-9 event-attendees-container">
            <h2>Reports</h2>
            <p>
              Download reports to get advanced data on your attendees and your event finances.
            </p>

            <div className="event-attendees">
              <p className="event-attendees__text">Choose your date:</p>

              <form className="event-report__form">
                <div class="form-group">
                  <select class="custom-select" id="inputGroupSelect01">
                    <option selected>All events</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>

                </div>
              </form>
            </div>
            <div className="event-report">
            <div className="event-report__image">
              <img className="" src="/image/analytics.png" alt=""/>
              </div>
              <p className="event-report__text">Once you have sold tickets to your event, a table would appear here.</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (EventReport);