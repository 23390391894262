import React, { Component } from 'react';
import '../styles/faq.css';
import Header from '../components/header';
import Footer from '../components/footer';
import NumberFormat from 'react-number-format';

export default class UtilityReg extends Component {
	componentDidMount = () => {
		var acc = document.getElementsByClassName('accordion');
		var i;

    };
    handleVoucher = () => {
        alert("hit")
    }
    handleUserEntry = () => {
        alert("entry")
    }
    form = () => {
        return (            
            <div className="row">
                <div className="col-lg-6 mb-3">
                    <input
                        type="text"
                        class="form-control"
                        name="firstName"
                        placeholder="First Name"
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <input
                        type="text"
                        class="form-control"
                        name="lastName"
                        placeholder="Last Name"
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <NumberFormat format="###########" name="phone" mask="_" class="form-control" placeholder="Enter phone number" />
                </div>
                <div className="col-lg-6 mb-3">
                    <input
                        type="text"
                        class="form-control"
                        name="email"
                        placeholder="Email"
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <NumberFormat format="##" name="age" mask="_" class="form-control" placeholder="Enter Age" />
                </div>
                <div className="col-lg-6 mb-3">
                    <input
                        type="file"
                        class="form-control"
                        name="email"
                        placeholder="Email"
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <input
                        type="text"
                        class="form-control"
                        name="voucher"
                        placeholder="Enter Your Voucher here"
                    />
                </div>
                <div className="col-lg-6 mb-3">
                    <a href="#" onClick={(e) => this.handleVoucher()}>Apply Voucher.</a>
                </div>
                <div className="col-lg-6 mb-3">
                    <button onClick={(e) => this.handleUserEntry()} className="payButton btn btn-white-main">
                        Get Ticket
                    </button>
                </div>
                
            </div>
        )
    }
	render() {
		return (
			<div>
				<Header />
				<div className="background">
					<div className="layer">
						<div className="faq-background-text">
							<h1>Welcome, most special visitors</h1>
							<p>Fill the form below and enter your voucher to get your ticket.</p>
						</div>
					</div>
					<div className="faq-background-image" />
				</div>
				<div className="container-fluid">
					<div className="accordionBody">
                        <div className="col 12 col-sm-8 m-auto">
                            {this.form()}
                        </div>
                    </div>
				</div>

				<div className="faq-cant-find faq-background-text">
					<h1>Having any issues with the registration?</h1>
					<p>
						Email your query to <a href="mailto:support@nairabox.com">support@nairabox.com</a>
					</p>
				</div>
				<Footer />
			</div>
		);
	}
}
