import React, {Component} from 'react';
import $ from 'jquery';
import '../styles/contactus.css';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/formtype.css';
import axios from "axios";
import env from '../env';

export default class FAQ extends Component {
  componentDidMount = async () => {
    const { id } = this.props.match.params;

    try{
        let res = await axios.get(`${env(true)}short-link/${id}` );
        let eventId = res.data.id;
        this.props.history.push(`/event/${eventId}`) 
    }catch(e){
        console.log(e)
        this.props.history.push(`/`) 
    }
    }
  render () {
    return (
      <div>
        <Header/>
        <div className="background mb-5" >
					<div className="layer">
						<div className="faq-background-text">
							<h1>Welcome to Nairabox</h1>
							<p>Redirecting You ...</p>
						</div>
					</div>
					<div className="contact-background-image" />
				</div>
         <Footer/>
      </div>
    );
  }
}
