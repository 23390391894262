import React, { Component } from "react";
import '../../styles/myevent.css';
import Header from '../header';
import LoggedInHeader from "../loggedInHeader";
import Footer from "../footer";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import {onGetPendingEvents} from "../../store/actions/getPendingEvents";
import {onGetLiveEvents} from '../../store/actions/getLiveEvents' 
import {onGetDraftEvents} from '../../store/actions/getEventsDrafts';
import {onGetExpiredEvents} from '../../store/actions/getExpiredEvents';
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";

class EventList extends Component {
   
  componentDidMount(){
    const { merchantID } = this.props.match.params;
    this.props.onGetLiveEvents(merchantID);
    this.props.onGetPendingEvents(merchantID);
    this.props.onGetDraftEvents(merchantID);
    this.props.onGetExpiredEvents(merchantID)
  }
  render() {
    const {login:{isLogged}, register:{isLoggedIn}, liveEvents:{response: liveEventsResponse},pendingEvents:{response: pendingEventsResponse} }=this.props;
    const uid = localStorage.getItem('uid')
     const {draftEvents:{response: draftEventsResponse}, expiredEvents:{response: expiredeventsResponse}} = this.props;
    console.log(expiredeventsResponse)
    const expired =  expiredeventsResponse.map((value,key)=>
                        
    <div className=" p-2 mt-3 b-radius my-events-d">
      <div className="row">
        <div className="col-sm-2">
          <img
            src="/image/event-bg.png"
            alt=""
            sizes=""
            srcset=""
          />
        </div>
        <div className="col-sm-7 ">
          <h5>{value.name}</h5>
          <p>
            <img
              src="/image/loca_black.png"
              alt="Event location in lagos"
            />{" "}
            {value.venue}
          </p>
          <p id="calender-d">
            <img
              src="/image/cal_black.png"
              alt="Event location in lagos"
            />{" "}
            {/* Sat, Sep 29 10:00 AM-1:00 PM */}
            {value.date}
          </p>
        </div>
        <div className="col-sm-3 numberTickets m-auto">
          <Link to={(`/eventoverview/${value._id}`)} className="btn btn-white-main">
            Manage
          </Link>
        </div>
      </div>
    </div>
    
)
    const draft =  draftEventsResponse.map((value,key)=>
                        
      <div className=" p-2 mt-3 b-radius my-events-d">
        <div className="row">
          <div className="col-sm-2">
            <img
              src="/image/event-bg.png"
              alt=""
              sizes=""
              srcset=""
            />
          </div>
          <div className="col-sm-7 ">
            <h5>{value.name}</h5>
            <p>
              <img
                src="/image/loca_black.png"
                alt="Event location in lagos"
              />{" "}
              {value.venue}
            </p>
            <p id="calender-d">
              <img
                src="/image/cal_black.png"
                alt="Event location in lagos"
              />{" "}
              {/* Sat, Sep 29 10:00 AM-1:00 PM */}
              {value.date}
            </p>
          </div>
          <div className="col-sm-3 numberTickets m-auto">
            <Link to={(`/eventoverview/${value._id}`)} className="btn btn-white-main">
              Manage
            </Link>
          </div>
        </div>
      </div>
      
)
    const data =  liveEventsResponse.map((value,key)=>
                        
      <div className=" p-2 mt-3 b-radius my-events-d">
        <div className="row">
          <div className="col-sm-2">
            <img
              src="/image/event-bg.png"
              alt=""
              sizes=""
              srcset=""
            />
          </div>
          <div className="col-sm-7 ">
            <h5>{value.name}</h5>
            <p>
              <img
                src="/image/loca_black.png"
                alt="Event location in lagos"
              />{" "}
              {value.venue}
            </p>
            <p id="calender-d">
              <img
                src="/image/cal_black.png"
                alt="Event location in lagos"
              />{" "}
              {/* Sat, Sep 29 10:00 AM-1:00 PM */}
              {value.date}
            </p>
          </div>
          <div className="col-sm-3 numberTickets m-auto">
            <Link to={(`/eventoverview/${value._id}`)} className="btn btn-white-main">
              Manage
            </Link>
          </div>
        </div>
      </div>
      
)

const pending =  pendingEventsResponse.map((value,key)=>
                        
      <div className=" p-2 mt-3 b-radius my-events-d">
        <div className="row">
          <div className="col-sm-2">
            <img
              src="/image/event-bg.png"
              alt=""
              sizes=""
              srcset=""
            />
          </div>
          <div className="col-sm-7 ">
            <h5>{value.name}</h5>
            <p>
              <img
                src="/image/loca_black.png"
                alt="Event location in lagos"
              />{" "}
              {value.venue}
            </p>
            <p id="calender-d">
              <img
                src="/image/cal_black.png"
                alt="Event location in lagos"
              />{" "}
              {/* Sat, Sep 29 10:00 AM-1:00 PM */}
              {value.date}
            </p>
          </div>
          <div className="col-sm-3 numberTickets m-auto">
            <Link to={(`/eventoverview/${value._id}`)} className="btn btn-white-main">
              Manage
            </Link>
          </div>
        </div>
      </div>
      
)
   // console.log(liveEventsResponse, pendingEventsResponse, draftEventsResponse)
    return (
      <div>
        {(isLogged || isLoggedIn) || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        <div className="container-fluid navlink">
          <nav className="nav">
          <Link  to={(`/eventdashboard/${uid}`)} className="nav-link" href="#">Dashboard</Link>
            <Link to={(`/eventlist/${uid}`)} className="nav-link active " href="#">
              My Events
            </Link>
            {/* <Link to="/eventticket" className="nav-link" href="#">
              My Tickets
            </Link> */}
          </nav>
        </div>
        <div className="container-fluid Dashboardbody">
          <div className="marginless">
            <h4 className="event-description ">My Events</h4>

            <nav className="myEvents">
              <div
                className="nav event-tab  nav-tabs"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className="nav-item nav-link active"
                  id="live-tab"
                  data-toggle="tab"
                  href="#live"
                  role="tab"
                  aria-controls="nav-live"
                  aria-selected="true"
                >
                  Live Events
                </a>
                <a
                  className="nav-item nav-link"
                  id="pending-tab"
                  data-toggle="tab"
                  href="#pending"
                  role="tab"
                  aria-controls="nav-pending"
                  aria-selected="false"
                >
                  Pending Events
                </a>
                <a
                  className="nav-item nav-link"
                  id="draft-tab"
                  data-toggle="tab"
                  href="#ddraft"
                  role="tab"
                  aria-controls="nav-draft"
                  aria-selected="false"
                >
                  Drafts
                </a>
                <a
                  className="nav-item nav-link"
                  id="expired-tab"
                  data-toggle="tab"
                  href="#expired"
                  role="tab"
                  aria-controls="nav-expired"
                  aria-selected="false"
                >
                  Expired
                </a>
                <form className="form-inline">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search"
                    aria-label="search all event by name"
                  />
                </form>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="live"
                role="tabpanel"
                aria-labelledby="live-tab"
              >
                <div className="row">
                  <div className="col-sm-8 ">
                    <div className="dashboardCard d__viewport">
                      <div className="p-3">
                        
                      { liveEventsResponse ? data : <div className=" p-2 mt-3 b-radius my-events-d">
                      <div className="row">
                      <div className="col-sm-2">No events </div>
                      </div>
                      </div> }                

                    

                      </div>

                    </div>
                    
                  </div>
                  

                  
                  

                  


                  <div className="col-sm-4 ">
                    <div className="dashboardCard quicklinks ">
                      <div className="p-4 ">
                        <h5 className="pt-2 ">Recently Updated Event</h5>
                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pending"
                role="tabpanel"
                aria-labelledby="pending-tab"
              >
                <div className="row">
                  <div className="col-sm-8 ">
                    <div className="dashboardCard d__viewport">
                      <div className="p-3">
                        
                      { pendingEventsResponse ? pending : <div className=" p-2 mt-3 b-radius my-events-d">
                      <div className="row">
                      <div className="col-sm-2">No events </div>
                      </div>
                      </div> }                

                    

                      </div>

                    </div>
                    
                  </div>
                  

                  
                  

                  


                  <div className="col-sm-4 ">
                    <div className="dashboardCard quicklinks ">
                      <div className="p-4 ">
                        <h5 className="pt-2 ">Recently Updated Event</h5>
                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
              <div
                className="tab-pane fade"
                id="ddraft"
                role="tabpanel"
                aria-labelledby="draft-tab"
              >
                <div className="row">
                  <div className="col-sm-8 ">
                    <div className="dashboardCard d__viewport">
                      <div className="p-3">
                        
                      { liveEventsResponse ? draft : <div className=" p-2 mt-3 b-radius my-events-d">
                      <div className="row">
                      <div className="col-sm-2">No events </div>
                      </div>
                      </div> }                

                    

                      </div>

                    </div>
                    
                  </div>
                  

                  
                  

                  


                  <div className="col-sm-4 ">
                    <div className="dashboardCard quicklinks ">
                      <div className="p-4 ">
                        <h5 className="pt-2 ">Recently Updated Event</h5>
                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
              <div
                className="tab-pane fade"
                id="expired"
                role="tabpanel"
                aria-labelledby="expired-tab"
              >
                <div className="row">
                  <div className="col-sm-8 ">
                    <div className="dashboardCard d__viewport">
                      <div className="p-3">
                        
                      { liveEventsResponse ? expired : <div className=" p-2 mt-3 b-radius my-events-d">
                      <div className="row">
                      <div className="col-sm-2">No events </div>
                      </div>
                      </div> }                

                    

                      </div>

                    </div>
                    
                  </div>
                  

                  
                  

                  


                  <div className="col-sm-4 ">
                    <div className="dashboardCard quicklinks ">
                      <div className="p-4 ">
                        <h5 className="pt-2 ">Recently Updated Event</h5>
                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li className="media">
                            <img
                              className="mr-3"
                              src="/image/event-bg.png"
                              alt="event in lagos"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 mb-1">None</h5>
                              {/* Last updated Sep 29 */}
                              <p id="draft">Draft</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
            
          </div>
        </div>
        <div className="event-list__footer">
        <Footer/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  //events: state.events,
  login:state.login, 
  register:state.register, 
  pendingEvents: state.pendingEvents,
  liveEvents: state.liveEvents,
  draftEvents: state.draftEvents,
  expiredEvents: state.expiredEvents
});

const mapDispatchToProps = (dispatch) => ({
onGetPendingEvents: bindActionCreators (onGetPendingEvents, dispatch),
onGetLiveEvents: bindActionCreators (onGetLiveEvents, dispatch),
onGetDraftEvents: bindActionCreators(onGetDraftEvents, dispatch),
onGetExpiredEvents: bindActionCreators(onGetExpiredEvents, dispatch)
});

export default withRouter(connect (mapStateToProps, mapDispatchToProps) (EventList));
