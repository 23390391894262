import React, {Component} from 'react';
import '../../styles/myevent.css';
//import '../../App.css';
//import '../../styles/responsive.css';
//import "../../styles/ap.css";
import Header from '../header';
import Footer from '../footer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import {onGetEventWeekly} from '../../store/actions/getWeekly';
import {onGetEventMonthly} from '../../store/actions/getMonthly';
import {onGetEventDaily} from '../../store/actions/getDaily'
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";

class EventDashboard extends Component {
  componentDidMount() {
    const { merchantID } = this.props.match.params;

    this.props.onGetEventWeekly(merchantID)
    this.props.onGetEventMonthly(merchantID)
    this.props.onGetEventDaily(merchantID)
  }

  formatCurrency(labelValue) {

    // Nine Zeroes for Trillion
    return Math.abs(Number(labelValue)) >= 1.0e+18

    ? Math.abs(Number(labelValue)) / 1.0e+18 + "G"
    // Nine Zeroes for Billions
    : Math.abs(Number(labelValue)) >= 1.0e+15

    ? Math.abs(Number(labelValue)) / 1.0e+15 + "Z"
    // Nine Zeroes for Billions
    : Math.abs(Number(labelValue)) >= 1.0e+12

    ? Math.abs(Number(labelValue)) / 1.0e+12 + "T"
    // Nine Zeroes for Billions
    : Math.abs(Number(labelValue)) >= 1.0e+9

    ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    : Math.abs(Number(labelValue));

  }
  render () {
   const {login:{isLogged}, register:{isLoggedIn}, weeklyEvent:{response: weeklyEventResponse}, monthlyEvent:{response: monthlyEventResponse}, dailyEvent:{response: dailyEventResponse}}=this.props;
  //  const {login:{isLogged}, register:{isLoggedIn}, oneEvent}=this.props;
  const uid = localStorage.getItem('uid')
  console.log(this.props)
    return (
      <div>
      <div>
{(isLogged || isLoggedIn) ||  <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
      </div>
      
        <div className="container-fluid navlink">
          <nav className="nav">
          <Link  to={(`/eventdashboard/${uid}`)} className="nav-link" href="#">Dashboard</Link>
            {/* <Link to="/eventdashboard" className="nav-link active" href="#">Dashboard</Link> */}
            <Link to={(`/eventlist/${uid}`)} className="nav-link " href="#">My Events</Link>
            {/* <Link to={(`/eventticket/${uid}`)} className="nav-link " href="#">My Tickets</Link> */}
          </nav>

        </div>
        <div className="container-fluid Dashboardbody">
          <div className="marginless">
            <h4 className="event-description ">Account Dashboard</h4>
            <nav>
              <div
                className="nav event-tab  nav-tabs"
                id="nav-tab"
                role="tablist"
              >
               <a
                  className="nav-item nav-link active"
                  id="today-tab"
                  data-toggle="tab"
                  href="#today"
                  role="tab"
                 aria-controls="nav-seven-days"
                  aria-selected="true"
                >
                  Today
                </a>
                <a
                  className="nav-item nav-link"
                  id="seven-days-tab"
                  data-toggle="tab"
                  href="#seven-days"
                  role="tab"
                  aria-controls="nav-seven-days"
                  aria-selected="false"
                >
                  Weekly
                </a>
                <a
                  className="nav-item nav-link"
                  id="thirty-days-tab"
                  data-toggle="tab"
                  href="#thirty-days"
                  role="tab"
                  aria-controls="nav-seven-days"
                  aria-selected="false"
                >
                  Monthly
                </a>

              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
            <div
                className="tab-pane fade show active"
                id="today"
                role="tabpanel"
                aria-labelledby="today-tab"
              >
                <div className="row">
                  <div className=" col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Revenue</h5>
                        <h5> ₦ {dailyEventResponse[0] ? new Intl.NumberFormat('en-GB', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
}).format(dailyEventResponse[0].total_sales) : 0  } </h5>
                      </div>

                    </div>
                  </div>

                  <div className=" col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Ticket Sold</h5>
                        <h5>  {dailyEventResponse[0] ? (dailyEventResponse[0].tickets_sold) : 0  } </h5>
                        {/* <h5> {weeklyEventResponse[0].tickets_sold} </h5> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Number Of Transactions</h5>
                        <h5>  {dailyEventResponse[0] ? (dailyEventResponse[0].number_of_transactions) : 0  } </h5>
                        {/* <h5>{weeklyEventResponse[0].number_of_transactions}</h5> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-8 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Sales Report</h5>
                        <div className="salesReport">
                          <div className=" col-6 m-auto">
                            <img
                              src=" /image/analytics.png "
                              alt="Events in Nigeria "
                            />
                            <p>
                              Once you've sold tickets to your event, a table will be displayed here.
                            </p>
                            <Link to="/createEvent" className="btn btn-primary btn-white-main" href="#">
                            Create Event
                            </Link>
                            
                             
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="dashboardCard quicklinks ">
                      <div className="p-4 ">
                        <h5 className="pt-2 ">Quick Links</h5>
                        <nav className="nav flex-column ">
                        <Link   to={(`/eventlist/${uid}`)} className="nav-link " href="# ">My Events</Link>
                          {/* <Link  to={(`/eventticket/${uid}`)} className="nav-link " href="# ">My Tickets</Link>
                          <Link  to={(`/account/${uid}`)}  className="nav-link " href="# ">Account Settings</Link> */}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              <div
                className="tab-pane fade"
                id="seven-days"
                role="tabpanel"
                aria-labelledby="seven-days-tab"
              >
                <div className="row">
                  <div className=" col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Revenue</h5>
                        <h5> ₦ {weeklyEventResponse[0] ? new Intl.NumberFormat('en-GB', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
}).format(weeklyEventResponse[0].revenue) : 0  } </h5>
                      </div>

                    </div>
                  </div>

                  <div className=" col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Ticket Sold</h5>
                        <h5>  {weeklyEventResponse[0] ? (weeklyEventResponse[0].tickets_sold) : 0  } </h5>
                        {/* <h5> {weeklyEventResponse[0].tickets_sold} </h5> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Number Of Transactions</h5>
                        <h5>  {weeklyEventResponse[0] ? (weeklyEventResponse[0].number_of_transactions) : 0  } </h5>
                        {/* <h5>{weeklyEventResponse[0].number_of_transactions}</h5> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-8 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Sales Report</h5>
                        <div className="salesReport">
                          <div className=" col-6 m-auto">
                            <img
                              src=" /image/analytics.png "
                              alt="Events in Nigeria "
                            />
                            <p>
                              Once you've sold tickets to your event, a table will be displayed here.
                            </p>
                            <Link to="/createEvent" className="btn btn-primary btn-white-main" href="#">
                            Create Event
                            </Link>
                            
                             
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="dashboardCard quicklinks ">
                      <div className="p-4 ">
                        <h5 className="pt-2 ">Quick Links</h5>
                        <nav className="nav flex-column ">
                        <Link   to={(`/eventlist/${uid}`)} className="nav-link " href="# ">My Events</Link>
                          {/* <Link  to={(`/eventticket/${uid}`)} className="nav-link " href="# ">My Tickets</Link>
                          <Link  to={(`/account/${uid}`)}  className="nav-link " href="# ">Account Settings</Link> */}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div
                className="tab-pane fade"
                id="thirty-days"
                role="tabpanel"
                aria-labelledby="thirty-days-tab"
              >
                <div className="row">
                  <div className=" col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Revenue</h5>
                        <h5> ₦ {(monthlyEventResponse[0]) ? new Intl.NumberFormat('en-GB', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
}).format(monthlyEventResponse[0].revenue) : 0  } </h5>
                        {/* <h5> ₦ {monthlyEventResponse[0].revenue} </h5> */}
                      </div>

                    </div>
                  </div>

                  <div className=" col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Ticket Sold</h5>
                        <h5> {monthlyEventResponse[0]  ? monthlyEventResponse[0].tickets_sold : 0  } </h5>
                        {/* <h5> {monthlyEventResponse[0].tickets_sold} </h5> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Number Of Transactions</h5>
                        <h5> {monthlyEventResponse[0]  ? monthlyEventResponse[0].number_of_transactions : 0  } </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-8 ">
                    <div className="dashboardCard">
                      <div className="p-4">
                        <h5 className="pt-2">Sales Report</h5>
                        <div className="salesReport">
                          <div className=" col-6 m-auto">
                            <img
                              src=" /image/analytics.png "
                              alt="Events in Nigeria "
                            />
                            <p>
                              Once you've sold tickets to your event, a table will be displayed here.
                            </p>
                            <Link to="/createEvent" className="btn btn-primary btn-white-main" href="#">
                            Create Event
                            </Link>
                            
                             
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="dashboardCard quicklinks ">
                      <div className="p-4 ">
                        <h5 className="pt-2 ">Quick Links</h5>
                        <nav className="nav flex-column ">
                        <Link   to={(`/eventlist/${uid}`)} className="nav-link " href="# ">My Events</Link>
                          {/* <Link  to={(`/eventticket/${uid}`)} className="nav-link " href="# ">My Tickets</Link>
                          <Link  to={(`/account/${uid}`)}  className="nav-link " href="# ">Account Settings</Link> */}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}

            </div>

          </div>

        </div>
        <div className="event-dashboard__footer">
        <Footer/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  weeklyEvent: state.weeklyEvent,
  monthlyEvent: state.monthlyEvent,
  dailyEvent: state.dailyEvent,
  login:state.login, 
    register:state.register, 
});

const mapDispatchToProps = (dispatch) => ({
  onGetEventWeekly: bindActionCreators (onGetEventWeekly, dispatch),
  onGetEventMonthly: bindActionCreators (onGetEventMonthly, dispatch),
  onGetEventDaily: bindActionCreators(onGetEventDaily, dispatch)
  });

export default connect (mapStateToProps, mapDispatchToProps) (EventDashboard);
