import React, {Component} from 'react';
// import '../../styles/basicsettings.css';
import '../../styles/email.css';
import SideMenu from './sidemenu';
import Header from '../../components/loggedInHeader';
import Footer from '../../components/footer';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';


class BasicEmail extends Component {
  render () {
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div className="acctSettings"> 
       <div>
        {isLogged || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        </div>
        <div className="row">
          <div className="col-sm-3">
          <div className="sideBar">
          <div className="account-settings">
        <nav className="nav flex-column">
          <Link to="/account" className="nav-link" href="#">
            Basic Settings
          </Link>
          <Link to="/password" className="nav-link" href="#">
            Password
          </Link>
          <Link to="/location" className="nav-link" href="#">
            Location Settings
          </Link>
          <Link to="/email" className="nav-link active" href="#">
            Email Preferences
          </Link>
          <Link to="/closeaccount" className="nav-link" href="#">
            Close Account
          </Link>
        </nav>
      </div>
            </div>
          </div>
          <div className="col-sm-9 pt-5 basicsettings-container">
            <h3 className="basicsettings-title">Email Preferences</h3>
            <div className="basicsettings-body">
            <h4 className="email-title">Attending Events</h4>
            <p className="email-news">News and updates about events created by event organizers</p>
            <p className="email-email">Email me</p>
              <div className="checkbox checkbox-success">
                <input id="checkbox1" type="checkbox" />
                <label for="checkbox1">
                  Updates about new Nairabox features and announcements
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox2" type="checkbox" />
                <label for="checkbox2">
                  Nairabox weekly event guide: A digest of personalized event recommendations
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox3" type="checkbox" />
                <label for="checkbox3">
                  Request for additional information on an event after you have attended
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox4" type="checkbox" />
                <label for="checkbox4">
                  Unsubscribe from all Nairabox newsletters and updates for attendees
                </label>
              </div>
              <p className="email-notification">Notifications</p>
              <div className="checkbox checkbox-success">
                <input id="checkbox5" type="checkbox" />
                <label for="checkbox5">
                  When friends buy tickets or register for events near me
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox6" type="checkbox" />
                <label for="checkbox6">
                  When an organizer you follow announces new events
                </label>
              </div>
              <h4 className="email-title">Organizing Events</h4>
              <p className="email-news">Helpful updates and tips for organizing events on Nairabox</p>
              <p className="email-email">Email Me</p>
              <div className="checkbox checkbox-success">
                <input id="checkbox7" type="checkbox" />
                <label for="checkbox7">
                  Updates about new Nairabox features and announcements
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox8" type="checkbox" />
                <label for="checkbox8">
                  Monthly tools and tips for organizing events
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox9" type="checkbox" />
                <label for="checkbox9">
                  Event sales recap
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox10" type="checkbox" />
                <label for="checkbox10">
                  Unsubscribe from all Nairabox newsletters and updates for attendees
                </label>
              </div>
              <p className="email-notification">Notifications</p>
              <div className="checkbox checkbox-success">
                <input id="checkbox11" type="checkbox" />
                <label for="checkbox11">
                  Important reminders for my next event
                </label>
              </div>
              <div className="checkbox checkbox-success">
                <input id="checkbox12" type="checkbox" />
                <label for="checkbox12">
                  Order confirmations from my attendees
                </label>
              </div>
              <button className="btn btn-success email-save mb-5">Save Preferences</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (BasicEmail);