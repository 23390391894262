import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

export default class Terms extends Component {
	
	render() {
		return <div>
        <Header />

        <div className="container-fluid">
          <div className="termsConditions text-white col-sm-10 m-auto pt-5 pb-5">
            <h4 className="mb-4"> Ticketing Terms and conditions </h4>
            
            <p> Please read this alongside our main Terms of Use</p>
            <p>
              The website at www.nairabox.com(our website) is owned and
              operated by JORG Technologies Limited(“JORG” or we). These
              terms and conditions apply to tickets for performances and
              events and references in these terms to 'performances' will
              also include events.
            </p>
            <p>
              <b>Introduction</b>
            </p>
            
            <p>
              JORG Technologies Ltd (“we”, “us”, “JORG” or “Nairabox”) is a
              ticketing sale platform of entertainment events (“Event”,
              “Events”). Tickets (“Tickets”) for Events are sold by Nairabox
              to purchasers through our website (www.nairabox.com) or app.
              Notwithstanding the terms of use of our app which may apply to
              the sale of a Ticket for a particular Event, by purchasing
              and/or holding and/or being in possession of a Ticket or
              Tickets, the holder of the Ticket (“Ticket holder”, “you”,
              “your”) agrees to and is bound by these terms and conditions
              (“Ticketing Terms”).
            </p>
            <p>
			The provisions of these Ticketing Terms are for the benefit of  Ticket holder and/or Nairabox and may be accepted by the Venue at any time.
			If you are under the age of 18, you must obtain your parents’ or legal guardians’ prior authorization, permission and consent to attend an Event.
			All clauses in the Ticketing Terms indicated in BOLD are provisions which limit the risk or liability of JORG and/or Nairabox and constitute an assumption of risk or liability by you, impose an obligation on you to indemnify JORG and/or Nairabox or is an acknowledgement of a fact by you, and are therefore drawn to your attention. You should pay particular attention to such clauses and consider them carefully.
			
            </p>
			<p>
              <b>The Ticketing Terms</b>
            </p>
			<p>
			1. Each Ticket you purchase from or through Nairabox is intended to give you a revocable license (or right) 
			to entry and attendance of the particular Event only to which the Ticket pertains. Each Ticket holder has a 
			right to a seat or standing place in relation to the stage, of a value corresponding to that stated on the 
			Ticket holder’s Ticket. Nairabox and the Venue reserve the right to provide alternative seats or standing place 
			to those specified on the Ticket.
			
            </p>
			<p>
			2. A Ticket does not afford the Ticket holder the right to transport to the Venue, or parking, or merchandise, 
			or any food or beverages at the Venue, unless otherwise stated. Other independent service providers might 
			render services relating to transport, parking, merchandise, food or beverages at the Venue and in such 
			circumstances, the terms and conditions of such service providers will apply between it and persons who make 
			use of such products or services.
            </p>
			<p>
			3. You must produce a valid Ticket to gain entry to the Venue and you must produce that Ticket for inspection 
			upon request from an Event official at any time. Failure to produce such Ticket when requested may result 
			in you being denied access to the Venue or in you being removed from the Venue.
            </p>
			<p>
			4. Tickets are not transferable and cannot be resold or offered for resale (including on-line auction sites) 
			or used for commercial purposes including advertising, competitions, contests, sweepstakes, promotions, 
			or used, sold or given away in conjunction with other products or services (such as part of a travel or 
			hospitality package) without the express written permission of Nairabox in advance. 
			If this condition is breached the Ticket may be <b>cancelled without refund or compensation and the 
			bearer of the Ticket may be refused entry or removed </b>from the Venue.
            </p>

			<p>
			5. Any Tickets bought from unauthorized agents or third parties are not valid and admission to an Event on the 
			strength of such Tickets will not be permitted. All Tickets remain the property of Nairabox at all times and 
			constitute a personal revocable license from Nairabox to the Ticket holder which may be withdrawn by Nairabox and 
			admission refused at any time upon refunding the printed purchase price of the Ticket.
            </p>
			<p>
			6. We reserve the right to restrict the sale of Tickets to a maximum number per person, per credit/debit card and/or 
			per household/organization and to cancel any Tickets purchased in excess of this number.
            </p>
			<p>
			7. If an error is made in the price of Tickets ordered by you, you will be notified by us as soon as possible and we will 
			give you the option to either (a) reconfirm your Ticket order at the correct price; or (b) cancel your Ticket order and 
			receive a full refund from us.
            </p>
			<p>
			8. It is your responsibility to (i) ensure that your Tickets are collected by or delivered to you before the date of the Event; 
			(ii) keep your Tickets safe prior to the Event; and (iii) check your Tickets and to ascertain the date and the time of the Event 
			and if applicable, any rescheduled Event. Event dates and times are subject to change. Neither JORG and/or Nairabox is responsible 
			or liable should you fail to obtain your Tickets timeously or for any Tickets that are lost, stolen or damaged.

            </p>
			<section id="refund">
				<h4 className="mb-4"> Refund and Cancellation Policy</h4>
				<p>
				9. You are entitled to cancel your purchase of and return your Ticket(s) at any time prior to the scheduled date of the 
				Event to which the Ticket(s) apply by contacting the us or our customer service team (whichever is expedient) and 
				arranging such cancellation and return, subject to the following:
				</p>
				<p>
				9.1.If you cancel your purchase of and return your Ticket(s) within 7 (seven) days of purchase, you will be entitled to a 
				full refund of the face value of the ticket.
				</p>
				<p>
				9.1.1. If you cancel your purchase of and return your Ticket(s) more than 7 (seven) days after the date on which the ticket was purchased, 
				you will be entitled to a refund of the face value of the ticket less a reasonable cancellation charge, calculated as follows:
				</p>
				<p>
				9.1.2.1. for Ticket(s) returned up to 90 days prior to the event date, a reasonable cancellation charge of 15% (fifteen percent) will apply;
				</p>
				<p>
				9.1.2.2. for Ticket(s) returned up to 60 days prior to the event date, a reasonable cancellation charge of 30% (thirty percent) will apply;
				</p>
				<p>
				9.1.1.3. for Ticket(s) returned up to 40 days prior to the event date, a reasonable cancellation charge of 50% (fifty percent) will apply;
				</p>
				<p>
				9.1.1.4. for Ticket(s) returned less than 30 days prior to the event date, a reasonable cancellation charge of 100% (one hundred percent) will apply;
				</p>
				<p>
				9.1.1.5.  No refund shall be issued in respect of a cancelled purchase unless the original Ticket(s) are returned.
				</p>
				<p>
				9.1.1.6. You hereby acknowledge and agree that the aforementioned cancellation charges constitute reasonable charges.
				</p>
				<p>
				9.1.1.7. Notwithstanding the above, if you can provide us with proof that the person for whom, or whose benefit a Ticket was purchased will not be able to 
				attend the Event because of his or her death or hospitalization, no cancellation fee will apply in respect of that Ticket.
				</p>
				<p>
				9.1.1.8 If you cancel your purchase of and return your Ticket(s) within 7 (seven) days of purchase, you will be entitled to a full refund of the face value of the ticket; 
				</p>
				<p>
				9.1.1.9 After the expiry of the aforementioned 7 (seven) day period after the date on which the Ticket(s) was purchased, you will have no right to cancel your purchase of and return your Ticket(s).
				</p>
				<p>
				9.1.1.10 for Ticket(s) returned up to 60 days prior to the event date, a reasonable cancellation charge of 50% (fifty percent) will apply;
				</p>
				<p>
				9.1.1.11 for Ticket(s) returned less than 60 days prior to the event date, a reasonable cancellation charge of 100% (one hundred percent) will apply;
				</p>
				<p>
				9.1.3.     No refund shall be issued in respect of a cancelled purchase unless the original Ticket(s) are returned.
				</p>
				<p>
				9.1.4.     You hereby acknowledge and agree that the aforementioned cancellation charges constitute reasonable charges as contemplated in the Consumer Protection Act, 2008.

				</p>
				<p>
				9.1.5.     Notwithstanding the above, if you can provide us with proof that the person for whom, or whose benefit a Ticket was purchased will not be able to attend the Event because of his or her death or hospitalisation, no cancellation fee will apply in respect of that Ticket.
				</p>
				<p>
				9.1.6.     Refund requests submitted after the event date has passed need to be submitted within 10 (ten) working days of such event date. All refund requests submitted after the 10 (ten) working days will not be entertained by NAIRABOX.
				</p>
				<p>
				10. Your purchase of Tickets is subject to our right to cancel or reschedule an Event at any time, or to alter or vary the programme of an Event, due to circumstances 
				beyond our reasonable control. Where we alter or vary the programme of an Event, we shall not be obligated to refund any Ticket monies or exchange your Tickets. Please 
				note that if an opening act or guest artist is changed or cancelled, the event is not considered cancelled and you will not be entitled to a refund or exchange. We shall have no liability to you for refund of the purchase price of a Ticket in circumstances where an Event is cancelled due to circumstances beyond our reasonable control.

				</p>
				<p>
				11. If an Event is rescheduled or cancelled, we will use our reasonable endeavours to notify you as soon as reasonably possible thereof(including notifying you of the details of any rescheduled Event) by publishing the details of the rescheduled or cancelled Event, as the
				case may be, on the website located at the domain name https: //www.nairabox.com (the “Website”) and by e-mail and short message service (“SMS”) notification to the contact details which you provide at the time of the purchase of Tickets. It is therefore important that you 
				provide us (whichever is applicable) with your correct contact details at the time of the purchase of Tickets. If the Event is to be rescheduled, you will be offered a Ticket for the rescheduled performance of the Event (subject to availability and you having provided us with correct contact details). 
				Should you not wish to attend such a rescheduled performance of the Event, you will be required, for purposes of safety and security planning in respect of the Event, to notify us, or alternatively our customer service centre at +234 980000381  or support@nairabox.com, of your intention to 
				not attend that Event. You may give such notification by way of verbal confirmation, e-mail or SMS notification, which notification must be received by the date published on the Website and by e-mail and SMS notification to the contact details which you provide at the time of the purchase of Tickets. 
				Should you give notification as stated above or if the Event is not rescheduled, you shall be entitled to a refund of the face value of the Ticket. If the relevant Event is only partially cancelled (eg if it is a multi-day Event and only one day is cancelled), we shall give you a partial refund corresponding 
				to the proportion of the Event cancelled. Any refund referred to herein must be claimed by you by contacting us or our customer care 234 980000381  or support@nairabox.com(whichever is applicable) to arrange the return of your unused Ticket and receive the refund.
				</p>
				<p>
				12. The following restrictions will apply to your attendance at an Event and your breach of any of these may lead to you being ejected from the Venue:
				</p>
				<p>
				12.1. Appropriate standards of behavior are expected of all persons attending an Event and persons deemed to be behaving in a manner that is dangerous or unacceptable in the opinion of 
				Event organizers or its agents or contractors, will be refused admission and/or removed from the Venue with no refund on the Ticket. Activities such as ‘stage diving’, ‘moshing’, ‘climbing’ and ‘crowd surfing’ are strictly prohibited for your own safety.
				</p>
				<p>
				12.2. In order to facilitate the security, safety and comfort of all patrons, we reserve the right in our reasonable discretion to conduct security 
				searches of persons, clothing, bags and all other items on entry and exit of a Venue, to confiscate items which may cause danger or disruption to the 
				Event or to other patrons or that are in breach of the Ticketing Terms and/or to refuse admission and/or to refund the Ticket face value if we have reasonable grounds to do so.
				</p>
				<p>
				12.3. Ticket holders may be ejected from the Venue if, in the reasonable opinion of Event organizers or its agents or any contractors appointed by it, 
				or the Venue, the Ticket holder is a risk to the safety of any patron and/or may affect the enjoyment of other patrons and/or the running of the Event or may cause 
				damage, nuisance or injury. Examples include being (or appearing to be) drunk, incapable, intoxicated or under the influence of illegal drugs, under-age (where relevant), abusive, 
				threatening, behaving antisocially, carrying offensive weapons or illegal substances, declining to be searched or in breach of any of these Ticketing Terms or the terms and conditions of the Venue.
				</p>
				<p>12.4. There may be instances where there are to be no pass-outs or readmi</p>
				<p>12.5. The following items are not permitted to be brought into a Venue:</p>
				<p>12.5.1. Glass bottles or containers or other heavy and/or sharp objects, which could potentially be used as projectiles (excluding sunglasses, binoculars and prescription or reading glasses)</p>
				<p>12.5.2. Any illegal drugs</p>
				<p>12.5.3. Bicycles, scooters or vehicles of such nature</p>
				<p>12.5.4. Knives, weapons of any nature or fireworks</p>
				<p>12.5.5. Any object that could be used to distract, hinder or interfere with any performer, including laser pointers and flashlights</p>
				<p>12.5.6. Flags with poles, large flags or banners that may obstruct the view of other patrons</p>
				<p>12.5.7. Any whistle, horn, musical instrument, loud hailer, public address system or vuvuzela</p>
				<p>12.5.8. Digital, electronic or other recording or broadcast device</p>
				<p>12.5.9. Any item that you intend to distribute, hawk, sell, offer, expose for sale or display for marketing or promotional purposes</p>
				<p>12.5.10. No animals apart from authorised guide/assistance dogs</p>
				<p>12.5.11. Any dangerous goods and any other items by management to be dangerous or capable of causing a public nuisance</p>
				<p>12.5.12. Professional cameras or video recorders</p>
				<p>12.5.13. Umbrellas (unless there is an obvious threat of rain), in which case small umbrellas will be permitted but no golf or beach umbrellas</p>
				<p>12.5.14. Braais or barbeques</p>
				<p>12.5.15. Camping Chairs</p>
				<p>12.5.16. Cooler Boxes</p>
				<p>12.5.17. Food and refreshments (including soft drinks and alcohol).</p>
				<p>12.6. Please note that in seated areas, other members of the audience may stand up during the Event.</p>
				<p>12.7. Smoking is not permitted in the Venue.</p>
				<p>12.8. The unauthorised use of photographic, video and/or sound recording equipment is prohibited.</p>
				<p>12.9. Efforts will be made to admit late comers at an appropriate break in the performance of the Event. Entry will not be permitted if it will disrupt the performance.</p>
				
				<p>13. If you are a disabled person, you are requested to inform Event organizer, its agents and contractors will use their reasonable endeavours to accommodate and assist you at the Event, 
					subject to their capacity to do so, and the confines and layout of the Venue facilities.</p>
				<p>14. All children attending an Event must be in possession of a valid Ticket. Events may be age-restricted and it is the responsibility of the Ticket holder to 
					check age restrictions to an Event before purchasing a Ticket. In any case, unless indicated otherwise, a responsible adult must accompany children 14 years of age and under. No children under the age of 3 (three) are allowed to attend an event without adult supervisor.</p>
				<p>15. Please note that CCTV and film cameras may be present at the Venue. By purchasing Tickets and attending the Event, 
					you consent to being filmed, photographed, videotaped, or otherwise recorded, and that your name, voice and likeness may be 
					broadcasted, displayed or distributed (commercial or otherwise) without any compensation being paid to you.</p>
				<p>16. You voluntarily assume all risks and dangers incidental to an Event, whether occurring before, during or after the Event, 
					and you waive any claims for loss, damage, personal injury or death against JORG, the management of Nairabox, any of its agents, 
					the performing artist(s), participants, and all of their respective parents, affiliated entities, agents, officers, directors, 
					owners and employees on behalf of yourself and any minor accompanying you or for whom you purchased a Ticket.</p>

				<p>	17. Nairabox will not accept responsibility for damage to or loss of any personal property at an Event.</p>
				<p>18. You bear the risk of inclement weather.</p>
				<p>19. To the fullest extent possible in law, we do not accept liability for and you will have no claim in respect of:</p>
				<p>19.1. any errors relating to the location of your seat or standing place relative to the stage as indicated on the seating plan prepared for the Venue; or</p>
				<p>19.2. the quality of the view from your seat or standing place; or</p>
				<p>19.3. the lack of or inadequacy of parking at the Venue; or</p>
				<p>19.4. any problems relating to the adequacy of traffic control to and from the Venue; or</p>
				<p>19.5. the lack of or inadequacy of food and beverages at the Venue; or</p>
				<p>19.6. the quality of sound and lighting at the Event; or</p>
				<p>19.7. the quality of any services provided by independent contractors at the Venue.</p>
				<p>20. You agree and acknowledge that neither JORG nor Nairabox nor any of its agents have guaranteed or made any warranties or representations to you in relation to the accuracy of the location of your seat or standing place relative to the stage as indicated on the seating plan prepared for the Venue, the availability of parking at the Venue and/or the adequacy of traffic control to or from the Venue.</p>
				<p>21. If any provision of these Ticketing Terms is or becomes illegal, invalid or unenforceable in any jurisdiction that shall not affect the legality, validity or enforceability in that jurisdiction of any other provision of these Ticketing Terms; or the legality, validity or enforceability in any other jurisdiction of that or any other provision of these Ticketing Terms.</p>
				<p>22. No indulgence which JORG or Nairabox or any of its agents and/or contractors (the “Grantor”) may grant to you and/or the Ticket holder (the “Grantee”) shall constitute a waiver of any of the rights of the Grantor, who shall not thereby be precluded from exercising any rights against the Grantee which might have arisen in the past or which might arise in the future.</p>
				<p>23. These Ticketing Terms and Conditions Agreement cancel and supersede all prior negotiations and previous agreements, understandings and arrangements, whether written or oral, between you and JORG/Nairabox or any of its agents and/or contractors.</p>
				<p>24. These Ticketing Terms constitute the whole agreement between you and/or the Ticket holder and JORG/Nairabox in respect of the subject matter hereof and no addition to or variation or novation of this agreement shall be of any force or effect, unless reduced to writing and signed by the above parties or their duly authorized representatives.</p>
				<p>25. These Ticketing Terms shall be governed by Nigerian Law and be subject to the exclusive jurisdiction of the Nigerian courts.</p>




			</section>
			
          </div>
        </div>

        <Footer />
      </div>;
	}
}
