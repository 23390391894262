import React, {Component} from 'react';
import '../../styles/eventmanager.css';
import Header from '../header';
import Footer from '../footer';
import EventSideMenu from '../eventManager/sideMenu';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import { onGetAttendance } from '../../store/actions/getAttendance';
import { withRouter } from "react-router";

import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import '../../styles/eventMobileMenu.css';
import  Loader  from '../../components/loader'

class EventOrders extends Component {
  componentDidMount() {
    const eventID  = this.props.match.params.eventID
  // console.log(this.props)
  const uid =localStorage.getItem('uid')
     this.props.onGetAttendance(eventID, uid)
  }
  render () {
     const {login:{isLogged}, register:{isLoggedIn}, attendance: {response: attendanceResponse, fetching}}=this.props;
     console.log(attendanceResponse)
    return (
      <div>
        {/*isLogged || <Redirect to="/"/>*/}
       
  
        {/* {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Loader />} */}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}

        
        <div className="row eventmanager-row">
          <div className="col-sm-3 ">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu" className="list-group-item active" data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2 show" id="sub-menu">
                    {/* <Link to="/eventover" href="#" className="list-group-item " data-parent="#sub-menu">Overview</Link> */}
                    {/* <Link to="/eventreport" href="#" className="list-group-item" data-parent="#sub-menu">Report</Link>
                    <Link to="/eventorders" href="#" className="list-group-item s__active" data-parent="#sub-menu">Manage Attendance</Link> */}
                </div>
              
                {/* <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item ">Edit Information<span className="caret"></span></a>
                  <div className="collapse list-group-level" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item " data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item " data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div> */}
                {/* <Link to="/eventquestions" href="#" className="list-group-item">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item" >Promote Event <span className="caret"></span></Link>
                  <Link to="/eventsettings" href="#" className="list-group-item">Additional Settings</Link> */}
                
            </div>
            </div>
          </div>
          <div className="col-sm-9 event-attendees-container">
            <h2>Orders and attendees</h2>
            <p>
              This panel allows you to manage the settings for both the ticket purchaser and attendees.
              {/* View, edit and delete orders or add additional guests. You can also send mass messages to the entire group or contact individuals directly */}
            </p>

            <div className="row row-event-attendees">
              <div className="col-md-6 event-attendees">
                <p className="event-attendees__text">Attendees for:</p>

                <form>
                  <div class="form-group">
                    <select class="custom-select" id="inputGroupSelect01">
                      <option selected>Sat, Sep 29, 2018, 10:00AM</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>

                  </div>
                </form>
              </div>
              <div className="col-md-3">
                <form>
                  <div class="form-group">
                    <select class="custom-select" id="inputGroupSelect01">
                      <option selected>Filter orders by status</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className="col-md-3">
                <form>
                  <div class="form-group event-search-class">
                  <i class="fas fa-search event-search__icon"></i>
                    <input
                      type="email"
                      className="form-control event-search"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Search"
                    />

                  </div>
                </form>
              </div>
            </div>

            <div className="event-attendees__table">
              <div className="event-attendees__table-top">
                {/* <div className="event-attendees__message">
                <i className="far fa-envelope event-message__icon"></i>
                  <button className="btn-event">Message attendees</button>
                </div> */}
                {/*<div className="event-attendees__changetime">
                  <button className="btn-event">Change event time</button>
    </div>*/}
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      {/* <th scope="col">LastName</th> */}
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Ticket</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(attendanceResponse) ? attendanceResponse.map((value, key) =>
                    <tr>
                      <td>{value.customerName}</td>
                      <td>{value.customerEmail}</td>
                      <td>{value.customerPhone ? value.customerPhone : value.uid}</td>
                      <td>{value.ticketClass}</td>
                      {/* <td>VIP</td> */}
                    </tr>
                 ) : <h2>No sales</h2>  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        
      </div>
    );
  }
}
const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
    attendance: state.attendance
});

const mapDispatchToProps = (dispatch) => ({
	onGetAttendance: bindActionCreators(onGetAttendance, dispatch)
});

export default withRouter(connect (mapStateToProps, mapDispatchToProps) (EventOrders));