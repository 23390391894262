import React, { Component } from 'react';
import '../styles/faq.css';
import Header from '../components/header';
import Footer from '../components/footer';

export default class FAQ extends Component {
	componentDidMount = () => {
		var acc = document.getElementsByClassName('accordion');
		var i;

		for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener('click', function() {
				this.classList.toggle('active');
				var panel = this.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + 'px';
				}
			});
		}
	};
	render() {
		return (
			<div>
				<Header />
				<div className="background">
					<div className="layer">
						<div className="faq-background-text">
							<h1>We are here to help you</h1>
							<p>Browse through the most frequently asked questions</p>
						</div>
					</div>
					<div className="faq-background-image" />
				</div>
				<div className="container-fluid">
					<div className="accordionBody">
						<div className="col 12 col-sm-8 m-auto">
							<button class="accordion">My payment failed?</button>
							<div class="panel">
								<p className="pt-2">
									Failed payments typically happen when credit card information is entered incorrectly
									or the card is declined.
									<p>
										Declines can happen for a variety of reasons other than the availability of
										funds. Incorrect information can cause your transaction to be blocked
									</p>
								</p>
							</div>

							<button class="accordion">Why is my ticket not "released" yet? </button>
							<div class="panel">
								<p className="pt-2">
									The default setting is to send tickets immediately after booking. However, if ticket
									is delayed check your spammed folder of your email and if ticket is still not found,
									feel free to call us with the number below or shoot us a mail.
								</p>
							</div>

							<button class="accordion">
								I was charged by Nairabox, but don't know if the registration went through.{' '}
							</button>
							<div class="panel">
								<p className="pt-2">
									If you see an Nairabox charge on your bank or credit card statement but don't see a
									confirmation mail, it's likely a pending charge. Pending charges should drop off in
									a few banking days (or sooner).
								</p>
							</div>

							<button class="accordion">
								I need to know what’s required to enter or access the event.
							</button>
							<div class="panel">
								<p className="pt-2">
									Come to the event venue, with the ticket sent in the confirmation mail.
								</p>
							</div>

							{/* <button class="accordion">Why is my ticket not "released" yet?</button>
							<div class="panel">
								<p className="pt-2">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
									exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
								</p>
							</div>

							<button class="accordion">How to delete an event</button>
							<div class="panel">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
									exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
								</p>
							</div> */}
						</div>
					</div>
				</div>

				<div className="faq-cant-find faq-background-text">
					<h1>Can't find what you're looking for?</h1>
					<p>
						Email your query to to <a href="mailto:support@nairabox.com">support@nairabox.com</a>
					</p>
				</div>
				<Footer />
			</div>
		);
	}
}
