import React, { Component } from 'react'
import '../../styles/ap.css'
import '../../styles/myevent.css'
// import Header from '../header'
// import Footer from '../footer'
import Header from '../header'
import LoggedInHeader from '../loggedInHeader'
import Footer from '../footer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { onLogin } from '../../store/actions/login'
import { onRegister } from '../../store/actions/register'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'

class EventTicket extends Component {
  render () {
    const {login:{isLogged}, register:{isLoggedIn}} = this.props
    const uid = localStorage.getItem('uid')
    return (
      <div>
        {(isLogged || isLoggedIn) || <Redirect to='/' />}
        {isLogged || isLoggedIn ?
           <LoggedInHeader/> :
           <Header />}
        <div className='container-fluid navlink'>
          <nav className='nav'>
            <Link to={(`/eventdashboard/${uid}`)} className='nav-link' href='#'> Dashboard
            </Link>
            <Link to={(`/eventlist/${uid}`)} className='nav-link ' href='#'> My Events
            </Link>
            <Link to='/eventticket' className='nav-link active ' href='#'> My Tickets
            </Link>
          </nav>
        </div>
        <div className='container-fluid Dashboardbody'>
          <div className='marginless'>
            <h4 className='event-description '>My Tickets</h4>
            <nav className='myEvents tickets'>
              <div className='nav event-tab  nav-tabs' id='nav-tab' role='tablist'>
                <a
                  className='nav-item nav-link active'
                  id='myTicket-tab'
                  data-toggle='tab'
                  href='#myTicket'
                  role='tab'
                  aria-controls='nav-myTicket'
                  aria-selected='true'>My Tickets</a>
                <a
                  className='nav-item nav-link'
                  id='savedTicket-tab'
                  data-toggle='tab'
                  href='#savedTicket'
                  role='tab'
                  aria-controls='nav-savedTicket'
                  aria-selected='false'>Saved Tickets</a>
                <form className='form-inline'>
                  <input
                    className='form-control mr-sm-2'
                    type='search'
                    placeholder='Search'
                    aria-label='search all event by name' />
                </form>
              </div>
            </nav>
            <div className='tab-content' id='nav-tabContent'>
              <div
                className='tab-pane fade show active'
                id='myTicket'
                role='tabpanel'
                aria-labelledby='myTicket-tab'>
                <div className='row'>
                  <div className='col-sm-8 '>
                    <div className='dashboardCard'>
                      <div className='p-2'>
                        <div className='salesReport'>
                          <div className=' col-6 m-auto'>
                            <img src=' /image/analytics.png ' alt='Events in Nigeria ' />
                            <p>
                              You don’t have a event ticket, Want to fix that?
                            </p>
                            <a className='btn btn-primary btn-white-main' href='#'>See Events</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 '>
                    <div className='dashboardCard quicklinks '>
                      <div className='p-4 '>
                        <h5 className='pt-2 '>Recently Veiwed Events</h5>
                        <ul className='list-unstyled'>
                          <li className='media'>
                            <img
                              className='mr-3'
                              src='/image/event-bg.png'
                              height='50'
                              alt='event in lagos' />
                            <div className='media-body'>
                              <h5 className='mt-0 mb-1'>Random Events</h5> Starting from N10,000
                            </div>
                          </li>
                        </ul>
                        <ul className='list-unstyled'>
                          <li className='media'>
                            <img
                              className='mr-3'
                              src='/image/event-bg.png'
                              height='50'
                              alt='event in lagos' />
                            <div className='media-body'>
                              <h5 className='mt-0 mb-1'>Random Events</h5> Starting from N10,000
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='savedTicket'
                role='tabpanel'
                aria-labelledby='savedTicket-tab'>
                4
              </div>
            </div>
          </div>
        </div>
        <div className='event-ticket__footer'>
          <Footer/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  login: state.login,
  register: state.register
})

export default connect(mapStateToProps)(EventTicket)
