import React, {Component} from 'react';
import '../../styles/eventmanager.css';
import Header from '../header';
import Footer from '../footer';
import EventSideMenu from '../eventManager/sideMenu';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import '../../styles/eventMobileMenu.css';

class EventSettings extends Component {
  
  render () {
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div>
         {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        <div className="row eventmanager-row">
          <div className="col-sm-3">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu"  className="list-group-item " data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2"npm id="sub-menu">
                    <Link to="/eventover" href="#" className="list-group-item" data-parent="#sub-menu">Overview</Link>
                    <Link to="/eventreport" href="#" className="list-group-item" data-parent="#sub-menu">Report</Link>
                    <Link to="/eventorders" href="#" className="list-group-item" data-parent="#sub-menu">Manage Attendance</Link>
                </div>
              
                <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item ">Edit Information<span className="caret"></span></a>
                  <div className="collapse list-group-level" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div>
                <Link to="/eventquestions" href="#" className="list-group-item">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item" >Promote Event <span className="caret"></span></Link>
                <Link to="/eventsettings" href="#" className="list-group-item active s__active">Additional Settings</Link>
                 
            </div>
            </div>
          </div>
          <div className="col-sm-9 event-questions-container">
            <h2>Advanced Settings</h2>
            <p>
              Set up advanced ticketing options and tailor your email notifications.
            </p>
            <div className="row">
              <div className="col-sm-4 event-settings-row">
                <div className="event-settings">
                  <h4>End ticket sales</h4>
                  <p>
                    Set when you would like your event ticket sales to end. This will apply to each date on your event.
                  </p>
                  <div className="form-group">

                    <select className=" form-control" id="exampleFormControlSelect1">
                      <option>Allow sales until the end of your event</option>
                      <option>When event starts</option>
                      <option>1 hour before event starts</option>
                      <option>2 hours before event starts</option>
                      <option>1 day before event starts</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 event-settings-row">
                <div className="event-settings">
                  <h4>Email me of ticket purchases</h4>
                  <p>
                    Set how often you would like to be notified about ticket purchases.
                  </p>
                  <div className="form-group">

                    <select className="form-control" id="exampleFormControlSelect1">
                      <option>Send individually</option>
                      <option>Send in groups</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 event-settings-row">
                <div className="event-settings">
                  <h4>Attendee reminder emails</h4>
                  <p>
                    Send a reminder email to attendees 24 hours before your event.
                  </p>
                  <div className="form-group">

                    <select className="form-control" id="exampleFormControlSelect1">
                      <option>Send email reminders</option>
                      <option>Dont send reminders</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-main mt-4">Save</button>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (EventSettings);