import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onLogin } from '../store/actions/login';
import { Link } from 'react-router-dom';
//import '../App.css';
import '../styles/login.css';
//import '../styles/responsive.css';
//import '../styles/myevent.css';
import '../styles/formtype.css';
// import jQuery from 'jquery';
import Signup from './signup';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: ''
		};
		this.toggla = this.toggla.bind(this);
	}
	toggla() {
		this.setState({
			signupModal: !this.state.signupModal
		});
	}
	onEnter = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};
	onSubmit = (e) => {
		e.preventDefault();
		const { email, password } = this.state;
		this.props.onLogin(email, password);
	};

	componentDidMount = () => {
		var formInputs = $('input[type="email"], input[type="text"], input[type="password"], input[type="number"]');
		formInputs.focus(function() {
			$(this).parent().children('p.formLabel').addClass('formTop');
			$('div#formWrapper').addClass('darken-bg');
			$('div.logo').addClass('logo-active');
		});
		formInputs.focusout(function() {
			if ($.trim($(this).val()).length == 0) {
				$(this).parent().children('p.formLabel').removeClass('formTop');
			}
			$('div#formWrapper').removeClass('darken-bg');
			$('div.logo').removeClass('logo-active');
		});
		$('p.formLabel').click(function() {
			$(this).parent().children('.form-style').focus();
		});
	};
	render() {
		const { email, password } = this.state;
		const { fetching } = this.props;
		console.log(this.state);
		if (fetching) {
			return <p>Loading ...</p>;
		}
		return (
			<div className="modal-content">
			{/* <div class="modal-header">
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div> */}
				<div className="modal-body">
				
					<form onSubmit={this.onSubmit}>
						<div id="form">
							<div className="form-item">
								<p class="formLabel">Email</p>
								<input
									type="email"
									name="email"
									id="email"
									className="form-style"
									autoComplete="off"
									value={email}
									onChange={this.onEnter}
									required
								/>
							</div>
							<div className="form-item">
								<p class="formLabel">Password</p>
								<input
									type="password"
									name="password"
									id="password"
									class="form-style"
									value={password}
									onChange={this.onEnter}
									required
								/>
							</div>
							<div>
								<p className="pull-right forgot">
									<a href="#">
										<strong>Forgot your Password?</strong>
									</a>
								</p>
							</div>
							<div className="modal-btn">
								<div className="clear-fix">
									<input type="submit" className="login" value="Log In" />
								</div>
							</div>
						</div>
					</form>
				</div>
				<h4 className="modalBottom">
					Don’t have Nairabox Event account?{' '}
					<span>
						<a onClick={this.toggla} data-toggle="modal" data-target="#signupModal">
							Sign Up
						</a>
						to Create and manage events
					</span>{' '}
				</h4>
				<Modal isOpen={this.state.signupModal} toggle={this.toggla}>
					<ModalHeader toggle={this.toggle}>Signup</ModalHeader>
					<ModalBody>
						<Signup />
					</ModalBody>
					<ModalFooter />
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ login: state.login });

const mapDispatchToProps = (dispatch) => ({
	onLogin: bindActionCreators(onLogin, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
