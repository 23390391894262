import React, {Component} from 'react';
import '../../styles/eventmanager.css';
import Header from '../header';
import Footer from '../footer';
import EventSideMenu from '../eventManager/sideMenu';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import '../../styles/eventMobileMenu.css';

class EventQuestion extends Component {
  render () {
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div>
       {/*isLogged || <Redirect to="/"/>*/}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}

        {/* <div className="drawer-menu-container">
        <input type="checkbox" id="drawer-toggle" name="drawer-toggle"/>
<label for="drawer-toggle" id="drawer-toggle-label"></label>
<div id="drawer-menu-header">Menu</div>
<label for="drawer-toggle" id="drawer-close"></label>
<div class="header">
  <span>My Awesome Website</span>
</div>
<div id="drawer">
  <ul>
    <li><a href="">Menu 1</a></li>
    <li><a href="">Menu 2</a></li>
    <li><a href="">Menu 3</a></li>
  </ul>
</div>
          
        </div> */}

        <div className="row eventmanager-row">
          <div className="col-sm-3 ">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu" className="list-group-item " data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2" id="sub-menu">
                    <Link to="/eventover" href="#" className="list-group-item " data-parent="#sub-menu">Overview</Link>
                    <Link to="/eventreport" href="#" className="list-group-item" data-parent="#sub-menu">Report</Link>
                    <Link to="/eventorders" href="#" className="list-group-item" data-parent="#sub-menu">Manage Attendance</Link>
                </div>
              
                <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item ">Edit Information<span className="caret"></span></a>
                  <div className="collapse list-group-level" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div>
                <Link to="/eventquestions" href="#" className="list-group-item active s__active ">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item" >Promote Event <span className="caret"></span></Link>
                <Link to="/eventsettings" href="#" className="list-group-item">Additional Settings</Link>
                
            </div>
            </div>
          </div>
          <div className="col-sm-9 event-questions-container">
            <div className="row event-questions-body">
              <div className="col-md-6">
                <div className="event-questions__left-header">
                  <div className="event-questions__header-row">
                    <h4>Checkout Form</h4>
                    <div className="event-questions__button">
                      <button className="btn btn-ticket ">Add question</button>
                      <button className="btn btn-main save-btn">Save</button>
                    </div>
                  </div>
                  <div className="event-questions__left-body">
                    <p>Per Ticket</p>
                    <div class="form-group event-questions__left-body-form">

                      <input
                        type="text"
                        className="form-control form-control__questions"
                        id="Input1"
                        placeholder="Name"
                      />
                    </div>
                    <div class="form-group event-questions__left-body-form">

                      <input
                        type="email"
                        className="form-control form-control__questions"
                        id="Input2"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 event-questions__right">
                <div className="event-questions__right-header">
                  <h4>Ticket Information</h4>
                  <div class="form-group">
                    <label for="example1">Name<span className="asterisk">*</span></label>
                    <input
                      type="email"
                      className="form-control form-control__questions"
                      id="example1"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group">
                    <label for="example2">Email<span className="asterisk">*</span></label>
                    <input
                      type="email"
                      className="form-control form-control__questions"
                      id="example2"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (EventQuestion);