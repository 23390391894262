import React, { Component } from 'react'

export default class FreeTicketEvent extends Component {

  constructor() {
    super();

    this.state = {
      eventClassName: '',
      maxQuantity: '',
      eventPrice: '',
      ticketStartSale: '',
      ticketEndSale: '',
      ticketStatus: '',
      salesChannel: '',
      minTicketsAllowed: '',
      maxTicketsAllowed: ''
    }
  }

  onEnter = ({ e, index }) => {
    const { onEnterMyEventDetails } = this.props;
    const { name, value } = e.target;
    const payload = { [name]: value };
    const type = "freeTicket";
    const classes = {
      type, index, payload
    }
    onEnterMyEventDetails(classes);
  };


  render() {
    const { onDeleteTicket, onChangeTicketSalesChannel, onChangeTicketStatus } = this.props;
    const key = this.props.collapseID;
    const collapseID = `#${key}`;
    return (
      <div key={key}>
        <div className="card card-body card-ticket">
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label>Free Ticket:</label>
                <input
                  type="text"
                  name="eventClassName"
                  id="eventClassName"
                  className="form-style"
                  autoComplete="off"
                  placeholder="Early Bird, Regular, VIP"
                  value={key.eventClassName}
                  onChange={e => this.onEnter({ e, index: key })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Maximum Quantity:</label>
                <input
                  type="text"
                  name="maxQuantity"
                  id="maxQuantity"
                  className="form-style"
                  autoComplete="off"
                  placeholder="200"
                  value={key.maxQuantity}
                  onChange={e => this.onEnter({ e, index: key })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Price:</label>
                <input type="text" name="priceFree" className="form-style freeticket-form" value="Free" disabled />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label>Actions:</label>
                <p>
                  <a
                    className="ticket-cog"
                    data-toggle="collapse"
                    href={collapseID}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <img
                      className=" ticket-offer__icon mr-2"
                      src="image/setting.png"
                    />
                  </a>
                  <span onClick={() => onDeleteTicket({ index: key })}>
                    <img
                      className="ticket-offer__icon"
                      src="/image/delete.png"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="collapse card-ticket__details" id={key}>
          <div className="card card-body card-ticket__card">
            <div>
              <div className="row ticket-label">
                <div className="col-lg-6">
                  <h5 className="form-content__label">
                    Ticket sale starts
                  </h5>
                  <div className="form-item">
                    <p className="formLabel" />
                    <input
                      type="datetime-local"
                      name="ticketStartSale"
                      id="ticketStartSale"
                      className="form-style"
                      autoComplete="off"
                      value={key.ticketStartSale}
                      onChange={e => this.onEnter({ e, index: key })}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h5 className="form-content__label">Ticket sale ends</h5>
                  <div className="form-item">
                    <p className="formLabel" />
                    <input
                      type="datetime-local"
                      name="ticketEndSale"
                      id="ticketEndSale"
                      className="form-style"
                      autoComplete="off"
                      value={key.ticketEndSale}
                      onChange={e => this.onEnter({ e, index: key })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h5 className="form-content__label">Ticket status</h5>
                <div className="switch-field">
                  <input
                    type="radio"
                    id="switch_left002"
                    name="ticketType"
                    value="public"
                    defaultChecked
                    onChange={e => onChangeTicketStatus({ e, index: key, type: 'free' })}
                  />
                  <label htmlFor="switch_left002">Public</label>
                  <input
                    type="radio"
                    id="switch_right002"
                    name="ticketType"
                    value="private"
                    onChange={e => onChangeTicketStatus({ e, index: key, type: 'free' })}
                  />
                  <label htmlFor="switch_right002">Private</label>
                </div>
              </div>
              <div className="col-md-6">
                <h5 className="form-content__label">Sales channel</h5>
                <div className="switch-field">
                  <input
                    type="radio"
                    id="switch_3_left"
                    name="platform"
                    value="everyone"
                    defaultChecked
                    onChange={e => onChangeTicketSalesChannel({ e, index: key, type: 'free' })}
                  />
                  <label htmlFor="switch_3_left">Everyone</label>
                  <input
                    type="radio"
                    id="switch_3_center"
                    name="platform"
                    value="online"
                    onChange={e => onChangeTicketSalesChannel({ e, index: key, type: 'free' })}
                  />
                  <label htmlFor="switch_3_center">Online</label>
                  <input
                    type="radio"
                    id="switch_3_right"
                    name="platform"
                    value="outlets"
                    onChange={e => onChangeTicketSalesChannel({ e, index: key, type: 'free' })}
                  />
                  <label htmlFor="switch_3_right">Outlets</label>
                </div>
              </div>
            </div>
            <div className="tickets-allow">
              <h5 className="form-content__label">
                Tickets allow per order
              </h5>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-item">
                    <p className="formLabel">1</p>
                    <input
                      type="number"
                      name="maxAllowedOrders"
                      id="maxAllowedOrders"
                      className="form-style"
                      autoComplete="off"
                      value={key.maxAllowedOrders}
                      onChange={e => this.onEnter({ e, index: key })}
                    />
                  </div>
                  <p className="ticket-number">Minimum</p>
                </div>

                <div className="col-md-3">
                  <div className="form-item">
                    <p className="formLabel">10</p>
                    <input
                      type="number"
                      name="maxAllowedOrders"
                      id="maxAllowedOrders"
                      className="form-style"
                      autoComplete="off"
                      value={key.maxAllowedOrders}
                      onChange={e => this.onEnter({ e, index: key })}
                    />
                  </div>
                  <p className="ticket-number">Maximum</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
