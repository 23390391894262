import React, {Component} from 'react';
import $ from 'jquery';
//import '../../App.css';
import '../../styles/createevent.css';
import '../../styles/formtype.css';
import Header from '../header';
import Footer from '../footer';
import { Link } from "react-router-dom";
//import EventSideMenu from '../eventManager/sideMenu';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onUpdateEvent} from '../../store/actions/updateEvent';
import '../../styles/eventMobileMenu.css';

class EventBasicInfo extends Component {
  state = {
    banner:"",
    coverImage:"",
    eventname:"",
    ageRestriction:"",
    commission:"",
    venue:"",
    category:"",
    tag:"",
    eventType:"",
    referal:"",
    startDate:"",
    endDate:"",
    city:"",
    state:"",
  };
  onShowPaid = () => {
    let {paidTicket}=this.state;
    paidTicket=[...paidTicket,1]
    this.setState ({
      showPaid: true,
      paidTicket
    });
  };
  onDeletePaid=({index})=>{
    let {paidTicket}=this.state;
    paidTicket.splice(index,1)
    this.setState({paidTicket})
  }

  onShowFree = () => {
    let {freeTicket} = this.state;
    freeTicket=[...freeTicket,1]
    this.setState ({
      showFree: true,
      freeTicket
    });
  };
  onDeleteFree=({index})=>{
    let {freeTicket}=this.state;
    freeTicket.splice(index,1)
    this.setState({freeTicket})
  }

  
  
  
  componentDidMount(){
    this.props.onUpdateEvent();
  }
  render () {
     const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div>
        {(isLogged || isLoggedIn) || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        <div className="row eventmanager-row">
          <div className="col-sm-3 ">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu" className="list-group-item " data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2" id="sub-menu">
                    <Link to="/eventover" className="list-group-item " data-parent="#sub-menu">Overview</Link>
                    <Link to="/eventreport" className="list-group-item" data-parent="#sub-menu">Report</Link>
                    <Link to="/eventorders" className="list-group-item" data-parent="#sub-menu">Manage Attendance</Link>
                </div>
              
                <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item active ">Edit Information<span className="caret"></span></a>
                  <div className="collapse list-group-level show" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item s__active edit-event-side" data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item edit-eventclass-side" data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div>
                <Link to="/eventquestions" href="#" className="list-group-item">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item" >Promote Event <span className="caret"></span></Link>
                 <Link to="/eventsettings" href="#" className="list-group-item">Additional Settings</Link>
                 
            </div>
            </div>
          </div>
          <div className="col-sm-9 eventmanager-container">
        <h4 className="event-header__create-event">Edit event</h4>
       
<form>
        <div className="col-12 form-container">
          <div className="form-content" id="form">
            <h3 className="form-content__title">Basic Information</h3>
            <h5 className="form-content__label">Name of the event</h5>
            <div className="form-item">
              <p className="formLabel">Give it a short and catchy title</p>
              <input
                type="text"
                name="eventname"
                id="eventname"
                className="form-style"
                autocomplete="off"
              />
            </div>
            <h5 className="form-content__label">Event description</h5>
            {/*<Editor apiKey="API_KEY" init={{plugins: 'link table'}} />*/}
            <textarea className="create-event-desc" name="summary" id="summary"  onChange={this.onEnter}>

						</textarea>
            <div className="row event-tag resp-column">
              <div className="col-6 form-group">
                <h5 className="form-content__label">Event Category</h5>
                <div class="create-event-cat">
                <select id="category" name="category" className="form-control event-select">
                  <option className="">Select an event</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
                </div>
              </div>

              <div className="col-6">
                <h5 className="form-content__label">Tags</h5>
                <div class="form-item">

                  <p className="formLabel">Add tags and seperate by commas</p>
                  <input
                    type="text"
										name="tag"
										id="tag"
                    className="form-style "
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="row resp-column">
              <div className="col-6">
                <h5 className="form-content__label">Who can see my event?</h5>
                <div className="event-button">
                  <button className="btn btn-light ticket-button__status">
                    Public
                  </button>
                  <button className="btn btn-light ticket-button__status">
                    Private
                  </button>
                </div>
              </div>
              <div className="col-6">
                <h5 className="form-content__label">Enter referal code</h5>
                <div className="form-item">
                  <p className="formLabel">Enter code</p>
                  <input
                    type="text"
										name="referal"
										id="referal"
                    className="form-style"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 form-container">
          <div className="form-content" id="form">
            <h3 className="form-content__title">When and where</h3>
            <div className="row resp-column">

              <div className="col-6">
                <h5 className="form-content__label">Start</h5>
                <div className="form-item">
                
                  <p className="formLabel"></p>
                  <input
                    type="datetime-local"
										name="startDate"
										id="startDate"
                    className="form-style"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div className="col-6">
                <h5 className="form-content__label">Stop</h5>
                <div className="form-item">
                  <p className="formLabel"></p>
                  <input
                    type="datetime-local"
										name="endDate"
										id="endDate"
                    className="form-style"
                    autocomplete="off"
                  />
                </div>
              </div>

            </div>
            <div className="row resp-column">
              <div className="col-6">
                <h5 className="form-content__label">Event Location</h5>
                <div className="form-item">
                  <p className="formLabel">Enter event venue</p>
                  <input
                    type="text"
										name="venue"
										id="venue"
                    className="form-style"
                    autocomplete="off"
                  />
                </div>
                <div className="form-item">
									<p className="formLabel">Featured</p>
									<input
										type="number"
										name="featured"
										id="featured"
										className="form-style"
										autocomplete="off"
										
									/>
								</div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-item">
                      <p className="formLabel">City</p>
                      <input
                        type="text"
												name="city"
												id="city"
                        className="form-style"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-item">
                      <p className="formLabel">State</p>
                      <input
                        type="text"
												name="state"
												id="state"
                        className="form-style"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-item">
									<p className="formLabel">Commission</p>
									<input
										type="text"
										name="commission"
										id="commission"
										className="form-style"
										autocomplete="off"
										
									/>
								</div>
                <div className="form-item">
									<p className="formLabel">Age Restriction</p>
									<input
										type="text"
										name="ageRestriction"
										id="ageRestriction"
										className="form-style"
										autocomplete="off"
										onChange={this.onEnter}
									/>
								</div>
              </div>
              <div className="col-6">
                <img
                  className="event-direction"
                  src="/image/directions.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        	<div className="col-12 form-container">
					<div className="form-content" id="form">
						<h3 className="form-content__title">Event Image</h3>
						<div className="row event-image__upload image-column">
							<div className="col-md-4 create-upload create-upload__left">
							
								<label className="label-column" for="file">
									<input type="file" name="banner" id="banner" className="file" accept="image/*" 
									onChange={this.handleBannerChange}
									//onChange={this.onEnter}
									/>
									<img className="upload-icon" src={this.state.banner} id="" alt="" />
									Add Banner Image
									<span>(350 X 420px 2mb Please avoid including text on image)</span>
								</label>
							</div>
							<div className="col-md-1" />
							<div className="col-md-7 create-upload create-upload__right">
							

  <label className="label-column" for="file">
                <input
                  type="file"
                  name="coverImage"
                  id="coverImage"
									className="file"
									accept="image/*"
									onChange={this.handleCoverChange}
									//onChange={this.onEnter}
									
                />
                <img
                  className="upload-icon"
                  src={this.state.coverImage}
									alt=""
									
                />
                
                  Add Cover Image
                
                <span>
                  (2600 X 1030px 5mb Please avoid including text on image)
                </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        
        <div className="nice-job">
          
          <button className="btn btn-success btn-bottom">SAVE YOUR EVENT</button>
        </div>
        </form>
</div>
      </div>
      <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  login:state.login, 
  register:state.register, 
  updateEvent: state.updateEvent,
});
const mapDispatchToProps = (dispatch) => ({
  onUpdateEvent: bindActionCreators (onUpdateEvent, dispatch),
});

export default connect (mapStateToProps, mapDispatchToProps) (EventBasicInfo);