import React, {Component} from 'react';
import '../../App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getSimilarEvents } from '../../store/actions/getSimilarEvents';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import { format } from 'date-fns';
import getMonth from 'date-fns/get_month';
import Truncate from 'react-truncate';
import moment from 'moment';

class SimEvents extends Component {
//   componentDidMount() {
//     const eventID  = this.props.match.params.id
//     console.log(eventID)

// //console.log(keyword)
//     this.props.getSimilarEvents(eventID)
//   }
  render () {
    const options = {
      responsive: {
        1000: {
          items: 4,
        },

        600: {
          items: 3,
        },
        0: {
          items: 1,
        },
      },
    };
    const {similarEvents:{response: similarEventsResponse} } = this.props;
    return (
      <div className="container-fluid">
      <div id="additionalInfo">
        <h4 className="page-title">Similar Events</h4>
        <OwlCarousel
          className="owl-theme"
          loop={true}
          margin={40}
          nav={true}
          items={4}
          dots={false}
          //autoplay={true}
          autoplayTimeout={7000}
          responsiveClass={true}
          {...options}
        >

          
{similarEventsResponse.map((value, key) =>
  <div className="card event-card  " key={value._id}>
    {/* var prevMonthFirstDay = (new moment(new Date(value.date)).format('YYYY-MM-DD HH:mm:ss')); */}
            <div className="card-image-container">
              <img
                className="card-image"
                src={value.coverImage}
                alt="Events in Nigeria"
              />
              <div className="details-section">
                <div className="event-date"> <span className="event-date__day">
          {new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')).getDate()}{' '}
												</span>
												<span className="event-month">
            {format(new Date((new moment(new Date(value.mongodate)).format('YYYY-MM-DD HH:mm:ss')).replace(/\s+/g, 'T')), 'MMM')}
												</span>{' '}
												{' '} </div>
                <div className="other-info">
                  <div className="event-details">
                  <Link className='event-name' to={`/event/${value._id}`}>
														<Truncate
															lines={1}
															width={220}
															ellipsis={<span className="event-title">...</span>}
														>
															{value.name}
														</Truncate>
													</Link>
                    <div className="clearfix mt-2">
                      <p className="d-inline float-left mr-2 event-icon">
                        {' '}
                        <img
                          src="/image/locations.svg"
                          alt="event-location"
                          height="15"
                        />
                      </p>
                      <p className="d-inline"> {value.state}</p>
                    </div>
                    <div className="clearfix">
                      <p className="d-inline float-left mr-2 event-icon">
                        {' '}
                        <img
                          src="/image/price-tag-white.svg"
                          alt="event-location"
                          height="15"
                        />
                      </p>
                      <p className="d-inline">
                        {' '}Starting from <span className="price">{Number(value.starting_from).toLocaleString(`en-${(value.currency || 'NGN').substring(0, 2)}`, {
                        style: 'currency',
                        currency: (value.currency || 'NGN'),
                        maximumFractionDigits: 2
                      })} </span>
                      </p>
                    </div>

                   <Link to={`/event/${value._id}`} className="btn btn-ticket mb-3">
														Buy Ticket
													</Link>

                  </div>
                </div>
              </div>

            </div>

          </div>
)}
        </OwlCarousel>

      </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
	similarEvents: state.similarEvents
});

const mapDispatchToProps = (dispatch) => ({
	getSimilarEvents: bindActionCreators(getSimilarEvents, dispatch)
});

//export default connect (mapStateToProps, mapDispatchToProps)
export default SimEvents;