
const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  isLoggedIn: false,
  response: [],
  error: {}
};

const register = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "REGISTER_PENDING": {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }
    case "REGISTER_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        isLoggedIn: true,
        response: action.payload.data
      };
    }
    case "REGISTER_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    }
    case "LOGOUT2": {
      return {
        ...state,
        ...INITIAL_STATE
      }
    }
    default: {
      return {
        ...state
      }
    };
  }
};

export default register;