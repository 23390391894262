import axios from 'axios';
import $ from 'jquery';
import env from '../../env.js';

const verify = (url,SECKEY,txref) => ({
  type: 'VERIFY',
  payload:axios.post(url, {
    SECKEY,txref
  })
});

export const onVerify = (SECKEY,txref) => async (dispatch) => {
  const url = `${env(true)}verify`
  dispatch(verify(url,SECKEY,txref))
}

