import React, { Component } from 'react';
import Ticket from './ticket';
//import '../../styles/bootstrap.css';
import '../../styles/responsive.css';
import { connect } from 'react-redux';
import { onGetOneEvent } from '../../store/actions/getOneEvent';
import { bindActionCreators } from 'redux';
import { onLogin } from '../../store/actions/login';
import isEqual from 'lodash/isEqual';
import Payment from '../payment';
import { format } from 'date-fns';
import { withRouter } from "react-router";
import axios from 'axios';

class Asis extends Component {
	constructor(props) {
		super(props);
		this.state = {value: 'coconut',
		total: 0,
		subTotal: 0,
		serviceCharge: 0,
		classOrders: {},
		email: '', // customer email
		amount: 0, // equals NGN 1000. Minimum amount allowed NGN 1 while on production or live system, it's 10
		phone: '',
		firstname: '',
		address: '',
		eventID: '',
		eventName: '',
		gender: '',
		twitter: '',
		membership_id: '',
		membership: '',
		age: '',
		checked: false,
        isChecked: false,
        dis: false,
		instagram: '',
		day: '',
		eventID: ''
	};
	
		this.handleChange = this.handleChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
	  }
	// state = {
		
	// };

	checkEmail (email) {
		if (email == "") return false;
		var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
		if (regex.test(email)) return true;
		else return false;
	}

	handleChange(event) {
		
		this.setState({membership: event.target.value});
		// console.log(this.state.gender)
	  }

	  handleDay =(event) => {
		
		this.setState({day: event.target.value});
		// console.log(this.state.day)
	  }

	  handleCheck = () => {
		// this.setState({checked: true});
		// const target = event.target;
		// this.state.checked = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			isChecked: !this.state.isChecked,
		  });
		// console.log(value)
	  }
ticketDisplay(classOrders) {
	 let ma = ''
	Object.keys(classOrders).forEach(key => {
			
		if (classOrders[key] == 0){
		console.log("no id")
		}else{
			ma = classOrders[key] 
			
}
	})
console.log(ma)
 
// 	// for(var i = 0; i < ma; i++){
// 	// 	return (
// 	// 	<button >
// 	// 	  Save
// 	// 	</button>
// 	// );
// 	// }
// 	if(ma <= 1){
// 		return ret;
// 	}
	

 }

 doRepeat(ma, compnt){
	var ret = [compnt];
	console.log('here')
	console.log(ma)
	console.log(ret)
	if(ma <= 1){
		console.log('mine here')
		return ret;
	}
	 return ret.concat(this.doRepeat(ma-1, compnt))
 }

 repeatThis(ma,classOrders, compnt){
	 var ret = [compnt];
	 //console.log(classOrders)
	 Object.keys(classOrders).forEach(key => {
			
		if (classOrders[key] == 0){
		console.log("no id")
		}else{
			ma = classOrders[key] 
		if(ma <= 1){
		return ret;
	}
	return ret.concat(this.repeatThis(ma-1,0, compnt))
		
}
	})
	if(ma <= 1){
		return ret;
	}
	return ret.concat(this.repeatThis(ma-1,0, compnt))
	 
 }
// renderButton() {
//     if(this.state.mode === 'view') {
//       return (
//           <button onClick={this.handleEdit}>
//             Edit
//           </button>
//       );
//     } else {
//       return (
//           <button onClick={this.handleSave}>
//             Save
//           </button>
//       );
//     }
// }

pay(){
	const { event, classes, oneEvent, user } = this.props;
	const { subTotal, total, serviceCharge, sTotal } = this.state;
	
	// console.log(classes);
	const yo = event
	
	// console.log(this.state.eventID);
	const { email, firstname, phone, address, eventID, gender,day, age,membership_id, membership,eventName } = this.state;
	if (this.state.eventID == "5c45085e8ead0e3d278b4743" || this.state.eventID == "5c096bf58ead0e4c2c8b45b2"){
	return (
<div>

			
                
                <button
                                            class="payButton btn btn-white-main"
                                            
											// data-toggle="modal"
											// data-target="#buyTicketModal"
											//  onClick={this.payFree(	
                                            //      {subTotal},
                                            //     	{firstname},
                                            //     	{phone},
                                            //     	{email},
                                            //     	{gender},
                                            //     	{age},
                                            //     	{membership_id},
                                                
                                                	
                                            //     	{eventName},
                                            //     	{eventID},
                                            //          {classes})
                                            //         }
										>
											Make Payment
										</button>
				
</div>
	)
				}else if (this.state.eventID == "5c390b5f8ead0ea3678b45c"){
					return (
						<div>
						{(this.state.phone != 0 && isNaN(phone) == false && this.state.email !=0 && this.checkEmail(this.state.email) && this.state.firstname != 0  && this.state.day != 0)  ? ( 
											<Payment
											amount={subTotal}
											firstname={firstname}
											phone={phone}
											email={email}
											
											day = {day}
											eventID = {eventID}
											order = {this.props.classOrders}
											history ={this.props.history}
											eventDetails = {eventName}
											classDetails = {classes}
										/>
										) : (
											<button
																	class="payButton btn btn-white-main"
																	// data-toggle="modal"
																	// data-target="#buyTicketModal"
																	 onClick={this.showError}
																>
																	Make Payment
																</button>
										)}
						</div>
							)	
				}
				else{
					return (
						<div>
						{(this.state.phone && isNaN(phone) == false != 0 && this.state.email !=0 && this.checkEmail(this.state.email) && this.state.firstname != 0)  ? ( 
											<Payment
											amount={subTotal}
											firstname={firstname}
											phone={phone}
											email={email}
											// gender={gender}
											// age = {age}
											// twitter = {twitter}
											// instagram = {instagram}
											// address = {address}
											eventID = {eventID}
											order = {this.props.classOrders}
											history ={this.props.history}
											eventDetails = {eventName}
											classDetails = {classes}
										/>
										) : (
											<button
																	class="payButton btn btn-white-main"
																	// data-toggle="modal"
																	// data-target="#buyTicketModal"
																	 onClick={this.showError}
																>
																	Make Payment
																</button>
										)}
						</div>
							)
				}
}
	myFunction(yo){
		const { oneEvent: { response: { event, classes } }, oneEvent, user } = this.props;
		// console.log(classes)
		for (var prop in event) {
			//if (yo.hasOwnProperty(prop)) { 
			// or if (Object.prototype.hasOwnProperty.call(obj,prop)) for safety...
			  console.log( event[prop]._id)
			//   this.setState({ eventID: yo[prop]._id });
			this.state.eventID = event[prop]._id
			this.state.eventName = event[prop].name
			
			//}
		  }

		  if (this.state.eventID == "5c45085e8ead0e3d278b4743" || this.state.eventID == "5c096bf58ead0e4c2c8b45b2"){
return (
	<div id="form">
		

<div class="form-item">
{/* <p class="formLabel">Twitter handle</p> */}
<input
	type="text"
	name="membership_id"
	id="membership_id"
	class="form-style"
	autoComplete="off"
	placeholder= "Membership ID"
	value={this.state.membership_id}
	onChange={this.onEnter}
	required
/>
</div>


{/* <label>
       
Member Type:  </label>
 <select className="form-control form-control-sm" 
		  name="membership"
		  required 
		  placeholder= "Select Membership Type"
		  value={this.state.membership}
		   onChange={this.handleChange}>
		    <option value="">Select Membership Type </option>
            <option value="ASIS Members">ASIS Members</option>
            
			<option value="Non-Members">Non-Members</option> 
			<option value="Students">Students</option> 
          </select> */}
</div>
)
		  }else if (this.state.eventID == "5c390b5f8ead0ea3678b45c"){
			return (
				<div id="form">
			<label>
				   
			Select Date:  </label>
			 <select className="form-control form-control-sm" 
					  name="day"
					  required 
					  placeholder= "Select Date"
					  value={this.state.day}
					  onClick={this.handleDay}
					   onChange={this.handleDay}>
					   
						<option value="">Select Date </option>
						<option value="03/09/2019 10:00 AM">03/09/2019 10:00 AM</option>
						<option value="04/09/2019 10:00 AM">04/09/2019 10:00 AM</option>
						{/* <option value="23/12/2018 2:00 PM">23/12/18 2:00 PM</option>
						<option value="30/12/2018 2:00 PM">30/12/18 2:00 PM</option> */}
					  </select>
			</div>
			)
		  }
		
	}

	showError = () => {
		alert("All fields are required....Please Check that all fields are filled correctly")
		  };
		  payFree = (amount,firstname,phone,email,gender,age,membership_id,membership,address,eventName,eventID,classes,event) => {
			
				// event.preventDefault();
               
                this.state.dis = true
                if(membership_id != 0 && firstname != 0 && email != 0 && phone != 0){
				 var apiBaseUrl = "http://localhost:4000/api/";
				var self = this;
				var payload={
					amount : amount.subTotal,
						firstname: firstname.firstname,
						phone:phone.phone,
						email:email.email,
						gender:gender.gender,
						age : age.age,
						membership_id : membership_id.membership_id,
						eventDetails : eventName,
						eventID : eventID,
						classDetails : classes
                }
               // console.log(payload)
				axios.post(apiBaseUrl+'issueEventFree', payload)
				.then(function (response) {
			   // console.log(response);
				if(response.data.status === 404){
				console.log("Transaction not found");
				alert(response.data.message)
				
				}
				else if(response.data.status === 200){
				
				alert(response.data.message)
				}
				
				})
				.catch(function (error) {
				console.log(error);
				});
			
            }	
		  }
	componentDidMount() {
		// this.props.onGetOneEvent();
		this.setState({ classOrders: this.props.classOrders });
		
	}

	componentWillReceiveProps(nextProps) {
		const { subTotal, total, serviceCharge } = this.state;
		const { oneEvent: { response: { event, classes } }, classOrders,mine } = this.props;
 //console.log(this.props)
		if (!isEqual(nextProps.classOrders, classOrders)) {
			// console.log('prps: ', nextProps.classOrders);
			let sTotal = 0;
			classes.forEach((dclass) => {
				const unitCost = Number(nextProps.classOrders[dclass._id]) * Number(dclass.price);
				sTotal = Number(sTotal) + Number(unitCost);
				// console.log('unitcost: ',  unitCost);
			});
			// console.log('subTotal: ', sTotal);

			if ( (1.4 / 100) * sTotal > 2000) {
				// serviceCharge:((1.4 / 100) * sTotal)
				this.setState({ serviceCharge: 2000, total: Number(sTotal + serviceCharge) });
			} else {
				this.setState({ serviceCharge: (1.4 / 100) * sTotal, total: sTotal + serviceCharge });
			}
			this.setState({ subTotal: sTotal });
		}
	}

	onEnter = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	
	

	render() {
		const { event, classes, oneEvent, user, classOrders } = this.props;
		const { subTotal, total, serviceCharge, sTotal } = this.state;
		
		console.log(classOrders, classOrders.toString(), "DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD");
		const yo = event
		
		// console.log(this.state.eventID);
		const { email, firstname, phone, address, eventID, gender, age,twitter, instagram } = this.state;
		// console.log(eventID);
		const { name, venue, date } = event ? event : {};
	//	let toDisplay = 
		//console.log(classes)
		
		return (
			<div className="buy-ticket-container">
				<div
					class="modal fade"
					id="asis"
					tabindex="-1"
					role="dialog"
					aria-labelledby=" buyTicketModalCenterTitle"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								<div>
									<h5 class="modal-title" id="buyTicketModalLongTitle">
										{name}
									</h5>
								</div>
								<div class="address">
									<p>
										<span>{venue}</span>. <span>{date}</span>
									</p>
								</div>
								<div>
									<p class="ticket-info">TICKET INFORMATION </p>
								</div>
							</div>
							<div class="modal-body">
								<div class="row">
									<div class="col-sm-7 ticketInfo">
										<h6>Buyer Information</h6>

										{/* <p>
											Checkout as a guest or<span>
												<a href=""> Sign In with your account</a>{' '}
											</span>
										</p> */}

										<form>
											<div id="form">
												<div class="form-item">
													<p class="formLabel">Name</p>
													<input
														type="text"
														name="firstname"
														id="name"
														class="form-style"
														autoComplete="off"
														value={firstname}
														onChange={this.onEnter}
														required
													/>
												</div>

												<div class="form-item">
													<p class="formLabel">Email</p>
													<input
														type="email"
														name="email"
														id="email"
														class="form-style"
														autoComplete="off"
														value={email}
														onChange={this.onEnter}
														required
													/>
												</div>

												<div class="form-item">
													<p class="formLabel">Phone Number</p>
													<input
														type="text"
														name="phone"
														id="pnumber"
														class="form-style"
														autoComplete="off"
														value={phone}
														onChange={this.onEnter}
														required
													/>
												</div>
												{this.myFunction(yo)}
												{/* {yo ? yo : 
												 yo.map((value,key)=>
													<div class="form-item">
														<p class="formLabel">Address</p>
														<input
															type="text"
															name="address"
															id="address"
															class="form-style"
															autoComplete="off"
															value={value._id}
															onChange={this.onEnter}
															required
														/>
													</div>
													)
												} */}
												
											</div>
										</form>
										
												
												
												<div class="mt-5">
											{/* <h6>Ticket Information</h6> */}
											{/* {this.ticketDisplay(classOrders) } */}
												{/* <div class="ticketInfoSection mt-3" > */}
												{/* {this.props.classOrders[dclass._id]} x{' '}
																	{dclass.class} */}
																	
											{/* <h5 class="ticketTypeHeader"> </h5> 
											{this.repeatThis(0,classOrders, <form class="ticketForm">
												<div id="form">
													<div class="form-item">
														<p class="formLabel">Name</p>
														<input
															type="text"
															name="name"
															id="name"
															class="form-style"
															autocomplete="off"
														/>
													</div>

													<div class="form-item">
														<p class="formLabel">Phone</p>
														<input
															type="text"
															name="name"
															id="name"
															class="form-style"
															autocomplete="off"
														/>
													</div>

													<div class="form-item">
														<p class="formLabel">Email</p>
														<input
															type="email"
															name="email"
															id="email"
															class="form-style"
															autocomplete="off"
														/>
													</div> 

											<div class="form-item">
														<button class="btn btn-ticket save">Save Ticket</button>
													</div> 
											</div>
										 </form> 
												)} */}
											 

											 {/* </div> */}
												

											{/* <div class="ticketInfoSection mt-3">
												<h5 class="ticketTypeHeader">2. VIP Ticket</h5>
												<form class="ticketForm">
													<div id="form">
														<div class="form-item">
															<p class="formLabel">Name</p>
															<input
																type="text"
																name="name"
																id="name"
																class="form-style"
																autocomplete="off"
															/>
														</div>

														<div class="form-item">
															<p class="formLabel">Email</p>
															<input
																type="email"
																name="email"
																id="email"
																class="form-style"
																autocomplete="off"
															/>
														</div>

														<div class="form-item">
															<button class="btn btn-ticket save">Save Ticket</button>
														</div>
													</div>
												</form>
											</div>
										</div>
										<div class="mt-4">
											{/* <button
												class="btn btn-buy mr-2"
												data-toggle="modal"
												data-target="#downloadTicketModal"
											>
												Pay with Flutterwave
											</button> */}
											{this.pay()}
											
											{/* <button class="btn btn-save">Pay with payarena</button> */}

											<p class="mt-4 ticketTerm">
												I accept the terms and conditions for signing up to this service, and hereby 
												confirm I have read the privacy policy.
											</p>
										</div>
												
													
												
										
										
									</div>
									<div class="col-sm-5 ticketCard">
										<div class="ticketInfo">
											<h6>Ticket Order</h6>
											{classes &&
												classes.map((dclass) => {
													// console.log(this.props.classOrders);

													let unitCost =
														this.props.classOrders[dclass._id] * Number(dclass.price);

													//console.log(this.props.classOrders[dclass._id]);
													// console.log("here" + dclass.dclass)

													return (
														<div class="border-top border-bottom pt-3 pb-3 ticketInfo">
															<div class="row pb-2 pt-2 ">
																<div class="col col-sm-6">
																	{this.props.classOrders[dclass._id]} x{' '}
																	{dclass.class}
																	
																</div>
																<div class="col col-sm-6 ticketPrice">
																	{' '}
																	₦{' '}
																	{new Intl.NumberFormat('en-GB', {
																		minimumFractionDigits: 0,
																		maximumFractionDigits: 0
																	}).format(unitCost)}
																</div>
															</div>
														</div>
													);
												})}

											<div class="border-bottom pt-3 pb-3 ticketInfo">
												<div class="row pb-2 pt-2 ">
													<div class="col col-sm-6">Sub-total</div>
													<div class="col col-sm-6 ticketPrice">
														{' '}
														₦{' '}
														{new Intl.NumberFormat('en-GB', {
															minimumFractionDigits: 0,
															maximumFractionDigits: 0
														}).format(subTotal)}
													</div>
												</div>

												<div class="row pb-2">
													<div class="col col-sm-6">
														Service Charge <span>(online payment convenience fee)</span>
													</div>
													<div class="col col-sm-6 ticketPrice">
														{' '}
														₦{' '}
														{new Intl.NumberFormat('en-GB', {
															minimumFractionDigits: 0,
															maximumFractionDigits: 0
														}).format(serviceCharge)}
													</div>
												</div>
											</div>
											<div class="row pb-2 pt-2 ">
												<div class="col col-sm-6">Total</div>
												<div class="col col-sm-6 ticketPrice">
													{' '}
													₦{' '}
													{new Intl.NumberFormat('en-GB', {
														minimumFractionDigits: 0,
														maximumFractionDigits: 0
													}).format(subTotal + serviceCharge)}
												</div>
											</div>
											<div class="border mt-3 p-3 mb-3 b-radius">
												<div class="d-inline-block mr-4 ">
													<img src="/image/visa.png" alt="Events in Lagos" />
												</div>
												<div class="d-inline-block">
													<img src="/image/master.png" alt="Events in Lagos" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					{/* <Ticket /> */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	oneEvent: state.oneEvent,
	user: state.login.response.user
});

const mapDispatchToProps = (dispatch) => ({
	onGetOneEvent: bindActionCreators(onGetOneEvent, dispatch),
	onLogin: bindActionCreators(onLogin, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Asis));
