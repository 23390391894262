import React, { Component } from 'react';
import $ from 'jquery';
import randomString from 'randomstring';
import axios from 'axios';
//import '../../App.css';
import '../../styles/createevent.css';
import '../../styles/formtype.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onCreateMyEvent } from "../../store/actions/createEvent";
import { Link } from 'react-router-dom';
// import { onLogin } from '../../store/actions/login';
import SweetAlert from 'react-bootstrap-sweetalert';

import swal from 'sweetalert';

import PaidTicketEvent from './PaidTicketEvent';
import FreeTicketEvent from './FreeTicketEvent';

class CreateEvents extends Component {
  //url = "https://nairabox-event-dashboard-api.herokuapp.com/api/events/upload";
  url = 'https://www.nairabox.net/api/events/uploadImage';
  //url = 'http://localhost:4000/api/events/uploadImage';

  constructor(props) {
    super(props);
    console.log(this.props)
    this.state = {
      paidTicket: [],
      freeTicket: [],
      allTickets: [],
      ticketsCount: 0,
      showTickets: false,
      bannerImage: "",
      artwork: "",
      coverImage: "",
      eventname: "",
      merchant: props.user ? props.user.firstname : props.userReg.firstname,
      ageRestriction: "",
      commission: "",
      venue: "",
      category: "",
      tags: "",
      eventType: "public",
      referralCode: "",
      startDate: "",
      endDate: "",
      city: "",
      state: "",
      uid: props.user ? props.user.uid : props.userReg.uid,
      featured: "",
      summary: "",
      isChecked: false,
      submitButtonText: "SUBMIT YOUR EVENT FOR REVIEW"
    };
    this.handleBannerChange = this.handleBannerChange.bind(this);
    this.handleCoverImageChange = this.handleCoverImageChange.bind(this);
    this.handleMobileImageChange = this.handleMobileImageChange.bind(this);
    this.onChangeEventType = this.onChangeEventType.bind(this);
  }

  onShowTicket = ({ type }) => {
    let { ticketsCount, allTickets } = this.state;

    if (type === "paid") {
      allTickets.push({
        key: randomString.generate({ length: 8 }),
        isPaid: true
      });

      this.setState({
        ticketsCount: ticketsCount + 1,
        showTickets: true,
        allTickets
      });
    } else if (type === "free") {
      allTickets.push({
        key: randomString.generate({ length: 8 }),
        isPaid: false
      });

      this.setState({
        ticketsCount: ticketsCount + 1,
        showTickets: true,
        allTickets
      });
    }
  }; //end onShowTicket

  onDeleteTicket = ({ index }) => {
    let { allTickets } = this.state;
    allTickets.splice(index, 1);
    this.setState({ allTickets });
  };

  handleCheck = () => {
    // this.setState({checked: true});
    // const target = event.target;
    // this.state.checked = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      isChecked: !this.state.isChecked,
    });
    // console.log(value)
  }


  handleBannerChange = async event => {
    const image = event.target.files[0];
    this.setState({ bannerImage: URL.createObjectURL(event.target.files[0]) });


    const data = new FormData()
    data.append('file', image);
    data.append('upload_preset', 'nwujbo2u')
    let res
    try {
      res = await axios.post('https://api.cloudinary.com/v1_1/dwa8k0pds/image/upload', data)
      console.log('res: ', res.data.url);
      this.setState({
        bannerImage: res.data.url
      });
    } catch (error) {
      console.log('error uploading: ', error);
    }
  };


  handleCoverImageChange = async event => {
    const image = event.target.files[0];
    this.setState({ coverImage: URL.createObjectURL(event.target.files[0]) });

    const data = new FormData()
    data.append('file', image);
    data.append('upload_preset', 'nwujbo2u')
    let res
    try {
      res = await axios.post('https://api.cloudinary.com/v1_1/dwa8k0pds/image/upload', data)
      console.log('res: ', res.data.url);
      this.setState({
        coverImage: res.data.url
      });
    } catch (error) {
      console.log('error uploading: ', error);
    }
  };

  handleMobileImageChange = async event => {
    const image = event.target.files[0];
    this.setState({ artwork: URL.createObjectURL(event.target.files[0]) });


    const data = new FormData()
    data.append('file', image);
    data.append('upload_preset', 'nwujbo2u')
    let res
    try {
      res = await axios.post('https://api.cloudinary.com/v1_1/dwa8k0pds/image/upload', data)
      console.log('res: ', res.data.url);
      this.setState({
        artwork: res.data.url
      });
    } catch (error) {
      console.log('error uploading: ', error);
    }
  };

  handleMobileBannerChange = event => {
    const file = event.target.files[0];
    this.setState({ mBanner: event.target.files[0] });
  };
  handleMobileCoverChange = event => {
    const file = event.target.files[0];
    this.setState({ mCover: event.target.files[0] });
  };
  // onDeletePaidEvent = ({ type, index }) => {
  //   let { paidTicket } = this.state;
  //   if (type === "paidTicket") {
  //     paidTicket.splice(index, 1);
  //     this.setState({ paidTicket });
  //   }
  // };

  // This method is called from PaidTicketEvents and FreeTicketEvents components.
  // Used in setting states of their respective inputs
  onEnterMyEventDetails = ({ type, index, payload }) => {
    let { paidTicket, freeTicket } = this.state;

    let fieldName = Object.keys(payload)[0];
    let fieldValue = Object.values(payload)[0];

    if (type === "paidTicket") {
      let new_item = {
        uid: index,
        [fieldName]: fieldValue
      };

      // Check if paidTicket array already has an object with this uid. If yes, push to it
      const indexOfItemInArray = paidTicket.findIndex(
        ticket => ticket.uid === index
      );
      if (indexOfItemInArray > -1) {
        let newObject = { ...paidTicket[indexOfItemInArray], ...new_item };
        paidTicket.splice(indexOfItemInArray, 1, newObject);
      } else {
        paidTicket.push(new_item);
      }

      this.setState({
        paidTicket
      });
    } else if (type === "freeTicket") {
      let new_item = {
        uid: index,
        [fieldName]: fieldValue
      };

      // Check if paidTicket array already has an object with this uid. If you, push to it
      const indexOfItemInArray = freeTicket.findIndex(
        ticket => ticket.uid === index
      );
      if (indexOfItemInArray > -1) {
        freeTicket.splice(indexOfItemInArray, 1, new_item);
      } else {
        freeTicket.push(new_item);
      }

      this.setState({
        freeTicket
      });
    }
  };

  onEnter = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };


  onChangeEventType = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    this.setState({
      eventType: value
    })
  }


  onChangeTicketStatus = ({ e, index, type }) => {
    const { name, value } = e.target;
    let { paidTicket, freeTicket } = this.state;

    let fieldName = name;
    let fieldValue = value;

    if (type === "paid") {
      let new_item = {
        uid: index,
        [fieldName]: fieldValue
      };

      // Check if paidTicket array already has an object with this uid. If you, push to it
      const indexOfItemInArray = paidTicket.findIndex(
        ticket => ticket.uid === index
      );
      if (indexOfItemInArray > -1) {
        let newObject = { ...paidTicket[indexOfItemInArray], ...new_item };
        paidTicket.splice(indexOfItemInArray, 1, newObject);
      } else {
        paidTicket.push(new_item);
      }

      this.setState({
        paidTicket
      });
    } else if (type === "free") {
      let new_item = {
        uid: index,
        [fieldName]: fieldValue
      };

      // Check if paidTicket array already has an object with this uid. If you, push to it
      const indexOfItemInArray = freeTicket.findIndex(
        ticket => ticket.uid === index
      );
      if (indexOfItemInArray > -1) {
        freeTicket.splice(indexOfItemInArray, 1, new_item);
      } else {
        freeTicket.push(new_item);
      }

      this.setState({
        freeTicket
      });
    }
  };


  onChangeTicketSalesChannel = ({ e, index, type }) => {
    const { name, value } = e.target;
    let { paidTicket, freeTicket } = this.state;

    let fieldName = name;
    let fieldValue = value;

    if (type === "paid") {
      let new_item = {
        uid: index,
        [fieldName]: fieldValue
      };

      // Check if paidTicket array already has an object with this uid. If you, push to it
      const indexOfItemInArray = paidTicket.findIndex(
        ticket => ticket.uid === index
      );
      if (indexOfItemInArray > -1) {
        let newObject = { ...paidTicket[indexOfItemInArray], ...new_item };
        paidTicket.splice(indexOfItemInArray, 1, newObject);
      } else {
        paidTicket.push(new_item);
      }

      this.setState({
        paidTicket
      });
    } else if (type === "free") {
      let new_item = {
        uid: index,
        [fieldName]: fieldValue
      };

      // Check if paidTicket array already has an object with this uid. If you, push to it
      const indexOfItemInArray = freeTicket.findIndex(
        ticket => ticket.uid === index
      );
      if (indexOfItemInArray > -1) {
        freeTicket.splice(indexOfItemInArray, 1, new_item);
      } else {
        freeTicket.push(new_item);
      }

      this.setState({
        freeTicket
      });
    }
  };



  convertToISODate = normalDate => new Date(normalDate).toISOString();

  onSubmit = e => {
    e.preventDefault();
    if (this.state.isChecked === false) {
      alert("Agree to terms and condition before you can proceed")
    } else {
      this.setState({
        submitButtonText: "SUBMITTING..."
      });

      const {
        paidTicket,
        freeTicket,
        bannerImage,
        artwork,
        coverImage,
        eventname,
        ageRestriction,
        commission,
        venue,
        category,
        tags,
        eventType,
        referralCode,
        startDate,
        endDate,
        city,
        state,
        featured,
        summary,
        uid
      } = this.state;

      // const {
      //   user: { phone }
      // } = this.props;

      // const {
      //   user: { firstname },
      //   user
      // } = this.props;

      let merchant = this.props.user ? this.props.user.firstname : this.props.userReg.firstname;
      // let uid = phone;

      console.log(this.props);

      const eventData = {
        merchant,
        uid,
        paidTicket,
        freeTicket,
        bannerImage,
        artwork,
        coverImage,
        eventname,
        ageRestriction,
        venue,
        category,
        tags,
        eventType,
        referralCode,
        startDate,
        endDate,
        city,
        state,
        summary,
        featured: 0,
        commission: 10
      };

      // console.log('STATE', this.state);

      this.props.onCreateMyEvent(eventData);
    }
  };

  componentWillMount = () => {
    console.log("I'm about to mount");
  };

  onSummary = (e) => {
    console.log(e);
    this.setState({ summary: e.target.value });
  }

  componentDidMount = () => {
    console.log("I just mounted");
    this.setState({ uid: localStorage.getItem("uid") });
    //window.$("#summary").summernote();
    var formInputs = $(
      'input[type="email"], input[type="text"], input[type="password"], input[type="number"]'
    );
    formInputs.focus(function () {
      $(this)
        .parent()
        .children("p.formLabel")
        .addClass("formTop");
      $("div#formWrapper").addClass("darken-bg");
      $("div.logo").addClass("logo-active");
    });
    formInputs.focusout(function () {
      if ($.trim($(this).val()).length === 0) {
        $(this)
          .parent()
          .children("p.formLabel")
          .removeClass("formTop");
      }
      $("div#formWrapper").removeClass("darken-bg");
      $("div.logo").removeClass("logo-active");
    });
    $("p.formLabel").click(function () {
      $(this)
        .parent()
        .children(".form-style")
        .focus();
    });
  };

  render() {
    let confirmationMessage = "";

    const {
      fetched,
      fetching,
      response: { message }
    } = this.props.createEvent;
    if (fetched) {
      console.log(fetched, fetching, message);
      confirmationMessage = `${message}`;
      this.state.submitButtonText = "SUBMITTED..."
      localStorage.removeItem('state')
      swal("Event Created Succesfully!", "A confirmation email has been sent to your email", "success").then(() => {

        window.location.href = '/';
      });

    }

    const {
      paidTicket,
      freeTicket,
      bannerImage,
      artwork,
      coverImage,
      eventname,
      ageRestriction,
      commission,
      venue,
      category,
      tags,
      eventType,
      referralCode,
      startDate,
      endDate,
      city,
      state,
      featured,
      summary
    } = this.state;

    let allOurTickets;

    if (this.state.showTickets && this.state.ticketsCount > 0) {
      const allTickets = this.state.allTickets;
      allOurTickets = allTickets.map(ticket => {
        if (ticket.isPaid) {
          return (
            <div key={ticket.key}>
              <PaidTicketEvent
                key={ticket.key}
                collapseID={ticket.key}
                onDeleteTicket={this.onDeleteTicket}
                onEnterMyEventDetails={this.onEnterMyEventDetails}
                onChangeTicketStatus={this.onChangeTicketStatus}
                onChangeTicketSalesChannel={this.onChangeTicketSalesChannel}
              />
            </div>
          );
        } else if (!ticket.isPaid) {
          return (
            <div>
              <FreeTicketEvent
                key={ticket.key}
                collapseID={ticket.key}
                onDeleteTicket={this.onDeleteTicket}
                onEnterMyEventDetails={this.onEnterMyEventDetails}
                onChangeTicketStatus={this.onChangeTicketStatus}
                onChangeTicketSalesChannel={this.onChangeTicketSalesChannel}
              />
            </div>
          );
        }
      });
    }

    return (
      <div>
        <form
          onSubmit={this.onSubmit}
          encType="multipart/form-data"
          method="POST"
        >
          <div className="col-12 col-lg-9 form-container">
            <h4 className="event-header__create-event">Create an Event</h4>
            <div className="form-content bg__shadow" id="form">
              <h3 className="form-content__title">Basic Information</h3>
              <h5 className="form-content__label">Name of the event</h5>
              <small id="Helper" className="form-text text-muted pb-2">
                Make your event title short and snappy!{" "}
              </small>
              <div className="form-item">
                {/* <p className="formLabel">Give it a short and catchy title</p> */}
                <input
                  type="text"
                  name="eventname"
                  id="eventname"
                  className="form-style"
                  autoComplete="off"
                  onChange={this.onEnter}
                  required
                />
              </div>
              <div>
                <h5 className="form-content__label">Event description</h5>
                <small id="Helper" className="form-text text-muted pb-2">
                  Tell us what your event is about!{" "}
                </small>
                <textarea
                  className="create-event-desc"
                  name="summary"
                  id="summary"
                  onChange={this.onSummary}
                  value={this.state.summary}
                  required
                />
              </div>
              <div className="row resp-column">
                <div className="col-6 form-group">
                  <h5 className="form-content__label">Event Category</h5>
                  <div className="">
                    <small id="Helper" className="form-text text-muted pb-2">
                      This will help others find your event.{" "}
                    </small>
                    <select
                      id="category"
                      name="category"
                      className="form-control event-select"
                      onChange={this.onEnter}

                    >
                      <option className="">Select Category</option>
                      <option>Music</option>
                      <option>Comedy</option>
                      <option>Arts</option>
                      <option>Exhibition</option>
                      <option>Play</option>
                    </select>
                  </div>
                </div>

                <div className="col-6">
                  <h5 className="form-content__label">Tags</h5>
                  <small id="Helper" className="form-text text-muted pb-2">
                    Add tags and seperate by commas{" "}
                  </small>
                  <div className="form-item">
                    {/* <p className="formLabel">Add tags and seperate by commas</p> */}
                    <input
                      type="text"
                      name="tags"
                      id="tags"
                      className="form-style "
                      autoComplete="off"
                      onChange={this.onEnter}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row resp-column">
                <div className="col-6">
                  <h5 className="form-content__label">Who can see my event?</h5>
                  <small id="Helper" className="form-text text-muted pb-2">
                    Who can see this event?
                  </small>
                  <div className="event-button">
                    <div className="switch-field">
                      <input
                        type="radio"
                        id="switch_left"
                        name="eventType"
                        value="public"
                        defaultChecked
                        onChange={this.onChangeEventType}
                      />
                      <label htmlFor="switch_left">Public</label>

                      <input
                        type="radio"
                        id="switch_right"
                        name="eventType"
                        value="private"
                        onChange={this.onChangeEventType}
                      />
                      <label htmlFor="switch_right">Private</label>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <h5 className="form-content__label">Enter referal code</h5>
                  <small id="Helper" className="form-text text-muted pb-2">
                    Enter your referral code
                  </small>
                  <div className="form-item">
                    {/* <p className="formLabel">Enter code</p> */}
                    <input
                      type="text"
                      name="referralCode"
                      id="referralCode"
                      className="form-style"
                      autoComplete="off"
                      onChange={this.onEnter}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-9 form-container">
            <div className="form-content bg__shadow" id="form">
              <h3 className="form-content__title">When and Where</h3>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <h5 className="form-content__label">Start</h5>
                  <small id="Helper" className="form-text text-muted pb-2">
                    Event starts
                  </small>
                  <div className="form-item start">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            className="search-location"
                            src="/image/calender.svg"
                            alt=""
                            height="12"
                          />
                        </div>
                      </div>
                      <input
                        type="datetime-local"
                        name="startDate"
                        id="startDate"
                        className="form-control form-style"
                        autoComplete="off"
                        onChange={this.onEnter}
                        required
                        placeholder="12/31/2018 04:00 PM"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6">
                  <h5 className="form-content__label">Stop</h5>
                  <small id="Helper" className="form-text text-muted pb-2">
                    Event stops
                  </small>
                  <div className="form-item stop">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            className="search-location"
                            src="/image/calender.svg"
                            alt=""
                            height="12"
                          />
                        </div>
                      </div>
                      <input
                        type="datetime-local"
                        name="endDate"
                        id="endDate"
                        className="form-control form-style"
                        autoComplete="off"
                        onChange={this.onEnter}
                        required
                        placeholder="12/31/2018 07:00 AM"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 form-group">
                  <h5 className="form-content__label">Age Restriction</h5>
                  <div className="">
                    <select
                      id="ageRestriction"
                      name="ageRestriction"
                      className="form-control event-select"
                      onChange={this.onEnter}
                    >
                      <option>Select Age range</option>
                      <option>13 - 21</option>
                      <option>18+</option>
                    </select>
                  </div>
                </div>

              </div>

              <h5 className="form-content__label">Event Location</h5>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-item">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <p className="formLabel">Location Name</p> */}
                        <input
                          type="text"
                          name="venue"
                          id="venue"
                          className="form-style"
                          placeholder="Location Name"
                          autoComplete="off"
                          onChange={this.onEnter}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-item">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className="form-style"
                          autoComplete="off"
                          placeholder="City"
                          onChange={this.onEnter}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-item">
                        <input
                          type="text"
                          name="state"
                          id="state"
                          className="form-style"
                          placeholder="State"
                          autoComplete="off"
                          onChange={this.onEnter}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-item">
                        <input
                          type="text"
                          name="country"
                          id="country"
                          className="form-style"
                          placeholder="Country"
                          autoComplete="off"
                          onChange={this.onEnter}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">hi there</div> */}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-9 form-container">
            <div className="form-content bg__shadow" id="form">
              <h3 className="form-content__title">Event Image</h3>
              <div className="row event-image__upload image-column">
                <div className="col-md-12 create-upload create-upload__left">
                  <label className="label-column" htmlFor="file">
                    <input
                      type="file"
                      name="bannerImage"
                      id="bannerImage"
                      className="file"
                      //accept="image/*"
                      onChange={this.handleBannerChange}
                      //onChange={this.onEnter}
                      required
                    />
                    <img
                      className="upload-icon"
                      src={this.state.bannerImage}
                      id=""
                      alt=""
                    />
                    Add Event Banner Image
                    <span className="uploadDesc">
                      (1440px X 400px not larger than 5mb, Please avoid including text on image)
                    </span>
                  </label>
                </div>

                <div className="col-md-6 create-upload create-upload__left">
                  <label className="label-column" htmlFor="file">
                    <input
                      type="file"
                      name="artwork"
                      id="artwork"
                      className="file"
                      accept="image/*"
                      onChange={this.handleMobileImageChange}
                      //onChange={this.onEnter}
                      required
                    />
                    <img
                      className="upload-icon"
                      src={this.state.artwork}
                      id=""
                      alt=""
                    />
                    Add Mobile Image
                    <span className="uploadDesc">
                      (584px X 960px not larger than 5mb, Please avoid including text on image)
                    </span>
                  </label>
                </div>

                <div className="col-md-6 create-upload create-upload__right">
                  <label className="label-column" htmlFor="file">
                    <input
                      type="file"
                      name="coverImage"
                      id="coverImage"
                      className="file"
                      accept="image/*"
                      onChange={this.handleCoverImageChange}
                      //onChange={this.onEnter}
                      required
                    />
                    <img
                      className="upload-icon"
                      src={this.state.coverImage}
                      alt=""
                    />
                    Event Cover image
                    <span className="uploadDesc">
                      (292px  X  230px not larger than 2mb, Please avoid including text on image)
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9 form-container">
            <div className="form-content bg__shadow" id="form">
              <h3 className="form-content__title">Event ticket offers</h3>

              <div className="ticket-offers__body">
                <p className="ticket-offers__title">
                  What type of ticket would you like to start with?
                </p>
                <div className="btn-ticket-offers">
                  <a
                    className="btn btn-save__create-ticket"
                    onClick={() => this.onShowTicket({ type: "paid" })}
                  >
                    + Paid Ticket
                  </a>
                  <a
                    className="btn btn-save__create-ticket"
                    onClick={() => this.onShowTicket({ type: "free" })}
                  >
                    + Free Ticket
                  </a>
                </div>
              </div>

              {allOurTickets}
            </div>

            <div class="form-item">
              <label>
                <p class="mt-4 ticketTerm" style={{ fontSize: '20px' }}>
                  <input type="checkbox" name="checked" checked={this.state.isChecked} onChange={this.handleCheck} />
                  &nbsp;&nbsp;
                                      I accept the terms and conditions for using this service, and hereby
												confirm I have read the  <Link to={`/eventAgreement`} > Agreement.
			 </Link>
                </p>
              </label>
            </div>

            <div className="nice-job">
              <h2 className="nice-job-text">Nice job! You're almost done.</h2>

              {fetched ? confirmationMessage : ""}

              <button className="btn btn-ticket">
                {this.state.submitButtonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.response.user,
    userReg: state.register.response.user,
    createEvent: state.createEvent
  }
}

// function mapDispatchToProps(dispatch) {
// 	onCreateMyEvent: bindActionCreators(onCreateMyEvent, dispatch)
// };

export default connect(
  mapStateToProps,
  { onCreateMyEvent }
)(CreateEvents);
