import React, {Component} from 'react';
import $ from 'jquery';
//import '../../App.css';
import '../../styles/createevent.css';
import '../../styles/formtype.css';
//import tinymce from 'tinymce/tinymce';
import {Editor} from '@tinymce/tinymce-react';
//import 'tinymce/themes/modern/theme';
import PaidTicketInfo from '../createEvent/paidTicketInfo';
import FreeTicketInfo from '../createEvent/freeTicketInfo';
import Header from '../header';
import Footer from '../footer';
import { Link } from "react-router-dom";
import EventSideMenu from '../eventManager/sideMenu';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';
import {onUpdateEventClass} from '../../store/actions/updateEventClass';
import '../../styles/eventMobileMenu.css';

class EventClasses extends Component {
  state = {
    paidTicket: [],
    freeTicket: [],
    showPaid: false,
    showFree: false, 
  };
  onShowPaid = () => {
    let {paidTicket}=this.state;
    paidTicket=[...paidTicket,1]
    this.setState ({
      showPaid: true,
      paidTicket
    });
  };
  onDeletePaid=({index})=>{
    let {paidTicket}=this.state;
    paidTicket.splice(index,1)
    this.setState({paidTicket})
  }

  onShowFree = () => {
    let {freeTicket} = this.state;
    freeTicket=[...freeTicket,1]
    this.setState ({
      showFree: true,
      freeTicket
    });
  };
  onDeleteFree=({index})=>{
    let {freeTicket}=this.state;
    freeTicket.splice(index,1)
    this.setState({freeTicket})
  }
  
  
  componentDidMount(){
    this.props.onUpdateEventClass();
  }
  render () {
    const {paidTicket, freeTicket, showFree, showPaid} = this.state;
     const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div>
        {(isLogged || isLoggedIn) || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        <div className="row eventmanager-row">
          <div className="col-sm-3 ">
          <div className="sideBar">
            <div id="main-menu" className="list-group">
                <a href="#sub-menu" className="list-group-item " data-toggle="collapse" data-parent="#main-menu">Event Manager <span className="caret"></span></a>
                <div className="collapse list-group-level2 " id="sub-menu">
                    <Link to="/eventover" className="list-group-item " data-parent="#sub-menu">Overview</Link>
                    <Link to="/eventreport" className="list-group-item" data-parent="#sub-menu">Report</Link>
                    <Link to="/eventorders" className="list-group-item" data-parent="#sub-menu">Manage Attendance</Link>
                </div>
              
                <a  href="#sub-sub-menu" data-toggle="collapse" data-parent="#sub-menu" className="list-group-item active">Edit Information<span className="caret"></span></a>
                  <div className="collapse list-group-level" id="sub-sub-menu">
                        <Link to="/eventbasicinfo" href="#" className="list-group-item" data-parent="#sub-sub-menu">Edit Event</Link>
                        <Link to="/eventclasses" href="#" className="list-group-item s__active" data-parent="#sub-sub-menu">Edit Event Classes</Link>
                    </div>
                <Link to="/eventquestions" href="#" className="list-group-item ">Ticket Questons</Link>
                <Link to="" href="#" className="list-group-item">Referral Code</Link>
                <Link to="/eventpromote" href="#" className="list-group-item" >Promote Event <span className="caret"></span></Link>
                 <Link to="/eventsettings" href="#" className="list-group-item">Additional Settings</Link>
                
            </div>
          </div>
          </div>
          <div className="col-sm-9 eventmanager-container">
        
       

        <h4 className="event-header__create-event">Edit event classes</h4>

<p>
  <a class="btn btn-save__create-ticket" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    Regular Ticket
  </a>
  <button class="btn btn-save__create-ticket" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    VIP Ticket
  </button>
</p>
<div class="collapse" id="collapseExample">
  <div class="card card-body edit-card-ticket">
  <form>
        <div className="col-12 form-container">
          <div className="" id="form">
            <h3 className="form-content__title">Event ticket offers</h3>

           
              <div className="btn-ticket-offers">
              <div className="card card-body ">
        <div className="row">
          <div className="col-lg-5">
            <p>Ticket:</p>
            <div className="form-item">
              {/*<p className="formLabel">Early Bird, Regular, VIP</p>*/}
              <input
                type="text"
                name="eventClassName"
                id="eventClassName"
                className="form-style"
                autocomplete="off"
                placeholder="Early Bird, Regular, VIP"
                onChange={this.onEnter}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <p>Maximum Quantity:</p>
            <div className="form-item">
              {/*<p className="formLabel">200</p>*/}
              <input
                type="number"
                name="maxQuantity"
                id="maxQuantity"
                class="form-style"
                autocomplete="off"
                placeholder="200"
                onChange={this.onEnter}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <p>Price:</p>
            <div className="form-item">
              {/*<p className="formLabel">10,000</p>*/}
              <input
                type="number"
                name="eventPrice"
                id="eventPrice"
                className="form-style"
                autocomplete="off"
                placeholder="10,000"
                onChange={this.onEnter}
              />
            </div>
          </div>
          
        </div>
        <div>
              <div className="card card-body">
                <div className="">
                  <h5 className="form-content__label">Ticket description</h5>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="form-style"
                    autocomplete="off"
                  />
                </div>
                <div>
                  <div className="row ticket-label">
                    <div className="col-lg-6">
                      <h5 className="form-content__label">
                        Ticket sale starts
                      </h5>
                      <div className="form-item">
                        <p className="formLabel">
                          September 19, 2018 - 10:00am
                        </p>
                        <input
                          type="datetime"
                          name="email"
                          id="email"
                          className="form-style"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="form-content__label">Ticket sale ends</h5>
                      <div className="form-item">
                        <p className="formLabel">
                          September 19, 2018 - 01:30pm
                        </p>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-style"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="form-content__label">Ticket status</h5>
                    <button className="btn btn-light ticket-button__status">
                      Public
                    </button>
                    <button className="btn btn-light ticket-button__status">
                      Private
                    </button>
                  </div>
                  <div className="col-md-6">
                    <h5 className="form-content__label">Sales channel</h5>
                    <button className="btn btn-light ticket-button__channel">
                      Everywhere
                    </button>
                    <button className="btn btn-light ticket-button__channel">
                      Online
                    </button>
                    <button className="btn btn-light ticket-button__channel">
                      Outlets
                    </button>
                  </div>
                </div>
                <div className="tickets-allow">
                  <h5 className="form-content__label ">
                    Tickets allow per order
                  </h5>
                  <div className="row">

                    <div className="col-md-3">

                      <div className="form-item">
                        <p className="formLabel">1</p>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-style"
                          autocomplete="off"
                        />
                      </div>
                      <p className="ticket-number">Minimum</p>
                    </div>

                    <div className="col-md-3">

                      <div className="form-item">
                        <p className="formLabel">10</p>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-style"
                          autocomplete="off"
                        />
                      </div>
                      <p className="ticket-number">Maximum</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
      </div>
              </div>
            
          
            
          </div>

        </div>
        <div className="nice-job">
          
          <button className="btn btn-success btn-bottom btn-edit-class">SAVE YOUR EVENT</button>
        </div>
        </form>
  </div>
</div>

</div>
      </div>
      <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  login:state.login, 
  register:state.register, 
  updateEventClass: state.updateEventClass,
});
const mapDispatchToProps = (dispatch) => ({
  onUpdateEventClass: bindActionCreators (onUpdateEventClass, dispatch),
});

export default connect (mapStateToProps, mapDispatchToProps) (EventClasses);