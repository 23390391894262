import React, { Component } from 'react';
import Header from '../components/loggedInHeader';
import Footer from '../components/footer';

export default class Terms extends Component {

	render() {
		return <div>
        <Header />

        <div className="container-fluid">
          <div className="termsConditions text-white col-sm-10 m-auto pt-5 pb-5">
          <h4 className="mb-4"> Nairabox Events – User Terms of Service </h4>

            <p> 1. THIS AGREEMENT AND THESE TERMS AND CONDITIONS</p>
            <p>
              1.1. This Agreement is between JORG Technologies Ltd., who are the registered owners of ‘Nairabox’. (“Nairabox”) and you (“User”, “you”, “your”). Whether you are using the Nairabox Services to purchase Tickets (“Buyer”) or sell Tickets (“Seller”), this Agreement governs your access to and use of the Nairabox Services.
            </p>
            <p>1.2. Please note:</p>
            <p>(a) Nairabox does not routinely screen the content of any Listing or its suitability for individual Users;</p>
            <p>(b) Nairabox does not own or set the Sale Price of any Tickets;</p>
            <p>(c) Nairabox does not endorse any Users or any Listings and reserves the right to remove a Listing it deems inappropriate from the Website at any time and for any reason;</p>
            <p>(d) Nairabox does not confirm the identity or suitability of Users; Users interact with and contact one another at their own risk;</p>
            <p>(e) Nairabox is not an event organiser or promoter;</p>
            <p>(f) Nairabox provides an online platform to facilitate the sale and purchase of Tickets; and</p>
            <p>(g) Nairabox is not party to the agreement between a Buyer and Seller for the sale and purchase of Tickets using the Nairabox Services. Therefore, Nairabox is not responsible for the Listing, the Event or the Ticket(s) purchased. Delivery of Tickets is the Seller’s responsibility and Nairabox is unable to guarantee that Sellers will complete Transactions in every single case.</p>

            <p>
            1.3. Legally Binding Agreement: This Agreement is a legally binding contract and you should read the following terms and conditions,
            the Nairabox Terms of Use, Privacy Policy and Cookie
              carefully before you use the Nairabox Services. By using the Nairabox Services, you acknowledge that you have read, understood and agree to be bound by the terms and conditions of this Agreement. If you don’t agree with this Agreement, or cannot comply with it, then you must not use the Nairabox Services.</p>
            <p>  Policy
              1.4. Changes to this Agreement: We may make changes to this Agreement at any time. Any changes we make will be effective immediately when we post a revised version of the Agreement on the Website. However, if you have already purchased Tickets at the time such a change is made, the version of this Agreement that was in force at the time you made the purchase will continue to apply and will not be deemed amended. Subject to the previous sentence, your continued use of the Nairabox Services will constitute your acceptance of the new Agreement so please make sure you read any such notification carefully. If you do not agree with the terms of this Agreement or any revised version of this Agreement, you must immediately stop using the Nairabox Services, including removing any Listings that you have posted.</p>

            <p>
              1.5. About Nairabox: Nairabox is a trading and brand name own and registered to JORG Technologies Limited; a limited liability company registered in Nigeria.
            </p>
            <p>
          The provisions of these Ticketing Terms are for the benefit of  Ticket holder and/or Nairabox and may be accepted by the Venue at any time.
          If you are under the age of 18, you must obtain your parents’ or legal guardians’ prior authorization, permission and consent to attend an Event.
          All clauses in the Ticketing Terms indicated in BOLD are provisions which limit the risk or liability of JORG and/or Nairabox and constitute an assumption of risk or liability by you, impose an obligation on you to indemnify JORG and/or Nairabox or is an acknowledgement of a fact by you, and are therefore drawn to your attention. You should pay particular attention to such clauses and consider them carefully.

            </p>
			      <p>
              1.6. Contacting Nairabox: If you have any questions or want any further information regarding this Agreement, please contact info@nairabox.com. You can also check out our FAQ’s
            </p>
          <p>
          1.7. Contacting You: If we need to contact you, we will do so using the telephone number or email address that you have provided with your Account.
          </p>
          <p>
          1.8. Your Location and associated changes to this Agreement: Where any local, national law, rule or regulation would conflict with provisions of this Agreement or render any provision invalid, illegal or unenforceable, nothing in this Agreement purports to or will seek to prevent the application of such law. All legal protections, rights or remedies applicable will apply and take precedence over the provisions of this Agreement, and this Agreement does not in any way seek to relieve you of such protections, rights or remedies.
          </p>

          <p><strong>B. Registration, Your Account and User Content</strong></p>
          <p>2. REGISTRATION</p>
          <p>2.1. Registration: In order to set up a Listing or buy Tickets using the Nairabox Services you must:</p>
          <p>(a) be at least 18 years old, or the legal age of majority in the country where you reside, and able to enter into legally binding contracts; and</p>
          <p>
            (b) setup a password-protected Account providing your correct fullname (or business name, if you are a business) and email address (all your details must be kept up to date at all times), further details can be added at a later date if desired. Your Account username may not include the name of another person with the intent to impersonate that person, or be offensive, vulgar or obscene.
          </p>
          <p>
            2.2. You can also register to join by logging into your account via a Third-Party Social Media Site. Please note that by connecting to the Website and the Nairabox Services using a Third-Party Social Networking Site, you are giving Nairabox permission to (i) access and use your information from that service as permitted by that service; and (ii) to store your authorisation access to that Third-Party Social Media Site.
          </p>

          <p><strong>3. YOUR ACCOUNT</strong></p>
          <p>
            3.1. Password: You are solely responsible for maintaining the security of your password and for all activity that occurs on your Account. You shall not permit anyone other than you to use your Account. You may not transfer or sell access to your Account. We will not be liable for any harm related to disclosure of your username or password or the use by anyone else of your username or password. You may not use another User's Account without that User's permission. You will immediately notify us in writing if you discover any unauthorised use of your Account or other account-related security breach. We may require you to change your username and/or password if we believe your Account is no longer secure or if we receive a complaint that your username violates someone else's rights. You will have no ownership in your Account or your username. We may refuse registration, cancel an account or deny access to the Website for any reason.
          </p>
          <p>
            3.2. Companies: If you are accepting this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity (and “you” refers and applies to that company or other legal entity).
          </p>
          <p>
          7. If an error is made in the price of Tickets ordered by you, you will be notified by us as soon as possible and we will
          give you the option to either (a) reconfirm your Ticket order at the correct price; or (b) cancel your Ticket order and
          receive a full refund from us.
                </p>
          <p>
            3.3. Financial Checks: You permit Nairabox and/or a third-party contractor to carry out financial checks for the purposes of verifying your financial status (which does not include a credit check but does include a fraud check) and you agree that Nairabox may pass your Account and payment card details to such third-party contractor to carry out such checks.
          </p>
          <p><strong>4. USER CONTENT</strong></p>
          <section id="refund">
            <p>
            4.1. User Content: We may, in our sole discretion, permit Users to post, upload, publish, submit or transmit profile information, comments, questions, Feedback, pictures and other content or information (“User Content”). User Content shall include all information posted, transmitted or otherwise made available by Users on the Nairabox Services in connection with the creation or booking of Listings.
    				</p>
            <p>
            3.5. International: Your Account can be used to access and use the Nairabox Services on the Website, application and any international platform operated by Nairabox and/or to purchase tickets to Events taking place in various countries. However, please be aware that buying and selling Tickets for Events in such countries outside the Nigeria may be subject to a different or amended agreement, and a different Privacy Policy.
            </p>

            <p><strong>4. USER CONTENT</strong></p>
            <p>
            4.1. User Content: We may, in our sole discretion, permit Users to post, upload, publish, submit or transmit profile information, comments, questions, Feedback, pictures and other content or information (“User Content”). User Content shall include all information posted, transmitted or otherwise made available by Users on the Nairabox Services in connection with the creation or booking of Listings.
            </p>
            <p>
            4.2. User Content Warranties: In relation to your User Content, you represent and warrant that:
            (a) you have the written consent of each and every identifiable natural person in the User Content to use such person’s name or likeness in the manner contemplated by the Nairabox Services
            and this Agreement, and each such person has released you from any liability that may arise in relation to such use;				</p>
            <p>
            (b) you have the written consent of the photographer or other owner of any images included in your User Content to use such pictures in the manner contemplated by the Nairabox Services and this Agreement;				</p>
            <p>
              (c) Nairabox's use of your User Content as contemplated by this Agreement and the Nairabox Services will not breach any law or infringe any rights of any third party, including but not limited to any intellectual property rights and privacy rights;				</p>
            <p>
              (f) to the best of your knowledge, all your User Content and any other information that you provide to us is truthful and accurate.				<p>
              9.1.1.5.  No refund shall be issued in respect of a cancelled purchase unless the original Ticket(s) are returned.
            </p>
            <p>
            4.3. User Content Licence Grant: By posting any User Content on the Nairabox Services, you expressly grant, and you represent and warrant that you have all rights, power and authority necessary to grant, to Nairabox a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide licence to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, syndicate, publicly perform, publicly display, and make derivative works of all such User Content and your name, voice, and/or likeness, and any third party name, voice, and/or likeness, as contained in your User Content, in whole or in part, and in any form, media or technology, whether now known or hereafter developed, for use in connection with the Nairabox Services and Nairabox’s (and its successors’ and affiliates’) business, including without limitation for promoting and redistributing part or all of the Nairabox Services (and derivative works thereof) in any media formats and through any media channels. You also hereby grant each User of the Nairabox Services a non-exclusive licence to access your User Content through the Nairabox Services, and to use, reproduce, distribute, display and perform such User Content as permitted through the functionality of the Nairabox Services and under this Agreement.				</p>
            <p>
              4.4. Nairabox and User Content: Nairabox takes no responsibility and assumes no liability for any User Content that you or any other User or third-party posts or sends using the Nairabox Services. You shall be solely responsible for your User Content and the consequences of posting or publishing it, and you agree that we are only acting as a passive conduit for your online distribution and publication of your User Content. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose, and you agree that Nairabox shall not be liable for any damages you incur (or allege to incur) as a result of User Content.
            </p>

            <p>
              <strong>C. Terms and Conditions – Listing and Selling Tickets</strong>
            </p>
            <p>5. CREATING A LISTING</p>
            <p>
              5.1. Listing Responsibility of Seller: By creating a Listing using the Nairabox Services, Sellers are offering to sell or distribute, for the Sale Price, where applicable, Ticket(s) for Buyers to attend an Event. It is the Seller’s responsibility to make any Event or Ticket restrictions clear to the Buyer and the Seller is solely responsible for all the Listings that they post on the Website. Any changes to Listings, Events and Tickets must be made clear to Users and Buyers as soon as possible.				</p>
            <p>
              9.1.1.10 for Ticket(s) returned up to 60 days prior to the event date, a reasonable cancellation charge of 50% (fifty percent) will apply;
            </p>
            <p>
              5.2. Listing Representation: A Listing is a legal representation made by the Seller about the details relevant to the Ticket and Event, and their accuracy.
            </p>
            <p>
              5.3. Service Charge Preference: The Seller shall elect whether the Service Charge shall be paid by the Buyer, in addition to the Sale Price, or included in the Sale Price and paid by the Seller.
            </p>
            <p>
            <p>5.4. Listing Description: The Seller must provide all the Event information in the Listing, for example:</p>
            (a) the location of the Event;<br/>
            (b) the name of the Event and performer(s) (if relevant);<br />
            (c) the date of the Event;<br/>
            (d) the name and address of the Venue;<br />
            (e) the type of Ticket(e.g. seated, standing, timedentry,etc.);<br/>
            (f) the Sale Price of each Ticket;<br/>
            (g) whether or not the Service Charge is included in the Sale Price or not;<br/>
            (h) whether or not a portion of the Sale Price will be donated to a particular cause or charity and the relevant associated details;<br/>
            (i) the refund policy, if any;<br/>
            (j) any additional information, special rules or requirements which apply to the Event and/or the Tickets (e.g., age restrictions, accessibility restrictions, restricted view, family section, alcohol restrictions, etc.);<br/>
            (k) if applicable, information on how and when the Buyer will be informed about whether the Seller has approved the Transaction or not (see paragraphs 5.2 and 11.5 below);<br/>
            (l) any details about the Buyer that the Seller will be collecting during the Transaction process and details as to how such data will be processed, including any Buyer User Data to be collected; and<br/>
            (m) any other significant information relating to the Event and/or Ticket(s) that may reasonably affect a Buyer’s decision about whether to purchase or apply for a Ticket or not, including any information required by law.<br/>
            </p>
            <p>
              Compliance: When creating a Listing, the Seller must comply with all applicable local and national laws and regulations and the terms of this Agreement. If you are a Seller trading as a business, you
              must identify yourself as such by using a business Account. Sellers trading as a business are responsible for complying with all applicable local and national laws and regulations in relation to their obligations as a “trader”. Nairabox assumes no responsibility for a Seller’s compliance with applicable laws, rules or regulations.
            </p>
            <p>
              5.6. Seller Warranties: The Seller represents and warrants to and for the benefit of Nairabox, that any Listings that he/she creates:
            </p>
            <p>
              (a) relate only to Tickets and Events over which that Seller has control, either directly or indirectly, and for which the Seller is permitted to sell or distribute Tickets;<br/>
              (b) include accurate and transparent Ticket prices and information relating to all additional charges which will be incurred by Buyers;<br />
              (c) if booked, will be honoured, unless otherwise agreed with the Buyer;<br />
              (d) do not infringe any third-party rights (including intellectual property rights);<br/>
              (e) are not being listed by the Seller serving in the capacity of a broker, rental agent or listing agent for a third party;<br/>
              (f) do not comprise of the same or substantially the same content as any other Listings on the Website and are not published on any other website;<br/>
              (g) do not in any way contain fraudulent or inaccurate or misleading information; Please note: selling stolen or counterfeit property is a crime and Nairabox will report such activity to the relevant local authorities and permanently ban anyone found selling stolen or counterfeit property from using the Nairabox Services.<br/>
              (h) are not defamatory, abusive, threatening or obscene;<br/>
              (i) are not unethical or discriminatory on the basis of age, gender, race, marital status, nationality or cultural, religious or personal beliefs, disability, sexual orientation, political opinion or otherwise;<br/>
              (j) do not attempt to solicit any Users to use a third-party service that competes with the Nairabox Services;<br/>
              (k) do not breach any applicable local, national or international law or regulation, including those associated with making charity donations;<br/>
              (l) do not contain any virus or other programme that interferes with or seeks to interfere with any computer system or data;<br/>
              (m) cannot be construed to be associated with any terrorist organisations; and<br/>
              (n) do not contain political campaigning, commercial solicitation, chain letters, mass mailings or any form of “spam”.<br/>
            </p>
            <p>
              5.7. VAT: The Seller is responsible for ensuring that the Sale Price includes all taxes required on Listings, including VAT or applicable local taxes. If in doubt, the Seller should seek the advice of a tax expert to determine which taxes need to be collected from the Buyer.
            </p>
            <p>
              5.8. Insurance: The Seller is solely responsible for any insurance that may be required in relation to the Listing and the Event.
            </p>
            <p>
              5.9. Event Cancellation or Alteration: If an Event is cancelled, re-scheduled or amended in any way, this must be dealt with by the Seller and communicated clearly to Buyers. The Listing must be updated or removed by the Seller and if it is not, we reserve the right to remove the Listing from the Nairabox Services.
            </p>
            <p>
              5.10. No Nairabox Guarantee: Nairabox does not guarantee that Listings made via the Nairabox Services will be published and active within a certain time of the Seller posting a Listing or that it will appear in a particular order in search results or that the Ticket(s) will sell. If a Ticket does not sell, Nairabox accepts no responsibility.
            </p>

            <p><strong>6. SALE OF TICKETS</strong></p>

            <p>6.1. Payment Processing Preferences: There are stipulated Payment Processing Methods under this service which Sellers can select when creating a Listing. further details can be obtained from the Custumer Services team. Once the Payment Processing Method has been selected by the Seller for a Listing, it can be changed at any time; however, any funds from existing Ticket sales will continue to be paid out via the Payment Processing Method set at the time of sale.</p>
            <p>6.2. Approval Settings: Sellers may set a Listing so that Seller approval is required for a Buyer to complete a Transaction. Such Listings must specify the timeframe within which approval or rejection of the Transaction must be provided by the Seller. If the Seller neither confirms nor rejects the Transaction within this time, it will be automatically cancelled.</p>
            <p>6.3. Sale Contract and Order Confirmation: Following Seller approval, if required, when a Buyer completes the sales process by entering their payment details and clicking on “pay now” for paid for Events or “register” for free Events, the parties shall become contractually bound to one another to complete the Transaction. An Order Confirmation will be generated and sent to the Buyer once the Buyer has completed the payment process.</p>
            <p>6.4. Failed Delivery: Order Confirmations are automatically generated following the Buyer’s completion of the payment process. Any notification of a failed delivery that the Seller receives from a Buyer must be dealt with by the Seller as soon as possible and, in any event, in advance of the date of the relevant Event.</p>
            <p>6.5. Disputed Events: If a Buyer disputes any element of an Event or a Transaction Value, such dispute shall be between the Buyer and the Seller and Nairabox shall have no involvement.</p>

            <p><strong>7. NAIRABOX PAYMENTS</strong></p>
            <p>7.1. Nairabox Payments: Nairabox Payments is the default payment option which is available to Sellers.</p>
            <p>7.2. Payment to Sellers: Provided that the Event has taken place, and no dispute has arisen in connection with the Transaction, Nairabox shall pay the proceeds due to the Seller from the Transaction using the payment method selected by the Seller approximately 48 hours after the end of the Event. Nairabox reserves the right to cancel Transactions and issue refunds to Buyers where,in its reasonable opinion, a Buyer is legally entitled to a refund for any reason, in which case no
            payment shall become due to the Seller.</p>
            <p>7.3. Payment Details: Sellers are responsible for providing Nairabox with accurate bank account
            information associated with their Account and keeping this up to date at all times.</p>
            <p>7.4. Account Balance: Sellers will be notified by email once funds are available for withdrawal. Nairabox shall remit the withdrawal request to the Seller within a commercially reasonable time of such
            request.</p>
            <p>7.5. Payments: In some circumstances, payment shall only be made if the amount due to the Seller
            totals at least the
            carried over indefinitely until the amount accrued is the Threshold Amount or above. If Sellers wish to have their balance remitted in a given month in which the amount due to them is less than the Threshold Amount, the Seller should contact Nairabox at info@nairabox.com with such request.</p>
            <p>7.6. Withholding Payment: Nairabox reserves the right to withhold payment to a Seller or charge back to the Seller’s account any amounts otherwise due to Nairabox under this Agreement, or any amounts due as a result of a breach of this Agreement, pending Nairabox’s reasonable investigation of such breach, in its sole discretion. Nairabox reserves the right to freeze any payments due to Sellers for up to 3 months if it, in its sole discretion, believes that charges may be disputed by Buyers or the Seller, Event, Listing and/or Tickets appear to be fraudulent.</p>
            <p>7.7. Disputed Payments: If a Seller disputes any payment made or owed by Nairabox, the Seller must notify Nairabox in writing within 30 days of any such payment being paid or becoming due.
            relating to any such disputed payment.</p>
            <p>7.8. Refunds: If a Seller grants a Buyer a refund but has insufficient funds in their Nairabox account to
            cover the refund amount, the Seller’s account will go into the negative and Nairabox shall pursue the Seller for all monies owed. The Seller will be charged the Service Charge, which shall be retained by Nairabox.</p>


            <p><strong>8. PAYMENTS</strong></p>
            <p>8.1. Applicable Taxes: Sellers agree to pay all applicable taxes or charges imposed by any government entity and/or local laws or regulations in connection with their Listings and associated Transactions.</p>
            <p>8.2. Chargebacks: Any credit card chargebacks or other transaction reversals initiated against Nairabox or its affiliates for any reason (except to the extent they are caused solely by Nairabox’s negligence or wilful misconduct) with respect to a Seller’s Listing and all related credit card association, payment processing and other fees and expenses incurred by Nairabox or its affiliates in connection with such chargebacks shall ultimately be the responsibility of the Seller and the Seller agrees to promptly and fully reimburse Nairabox for such amounts on demand. Nairabox reserves the right to withhold at least 10% of a Seller’s proceeds, in its sole discretion, to cover the cost of any
            such chargebacks that may arise.</p>
            <p>8.3. Cancellation and Refunds: The cancellation and refund policy for a Ticket is at the Seller’s discretion
            and will be set out in the Listing description. If no such policy is set out by the Seller, before the start time of the Event.</p>


            <p><strong>9. USE AND COLLECTION OF DATA BY SELLERS</strong></p>
            <p>9.1. Nairabox Account Information: The Seller may be given access to Buyers’ Nairabox Account Information where necessary to fulfil Tickets. Nairabox does not provide any warranties as to the accuracy, relevance or fitness for any particular purpose in relation to the Nairabox Account Information. The Seller is the Data Controller (as defined in NITDA 2013 Guideline for Data Protection) in respect of the Nairabox Account Information of Buyers, from the point at which it is passed and/or made available to the Seller and the Seller must comply with Data Protection Legislation when processing Nairabox Account Information of Buyers. Where Nairabox Account Information of Buyers is made available, it is confidential and must only be disclosed to authorised persons using appropriate security measures. The Seller must keep Buyers’ Nairabox Account Information secure and must take reasonable steps to protect it from misuse, loss and from unauthorised access, modification or disclosure.</p>
            <p>9.2. Buyer User Data: The Seller is the Data Controller of all Buyer User Data and must comply with Data Protection Legislation when processing Buyer User Data including, but not limited to, providing Buyers with information about how their data will be used, and collecting appropriate permission from Buyers for any uses made of their User Data. The Seller must keep Buyer User Data secure and must take reasonable steps to protect it from misuse, loss and from unauthorised access, modification or disclosure. Nairabox has no control over or input into Buyer User Data, nor does it have responsibility for or ownership of any Buyer User Data collected and solely acts as a processor in relation to the same. Notwithstanding this, Nairabox will take appropriate measures to protect Buyer User Data, when held within the Nairabox Services, from loss, misuse and unauthorised access, disclosure, alteration and destruction, taking into account the risks involved in the processing and the nature of the Buyer User Data. Nairabox will only process Buyer User Data on the instructions of the Seller.</p>
            <p>9.3. Prohibited Data Classes: Sellers must not use the Nairabox Services to collect the following types of data: payment card or bank details, details of racial or ethnic origin, political opinions, details of religious beliefs, trade union membership details, physical or mental health information, details of sexual life, details of the commission or alleged commission of any offence, and personal data of or other information relating to children under the age of 18. If the Seller collects such information, Nairabox is entitled, in its sole discretion, to terminate the Agreement for material breach in accordance with paragraph 15 of this Agreement.</p>

            <p><strong>10. ENFORCEMENT ACTION AGAINST SELLERS</strong></p>
            <p>10.1. Cancellation: Nairabox reserves the right to cancel a Listing and/or a Transaction and/or a Seller’s Account in its sole discretion, for any reason and at any time.</p>

            <p>
              <h4>D. Terms and Conditions – Buying Tickets</h4>
            </p>
            <p><strong>11. PRICE AND PAYMENT</strong></p>
            <p>11.1. Sale Price: The Seller sets the Sale Price of the Ticket and can change the Sale Price at any time prior to a Transaction taking place.</p>
            <p>11.2. Fees/Charges: When a Buyer selects one or more Tickets for purchase, any additional charges and/or fees associated with the Ticket and Transaction shall be made clear by the Seller, which shall include:</p>

            <p>(a) the Sale Price;</p>
            <p>(b) the Service Charge (if applicable); and </p>
            <p>(c) any other charges included by the Seller.</p>

            <p>11.3. Check Your Order: As a Buyer you must ensure that you have read the full Listing details and are happy with your Ticket selection prior to purchase. Nairabox will not rectify any issues that arise, and Sellers will only do so in their sole discretion.</p>
            <p>11.4. Payment Information: The Buyer warrants and represents that he or she is authorised to use the selected payment method and that the billing information provided is complete, up to date and accurate.</p>
            <p>11.5. Approval Settings: Some Listings may require the Seller’s approval prior to the Buyer being able to purchase Tickets. Such Listings shall specify the timeframes within which approval must be given by the Seller. If no approval is granted within such timeframes, your order will be cancelled, and payment will not be taken.</p>
            <p>11.6. Order Confirmation: Upon Buyers completing their purchase, by entering their payment details, where necessary, and clicking on “pay now” or “pay” for paid for Events or “register” for free Events, an Order Confirmation shall automatically be issued to the Buyer, which is the point at which the Buyer and Seller enter into a binding agreement. The Order Confirmation will contain the Ticket with which the Buyer will be able to attend the Event. If the Order Confirmation is not received, it can be retrieved from the Account dashboard, along with the Ticket.</p>
            <p>11.7. Payment: Once the Buyer receives the Order Confirmation, depending on which Payment Processing Method the Seller has selected, the Buyer agrees that Nairabox or a third-party payment processor, acting on behalf of the Seller, may debit or authorise the amount of the Transaction Value from the method of payment provided by the Buyer. If applicable, the Service Charge is non- refundable and, unless otherwise agreed by the Seller, no refunds or credits will be provided once the Buyer has been charged.</p>
            <p>11.8. Other Ticket Terms and Conditions: All Tickets purchased by the Buyer are subject to the Ticket, Event and Venue terms and conditions, and all applicable laws and regulations.</p>
            <p>11.9. After-Sales Queries: If the Buyer has any questions or concerns following the purchase of a Ticket, the Buyer should contact the Seller directly and should not contact or attempt to contact Nairabox.</p>
            <p>11.10. Donations: Sellers may pledge to donate a portion of the Sale Price to a particular cause or charity. Nairabox does not control or accept any responsibility or liability for any donations pledged by the Seller. Nairabox does not investigate or otherwise ensure the factual accuracy of any
            donation claims made by Sellers in Listings.</p>
            <p>11.11. Foreign Currency: Nairabox provides a feature through which Users may view fees for various
            Listings in foreign currencies. Sale Prices listed in foreign currencies are for informational purposes only. You understand and agree that any such foreign currencies are not the official Sale Price andat the time you request a booking you will be notified of the currency in which you will be charged together with the corresponding Transaction Value. The value is payable in the currency specified at the time of checkout.</p>

            <p><strong>12. CANCELLATION AND REFUNDS</strong></p>
            <p>12.1. Cancellation: The cancellation and refund policy for a Ticket is at the Seller’s discretion and will be set out in the Listing description. If no such policy is set out by the Seller, Buyers may cancel a booking and receive a refund of the Sale Price, if requested 24 hours or more before the start time of the Event.</p>
            <p>12.2. Refunds: If a Buyer cancels a booking within the timeframe set out by the Seller, if any, the Buyer may be refunded the Sale Price for such booking within a commercially reasonable amount of time, typically one week after a refund is agreed upon. Service Charges, if applicable to the Buyer, are non-refundable (other than as may be legally required).</p>
            <p>12.3. Refund via Nairabox Payments: If a Seller grants a Buyer a refund and Nairabox Payments has been selected by the Seller, Nairabox will refund the Sale Price paid by the Buyer for such booking within a commercially reasonable amount of time, typically one week after a refund is agreed upon.</p>
            <p>12.4. Disputes: If a Seller fails to fulfil its obligations in relation to a Transaction, subject to the refund options a Seller has set out in the Listing, Buyers will have 48 hours from the start time of the Event to request a refund. If a Buyer requests a refund within this timeframe, Nairabox will promptly notify the Seller. The Seller will have 48 hours to rebut such dispute, resulting in one of two scenarios: (i) if the Seller does not rebut such dispute, a full refund of the Sale Price will be given to the Buyer by the Seller; or (ii) if the Seller does rebut such dispute, the two parties must contact one another to resolve the dispute and submit the confirmation of the resolution to Nairabox within 30 days. If no confirmation of resolution is received by Nairabox within 30 days, Nairabox will resolve the dispute in its sole and absolute discretion, which may include a decision to refund the Buyer and pursue the Seller for the refunded amount. It is the Seller’s responsibility to provide proof that a Buyer was in attendance at an Event.</p>

            <p><strong>13. ENFORCEMENT ACTION AGAINST BUYERS</strong></p>
            <p>13.1. Cancelling Transactions: Nairabox reserves the right to cancel a Buyer’s Transaction in any of the following circumstances if:</p>
            <p>(a) Nairabox suspects any fraudulent, illegal or unethical activity relating to one or more Transactions; and/or</p>
            <p>(b) Nairabox suspects any unauthorised use of a Buyer’s Account and/or of the Nairabox Services; and/or</p>
            <p>(c) a breach of the terms of this Agreement occurs.</p>

            <p>
              <h4>E. General Terms and Conditions</h4>
            </p>
            <p><strong>14. DEFINITIONS AND INTERPRETATION</strong></p>
            <p>14.1. In this Agreement:</p>
            <p>(a) “Account” means the account set up by you to use the Nairabox Services.</p>
            <p>(b) “Agreement”meansthesetermsandconditions,theTermsofUse,theNairaboxPrivacyPolicy, the Cookie Policy and any other instructions or terms and conditions made available to you.</p>
            <p>(c) “Buyer User Data” means the data collected pursuant to the bespoke questions, if any, that a Seller is able to include in a Listing in order to collect personal data from Buyers during the checkout process, as well as personal data the Buyer is able to upload into the Nairabox Services.</p>
            <p>(d) “Data Protection Legislation” means all data and privacy laws, rules and regulations to the extent applicable from time to time, including, without limitation, the following legislation The Nigerian Constitutional Provisions on privacy (section 36 Constitution of the Federal Republic of Nigeria [as amended] 1999, (“NITDA 2013 Guidelines on Data Protection”): (a) Cybercrimes (Prohibitions, Prevention etc) Act 2015; and (b) any other applicable national privacy law.</p>
            <p>(e) “Event” means any event or ticketed activity detailed in a Listing for which Tickets may be issued, bought or sold via the Nairabox Services.</p>
            <p>(f) “Feedback” means feedback, comments and suggestions for improvements to the Nairabox Services posted by Users.</p>
            <p>(g) “Force Majeure” means any cause beyond Nairabox’s control including without limitation, acts of God, war, insurrection, riot, civil disturbances, acts of terrorism, fire, explosion, flood, epidemic, theft of essential equipment, malicious damage, strike, lock out, weather, third party injunction, national defence requirements, acts or regulations of national or local governments.</p>
            <p>(h) “Listing” means the Event and Ticket listing(s) created by a Seller, for which Tickets are available for sale or distribution and which includes the digital content created for that Event.</p>
            <p>(i) “Order Confirmation” means the written notice confirming a Transaction, issued to the Buyer automatically once the booking process has been completed, at which point a legally binding agreement in respect of the Transaction between that Buyer and that Seller is formed.</p>
            <p>(j) “Payment Processing Method” means one of the methods of payment processing, when selling Tickets using the Nairabox Services. The default option is set to Nairabox Payments and Sellers can choose to integrate Flutterwave Payments into their Account to manage their own payment process.</p>
            <p>(k) “Sale Price” means the price at which a Seller offers a Ticket for sale in Nigerian Naira or other applicable currency, using the Nairabox Services, which can be nil (i.e. some Events may be free) and which includes any and all additional costs and charges which may be set by the Seller.</p>
            <p>(l) “Service Charge” means the non-refundable (other than as may be legally required) service charge payable to Nairabox for the provision of the Nairabox Services, which at the Seller’s election may either be charged to the Seller (in which case such amount will be collected by Nairabox prior to settlement of sales proceeds) or charged to the Buyer (in which case such
              amount will show in the subtotal on the checkout page and will be paid by the Buyer on top of the Sale Price as part of the total Transaction Value) for providing the platform, which is inclusive of VAT at the then-current rate.</p>
            <p>(m) “Third-Party Social Media Site” means third party websites and applications that enable users to create and share content or to participate in social networking, including, but not limited to, Facebook, Twitter and Google Chrome.</p>
            <p>(n) “Threshold Amount” means at least N1,000,000.00 (One Million Naira) or its equivalent amount
              in the currency of the country in which you reside.</p>
            <p>(o) “Ticket(s)” means an electronic ticket or other type of evidence of the right to occupy space at or to attend an Event.</p>
            <p>(p) “Transaction” means a transaction undertaken via the Nairabox Services where the Buyer agrees to buy and the Seller agrees to sell one or more Tickets at the listed Sale Price(s).</p>
            <p>(q) “Transaction Value” means the total amount payable by a Buyer pursuant to a Transaction,
              which includes all the costs and fees set out in paragraph 11.2.</p>
            <p>(r) “Nairabox Account Information” means the personal data you upload when registering your Account.</p>
            <p>(s) “Nairabox Payments” means the Payment Processing Method option which requires no set up by the Seller and through which the Transaction Value gets paid to Nairabox who will remit the net Sale Price amount(s) to the Seller 48 hours after the end of the Event, subject to the terms of this Agreement.</p>
            <p>(t) “Nairabox Services” means all of Nairabox’s online services, including but not limited to the Website, the software application available for download to a smartphone which contains a modified version of the Website and all mobile services, any software provided, and any related tools and services provided by Nairabox.</p>
            <p>(u) “Venue” means any site, facility or location where an Event is held.</p>
            <p>(v) “Website” means any website run or provided by Nairabox, including any software application available for download to a smartphone which contains a modified version of the Website.</p>

            <p>14.2. Interpretation: The terms “including”, “include”, “for example” or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.</p>
            <p>14.3. Headings: The headings used in this Agreement are for reference purposes only and do not affect its interpretation.</p>

            <p><strong>15. AGREEMENT TERM AND TERMINATION</strong></p>
            <p>15.1. Commencement and Term: This Agreement shall commence on the date that you register an Account with Nairabox and shall continue indefinitely subject to earlier termination by either you or Nairabox.</p>
            <p>15.2. Termination by Nairabox: Nairabox may terminate this Agreement if:</p>

            <p>(a) any abusive or threatening behaviour is reported to Nairabox as being carried out by you or via your Account;</p>
            <p>(b) Nairabox suspects any unauthorised use of your Account and/or the Nairabox Services;</p>
            <p>(c) Nairabox suspects any fraudulent activity or other illegal activity relating to your Account;</p>
            <p>(d) you otherwise breach the terms of this Agreement;</p>
            <p>(e) Nairabox is ordered to do so by any legal or regulatory authority;</p>
            <p>(f) you are an individual, and you are unable to pay your debts as they fall due or are declared bankrupt or if you are a company and you become subject to an administration order or make a voluntary arrangement or composition with your creditors (or similar other arrangement applicable in the country you reside), or an encumbrancer takes possession of or a receiver is appointed over your property or assets, or the company is wound up or ceases or threatens to cease to carry on business except for the purposes of reconstruction; and/or</p>
            <p>(g) Nairabox provides you with 30 days’ written notice of termination, at Nairabox’s election.</p>

            <p>15.3. Termination by You: You may cancel your Account at any time via the Cancel Account feature or by sending an email to support@Nairabox.com. If you are a Seller, before cancelling your account, you must cancel any Tickets you have sold for Events that have not taken place, notify Buyers and refund the Buyers, if you are using Stripe Payments, or instruct Nairabox to refund Buyers, if using Nairabox Payments. Please note that if your Account is cancelled, Nairabox does not have an obligation to delete or return to you any User Content you have posted to the Nairabox Services.</p>
            <p>15.4. Consequences of Termination: Termination of this Agreement shall not affect either your or Nairabox’s rights and liabilities accrued prior to and including the date of termination and/or any terms intended expressly or by implication to survive termination.</p>

            <p><strong>16. LICENSES</strong></p>
            <p>16.1. User Licence: Subject to your compliance with this Agreement, Nairabox grants you a limited, non- exclusive, non-transferable licence, to access and view content via the Nairabox Services for your personal use and solely for the purposes expressly permitted by the Nairabox Services. This licence is expressly conditioned on your pre-existing agreement to comply with, and your actual compliance with, each of the provisions described in this Agreement.</p>
            <p>16.2. User Licence Restrictions: You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sublicense, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Nairabox Services, except as expressly permitted in this Agreement. No licences or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Nairabox or its licensors, except for the licences and rights expressly granted in this Agreement.</p>
            <p>16.3. Trademarks: Nairabox logos as well as the name and any designs found on the Website are registered trademarks of Nairabox. Nairabox's trademarks may be used publicly only with our writtenpermission. Except as expressly stated in this Agreement, Nairabox does not grant any express or implied right to you under any of its trademarks, copyrights or other proprietary information.</p>

            <p>
              <h4>17. NAIRABOX SERVICE RULES</h4>
            </p>
            <p>17.1. Rules: In connection with your use of the Nairabox Services, you may not and you agree that you will not:</p>
            <p>(a) breach any local, state, provincial, national, or other law or regulation, or any order of a court;</p>
            <p>(b) infringe the rights of any person or entity, including without limitation, their intellectual property, privacy, publicity or contractual rights;</p>
            <p>(c) interfere with or damage the Nairabox Services, including, without limitation, through the use of viruses, bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology;</p>
            <p>(d) use the Nairabox Services in connection with the distribution of unsolicited commercial email ("spam") or advertisements unrelated to the Nairabox Services;</p>
            <p>(e) "stalk" or harass any other User of the Nairabox Services or collect or store any personally identifiable information about any other User (except as expressly permitted by the Nairabox Services);</p>
            <p>(f) submit, or provide links to, any postings containing material that could be considered harmful, obscene, pornographic, sexually explicit, indecent, lewd, violent, abusive, profane, insulting, threatening, harassing, hateful or otherwise objectionable, includes the image or likeness of individuals under 18 years of age, encourages or otherwise depicts or glamorises drug use (including alcohol and cigarettes), characterises violence as acceptable, glamorous or desirable, or contains any personal contact information or other personal information identifying any third party;</p>
            <p>(g) submit,or provide links to, any postings containing material that harasses, victimises, degrades, or intimidates an individual or group of individuals on the basis of religion, race, ethnicity, sexual orientation, gender, age, or disability;</p>
            <p>(h) register for more than one Account or register for an Account on behalf of an individual other than yourself, including any employee or representative of our company;</p>
            <p>(i) recruit or otherwise solicit any Seller or other User to join third party services or websites that are competitive to Nairabox, without Nairabox’s prior written approval;</p>
            <p>(j) impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity;</p>
            <p>(k) use automated scripts to collect information or otherwise interact with the Nairabox Services;(l) use the Nairabox Services to find a Seller or Buyer and then complete the transaction independent of the Nairabox Services in order to circumvent the obligation to pay any fees related to Nairabox’s provision of the Listing;</p>
            <p>(m) as a Seller, submit any Listing with a false or misleading price information, or submit any Listing with a price, service or activity that you do not intend to honour;</p>
            <p>(n) use, display, mirror or frame the Nairabox Services, or any individual element with in the Nairabox Services, Nairabox’s name, any Nairabox trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without Nairabox’s express written consent;</p>
            <p>(o) access, tamper with, or use non-public areas of the Nairabox Services, Nairabox’s computer systems, or the technical delivery systems of Nairabox’s providers;</p>
            <p>(p) attempt to probe, scan, or test the vulnerability of any Nairabox system or network or breach any security or authentication measures;</p>
            <p>(q) avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Nairabox or any of Nairabox’s providers or any other third party (including another User) to protect the Nairabox Services or any content thereon;</p>
            <p>(r) attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Nairabox Services;</p>
            <p>(s) reproduce or scan Tickets in a format or medium different from that provided by the Website;</p>
            <p>(t) use any automated software or computer system to search for, reserve, buy or otherwise obtain Tickets, discount codes, promotional codes, vouchers, gift cards or any other items available on the Website, including sending information from your computer to another computer where such software or system is active; and/or</p>
            <p>(u) advocate, encourage, or assist any third party in doing any of the foregoing.</p>

            <p>17.2. Legal Action: Nairabox reserves the right to investigate and take appropriate legal action against any illegal and/or unauthorised use of the Nairabox Services. Nairabox may involve and cooperate with local law enforcement authorities in prosecuting Users who breach this Agreement, including, without limitation, civil, criminal and injunctive redress. You agree that monetary damages may not provide Nairabox a sufficient remedy and that Nairabox may pursue injunctive or other relief for your violation of these Terms. If Nairabox determines that you have violated these Terms or any local or national law, or for any other reason or for no reason, Nairabox may cancel your Account, delete all your User content and prevent you from accessing the Website at any time without notice to you. If that happens, you may no longer use the Nairabox Services or the Website. You will still be bound by your obligations under these Terms. You agree that Nairabox will not be liable to you or any third party for termination of your access to the Website or to your account or any related information, and Nairabox will not be required to make the Website or your account or any related information available to you.</p>

            <p><strong>18. WARRANTIES AND INDEMNITIES</strong></p>
            <p>18.1. True Information: You represent and warrant that the information that you submit to Nairabox for your Account and in your use of the Nairabox Services is true, accurate and complete and you will not use false information, including name and email address, in using the Nairabox Services.</p>
            <p>18.2. Legal Compliance: You represent and warrant that in using the Nairabox Services, you shall comply will all applicable local, regional, national and international laws, regulations, codes of practice and the terms of this Agreement. You further warrant that you are aged 18 years or over and can enter into legally binding contracts.</p>
            <p>18.3. Tax Indemnity: You agree that Nairabox is not in any way responsible for the accuracy or suitability of any payment of taxes to any entity on your behalf (whether Buyer and/or Seller). If in using the Nairabox Services you are trading as a business, you hereby indemnify and hold Nairabox harmless against all liabilities, costs, interests and expenses (including reasonable legal fees) incurred by Nairabox that arise out of any third party or governmental claim that involves, relates to or concerns any local, regional, national or international tax obligation or amounts due or owing under any tax regulation, order, law or decree or any dispute concerned the tax status of Nairabox.</p>
            <p>18.4. General Indemnity: You agree to indemnify and hold Nairabox and its parents and affiliated companies, suppliers, advertisers and sponsors, and each of their officers, directors, employees, agents, legal representatives and sub-contractors (the “Indemnified Parties”) harmless against any claim or demand and all liabilities, costs and expenses (including reasonable legal fees and costs) incurred by an Indemnified Party arising as a result of or in connection with your use of the Website, breach of this Agreement (including any misuse of the Nairabox Services) and/or your violation of any law or the rights of a third party. We reserve the right to take exclusive control and defence of any claim, and you will cooperate fully with us in asserting any available defences.</p>

            <p><strong>DISCLAIMERS</strong></p>
            <p>19.1. Nairabox Disclaimer: Nairabox disclaims any and all liability for the acts, omissions and conduct of any third-party users, Users, advertisers and/or sponsors on the Website, in connection with the Nairabox Services or otherwise related to your use of the Website and/or the Nairabox Services. Nairabox is not responsible for the products, services, actions or failure to act of any venue, performer, promoter or other third party in connection with or referenced on the Website. Without limiting the foregoing, you may report the misconduct of Users and/or third-party advertisers, service and/or product providers referenced on or included in the Website to Nairabox using our online help desk or Customer Services. Nairabox may investigate a claim and take appropriate action, in its sole discretion.</p>

            <p><strong>LIABILITIES</strong></p>
            <p>20.1. Nairabox Disclaimer: Due to the nature of Nairabox’s business, and the fact that Nairabox cannot control Users’ acts or omissions, Nairabox makes no warranty, and specifically disclaims all liability, in relation to any goods or services provided by Sellers.</p>
            <p>20.2. Event Attendance: Under no circumstances shall Nairabox be liable for death or personal injury suffered by you or your guests arising out of attendance at an Event unless directly caused byNairabox’s negligence. Neither shall Nairabox be liable for any loss or damage sustained to yourproperty or belongings or that of your guests attending an Event.</p>
            <p>20.3. Nairabox’s Liability: You agree that you have no claim against Nairabox for the actions, whethernegligent or intentional, of any other User of the Nairabox Services (whether Buyers or Sellers), and in relation to any Listing, Event or Transaction, other than where Nairabox is at fault. If a dispute arises between a Buyer and Seller, you release Nairabox (and its affiliates, parent undertakings, subsidiaries and their respective officers, directors, employees, agents, legal representatives and sub-contractors) from all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, disclosed and undisclosed arising out of or in any way connected with such dispute.</p>
            <p>20.4. Exclusion of Liability: To the fullest extent permitted by local, national or international law, Nairabox (and its affiliates, parent undertakings, subsidiaries and their respective officers, directors, employees, agents, legal representatives and sub-contractors) shall not be liable to you in contract or tort (including negligence) for any indirect, consequential losses or business losses such as loss of data, profits, revenue, goodwill, reputation, loss of enjoyment or opportunity, nor any exemplary, special or punitive damages which are not reasonably foreseeably, arising directly or indirectly from your access to and use of or inability to use the Nairabox Services. Nothing in this Agreement shall limit or exclude Nairabox’s liability for fraud or fraudulent misrepresentation or for death or personal injury arising as a result of Nairabox’s negligence or for any other liability that cannot be limited or excluded by local, national or international law.</p>
            <p>20.5. Liability Cap: Without prejudice to the other provisions of this paragraph 21, if Nairabox is found to be liable, Nairabox’s liability to you (other than liability that cannot be limited or excluded by virtue of applicable local, national or international law) is limited in aggregate to the Transaction Value in relation to the Transaction giving rise to any such liability.</p>
            <p>20.6. Force Majeure: Nairabox will not be liable to you for failure to perform any of its obligations under this Agreement to the extent that the failure is caused by reason of Force Majeure.</p>

            <p><strong>21. DISPUTE RESOLUTION</strong></p>
            <p>21.1. If any dispute arises out of this Agreement or any Transaction pursuant to this Agreement, you should contact our Customer Services through its helpline, live-chat or email address who will try to resolve the matter as expediently as possible. Your complaint shall be acknowledged by Nairabox as soon as possible.</p>
            <p>21.2. Nairabox shall use reasonable endeavours to consult or negotiate in good faith and attempt to reach a just and equitable settlement that is satisfactory to you and Nairabox.</p>
            <p>21.3. In the unlikely event that we are unable to resolve your complaint by negotiation within 28 days, we may attempt to settle a matter via mediation. To initiate a mediation a party must give written notice to the other party to the dispute requesting a mediation. The mediation shall be conducted in accordance with the procedure of Lagos State Multi-Door Court (LSMDC) practice and procedure, which sets out the procedures to be adopted, the process of selection of the mediator and the costs involved and which terms are deemed incorporated into this agreement.21.4. The above does not restrict your statutory rights, your right to make a complaint to the Consumer Protection Council (CPC) or another appropriate local or national regulatory authority, or your right to pursue court proceedings or other forms of settlement with the aim of resolving the dispute.</p>

            <p><strong>22. GENERAL</strong></p>
            <p>22.1. Taxes: As a User of the Nairabox Services, you agree that you are solely responsible for determining your applicable local tax reporting requirements in consultation with your tax advisors. Nairabox cannot and does not offer tax-related advice to any Users. Additionally, please note that each Seller is responsible for determining local or national taxes and for including any applicable taxes to be collected or obligations relating to applicable taxes in Listings. You are also responsible for paying any local or national governmental taxes imposed on your purchase from or use of the Nairabox Services, including, but not limited to, sales, use, or value-added taxes (or local equivalent). To the extent Nairabox is required to collect such taxes, the applicable tax will be added to your billing account and Nairabox will issue a receipt on request from a Seller as required by law.</p>
            <p>22.2. Waiver: If Nairabox delays or fails to enforce any of the provisions of this Agreement, it shall not mean that Nairabox has waived the right to do so.</p>
            <p>22.3. Assignment: Nairabox shall be entitled to assign its rights and obligations under this Agreement provided that your rights are not adversely affected.</p>
            <p>22.4. Severability: If it is found by a court that any provision of this Agreement for any reason is invalid or cannot be enforced, this shall not prevent the other provisions from continuing to remain in full force and operate separately.</p>
            <p>22.5. Illegality: If any provision of this Agreement is or becomes illegal, invalid or unenforceable under the law of any jurisdiction, that shall not affect or impair the legality, validity or enforceability in that jurisdiction of any other provision of this Agreement.</p>
            <p>22.6. No Partnership/Agency: Nothing contained within this Agreement and no action taken by you or Nairabox under this Agreement shall create, or be deemed to create, a partnership, joint venture, or establish a relationship of principal and agent or any other relationship between you and Nairabox beyond the relationship created under this Agreement.</p>
            <p>22.7. Entire Agreement: This Agreement and all documents incorporated into this Agreement by reference constitutes the entire agreement between the parties in connection to its subject matter and supersedes any previous agreement, terms and conditions or arrangement between the parties, whether written or oral. Each of the parties agrees that it has not entered into this Agreement in reliance on and shall have no remedy in respect of any statement, representation, covenant, warranty, undertaking or indemnity (whether negligently or innocently made) by any person (whether party to this Agreement or not) other than as expressly set out in this Agreement. Nothing in this paragraph shall limit any liability for (or remedy in respect of) fraud or fraudulent misrepresentation.</p>
            <p>22.8. Notices: A notice provided under this Agreement shall be delivered upon receipt and shall be deemed to have been received (i) at the time of delivery, if delivered by hand, registered post or courier; or (ii) at the time of transmission if delivered by email (and no error message is received in response).22.9. Governing Law and Jurisdiction: This Agreement shall be governed by and construed in all respects in accordance with the law of the Federal Republic of Nigeria (where the Event for which you are Purchasing Tickets is taking place) and the parties agree to submit to the exclusive jurisdiction of the courts in Nigeria.</p>
           </p>

           <p><strong>Contact:</strong></p>
            <p>#5, Ayinde Akinmade Street, Off Admiralty Way, Lekki Peninsular Scheme 1, Lagos State of Nigeria.</p>
            <p>info@nairabox.com</p>
            <p>Tel: 09080000381</p>
          </section>

          </div>

        </div>

        <Footer />
      </div>;
  }
}