import axios from 'axios';
import env from '../../env.js';

const getEvent = (url) => ({
  type:'GET_EVENT',
  payload:axios(url)
})

export const onGetEvents = () => async (dispatch) => {
  const url=`${env(true)}events`
  dispatch(getEvent(url))
}