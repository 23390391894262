import React, { Component } from "react";
import "../../styles/basicsettings.css";
// import "../../styles/location.css";
import SideMenu from "./sidemenu";
import Header from "../../components/loggedInHeader";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';

class BasicLocation extends Component {
  render() {
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div className="acctSettings">
        <div>
        {isLogged || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        </div>
        <div className="row">
          <div className="col-sm-3">
            <div className="sideBar">
            <div className="account-settings">
        <nav className="nav flex-column">
          <Link to="/account" className="nav-link" href="#">
            Basic Settings
          </Link>
          <Link to="/password" className="nav-link" href="#">
            Password
          </Link>
          <Link to="/location" className="nav-link active" href="#">
            Location Settings
          </Link>
          <Link to="/email" className="nav-link" href="#">
            Email Preferences
          </Link>
          <Link to="/closeaccount" className="nav-link" href="#">
            Close Account
          </Link>
        </nav>
      </div>
            </div>
          </div>
          <div className="col-sm-9 mt-5 basicSetting" id="">
            <h3 className="basicsettings-title">Location Settings</h3>
            <div className="basicsettings-body">
              <h5>Update Location</h5>
              <div>
                <form>
                  <div className="col-12 form-body">
                    <div className="form-item no__lpadding">
                      <p className="formLabel">House Address</p>
                      <input
                        type="text"
                        name="street"
                        id="street"
                        className="form-style"
                        autocomplete="off"
                      />
                    </div>
                    <div className="row">
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">City</p>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">State</p>
                          <input
                            type="text"
                            name="state"
                            id="state"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-success">Update</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (BasicLocation);