import React from 'react';
import { onVerify } from '../store/actions/verifyPayment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

// import the library
import { Redirect } from 'react-router-dom'
import RavePaymentModal from 'react-ravepayment';
import { PaystackButton } from 'react-paystack';
import env from '../env';
import { withRouter } from "react-router";
// import "./styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from "jquery";
import swal from 'sweetalert';

class Payment extends React.Component {
	componentDidMount = async () => {
		localStorage.removeItem('state')
		let ref = (+ new Date()).toString(36) + Math.floor(Math.random() * Math.floor(17));
		this.setState({ref: ref})

		const { age, amount, firstname, email, phone, address, gender, instagram, twitter, day, person, people, time, membership_id, membership, venue, loca, currency, date, dayTime, eventDetails, order } = this.props;

		const userDetails = {
			amount: amount,
			eventDetails: eventDetails,
			classid: order,
			firstname: firstname,
			email: email,
			phone: phone,
			age: age,
			address: address,
			gender: gender,
			instagram: instagram,
			twitter: twitter,
			loca: loca,
			day: day,
			date: date,
			membership_id: membership_id,
			membership: membership,
			dayTime: dayTime,
			person: person,
			people: people,
			venue: venue,
			time: time,
			currency
		}

		this.setState({toSend: userDetails})

		try{
			let res = await axios.post(`${env(true)}prepayments`, {ref: ref, info: userDetails})
		}catch(e){
			console.log("prepayment-response-error: ", e.response? e.response.data : e)
		}
	};
	componentDidUpdate(prevProps, prevState) {
		localStorage.removeItem('state')
	}

	hideAlert = () => {

	}

	state = {
		key: env() == "local" || env() == "heroku" ? 'pk_test_38169a739b51c5c7139972b3caf86d4f3d4b94c7' : 'pk_live_41ed986b3773595273e995adf63505fa657e6bfc',
		email: '',
		amount: this.props.amount,
		ref: "",
		discount: '',
		discounted: '',
		currency: this.props.currency,
		toSend: {}
	};


	trxref = null

	callback = async (response) => {
		let affiliate = window && window.sessionStorage && window.sessionStorage.getItem('affiliate');

		if (this.trxref === (response && response.trxref)) {
			return;
		}
		this.trxref = (response && response.trxref);
		if (response && response.status === 'success') {			
			try{
				let res = await axios.post(`${env(true)}verify-payment-website`, {txRef: response.trxref, info: this.state.toSend, affiliateID: affiliate })
				console.log(res.data)
				for (let i = 0; i < res.data.responses.length; i++) {
					const ele = res.data.responses[i];
					if(ele.status){
						if (this.props.isInsuranceActive){ 
							axios.post(`${env(true)}merchant/paie`, {...(({ eventDetails, classDetails , people, firstname, email, }) => ({ eventDetails, classDetails , people, firstname, email, }))(this.props)}); console.log("Sending insurance..."); 
						}
						i == 0 ? window.location.href = ele.url : window.open(ele.url, '_blank');
					}else{
						//swal("Ticket Purchase", ele.message,  ele.status ? "success" : "error" ).then(() => {  });
					}
				}				
				swal("Ticket Purchase Successfully!", res.data.message, "success");
			}catch(e){
				console.log(e.response ? e.response.data : e)
				if(e.response && e.response.data){
				swal("Ticket issuing error", e.response.data.message, "error");
				}else{
					swal("Ticket issuing error", e.message, "error");
				}
			}
		} else {
			swal("Payment failed!", "Contact support@nairabox.com if you are having any issues buying a ticket", "error").then(() => {
			});
		}

	};

	close = () => {
		console.log('Payment closed');

	};



	render() {
		const { amount, firstname, email, phone, currency } = this.props;
		console.log(amount, "amount")
		return (
			<div className="App">

				<p className="App-intro">

					{/* <RavePaymentModal
						text="Make Payment"
						class="payButton btn btn-white-main"
						// meta data to be passed along to Rave.
						metadata={[
							{ metaname: 'firstname', metavalue: firstname },
							{ metaname: 'amount', metavalue: amount },
							// {metaname: 'eventId', metavalue: classid},
							{ metaname: 'eventName', metavalue: eventDetails },
							{ metaname: 'userDetails', metavalue: user }
						]}
						// Transaction Reference. The getReference generates random characters to be used as the transaction ref.
						reference={this.getReference()}
						// Customer's email
						email={email}
						// Amount to be charged
						amount={amount}
						customer_firstname={firstname}
						customer_phone={phone}
						//campaign_id={disc}
						// Your Rave Public Key
						ravePubKey={this.state.key}
						// The callback function after a successful payment. The function was created above
						callback={this.callback}
						// The close function after closing the modal. The function was created above
						close={this.close}
						isProduction={env() == "local" || env() == "heroku" ? false : true}
					/> */}

					<PaystackButton
						text="Make Payment"
						className="payButton btn btn-white-main"
						metadata={{info: JSON.stringify(this.state.toSend)}}
						reference={this.state.ref}
						// channels={['card', 'ussd', 'mobile_money', 'qr']}
						email={email}
						amount={parseFloat(amount) * 100}
						firstname={firstname}
						phone={phone}
						//campaign_id={disc}
						currency={ currency || 'NGN'}
						publicKey={this.state.key}
						onSuccess={this.callback}
						onClose={this.close}
					/>

				</p>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ verify: state.verify });

const mapDispatchToProps = (dispatch) => ({
	onVerify: bindActionCreators(onVerify, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));
