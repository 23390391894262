import React from 'react';
import App from './main';
import { BrowserRouter,HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';


const Root  = ({ store }) => ( <Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>
)

export default Root;