import axios from 'axios';
import env from '../../env.js';


const getOverviewEvent = (url) => ({
  type:'GET_OVERVIEW_EVENT',
  payload:axios(url)
})

export const onGetOverviewEvent = (id) => async (dispatch) => {
  const url=`${env(true)}sales/${id}`
  dispatch(getOverviewEvent(url))
}