import axios from 'axios';
import env from '../../env.js';

const getEventDaily = (url) => ({
  type:'GET_EVENT_DAILY',
  payload:axios(url)
})

export const onGetEventDaily = (id) => async (dispatch) => {
  const url=`${env(true)}merchant/daily-events/${id}`
  dispatch(getEventDaily(url))
}