import React, { Component } from "react";
import $ from "jquery";
//import '../../styles/password.css';
import "../../styles/basicsettings.css";
// import "../../styles/password.css";
import SideMenu from "./sidemenu";
import Header from "../../components/loggedInHeader";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {onLogin} from '../../store/actions/login';
import {onRegister} from '../../store/actions/register';
import LoggedInHeader from '../loggedInHeader';
import { Redirect } from 'react-router-dom';

class Password extends Component {
  render() {
    const {login:{isLogged}, register:{isLoggedIn}}=this.props;
    return (
      <div className="acctSettings">
        <div>
        {isLogged || <Redirect to="/"/>}
        {isLogged || isLoggedIn ?
        <LoggedInHeader/> :
        <Header />}
        </div>
        <div className="row pwd_S">
          <div className="col-sm-3 sideBar">
          <div className="account-settings">
        <nav className="nav flex-column">
          <Link to="/account" className="nav-link" href="#">
            Basic Settings
          </Link>
          <Link to="/password" className="nav-link active" href="#">
            Password
          </Link>
          <Link to="/location" className="nav-link" href="#">
            Location Settings
          </Link>
          <Link to="/email" className="nav-link" href="#">
            Email Preferences
          </Link>
          <Link to="/closeaccount" className="nav-link" href="#">
            Close Account
          </Link>
        </nav>
      </div>
          </div>
          <div className="col-md-9 mt-5 basicSetting" id="password-settings">
            <h3 className="basicsettings-title">Password Settings</h3>
            <div className="basicsettings-body">
              <h5>Change Password</h5>
              <div>
                <form>
                  <div className="col-12 form-body">
                    <div className="col-sm-6 no__lpadding">
                      <div className="form-item current">
                        <p className="formLabel">Current Password</p>
                        <input
                          type="password"
                          name="current_pwd"
                          id="current_pwd"
                          className="form-style"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">New Password</p>
                          <input
                            type="password"
                            name="new_pwd"
                            id="new_pwd"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 no__lpadding">
                        <div className="form-item">
                          <p className="formLabel">Confirm New Password</p>
                          <input
                            type="password"
                            name="confirm__npwd"
                            id="confirn__npwd"
                            className="form-style"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-success">Update</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
 
  login:state.login, 
    register:state.register, 
});

export default connect (mapStateToProps) (Password);