import React, { Component } from 'react';
import $ from 'jquery';
import '../../styles/createevent.css';
import '../../styles/formtype.css';

export default class PaidTicketInfo extends Component {
  state={
    paidTicket:[]
  }
  componentDidMount = () => {
    var formInputs = $ (
      'input[type="email"], input[type="text"], input[type="password"]'
    );
    formInputs.focus (function () {
      $ (this).parent ().children ('p.formLabel').addClass ('formTop');
      $ ('div#formWrapper').addClass ('darken-bg');
      $ ('div.logo').addClass ('logo-active');
    });
    formInputs.focusout (function () {
      if ($.trim ($ (this).val ()).length == 0) {
        $ (this).parent ().children ('p.formLabel').removeClass ('formTop');
      }
      $ ('div#formWrapper').removeClass ('darken-bg');
      $ ('div.logo').removeClass ('logo-active');
    });
    $ ('p.formLabel').click (function () {
      $ (this).parent ().children ('.form-style').focus ();
    });
  };

  onShowPaid = () => {
    let {paidTicket}=this.state;
    paidTicket=[...paidTicket,1]
    this.setState ({
      showPaid: true,
      paidTicket
    },()=>this.setState({eventType:"Paid"}));
  };

componentDidUpdate(prevProps,prevState,snapshot){
 let {paidTicket}= this.props
 if(paidTicket!==prevProps.paidTicket){
   this.setState({paidTicket})
 }
}


  onEnter = ({e,index}) => {
    const {onEnterEventDetails}=this.props;
    const {name, value} = e.target;
    const payload={[name]:value};
    const type="paidTicket"
    const classes={
      type,index,payload
    }
    onEnterEventDetails(classes)
	};
  render () {
    const {paidTicket}=this.state
    const{showPaid,onDeletePaidEvent,eventClassName,eventPrice,maxQuantity,ticketSummary, ticketStartDate, ticketEndDate, ticketsAllowedMin, ticketsAllowedMax, onEnter}=this.props
    return (
      showPaid&&paidTicket.map((val,key)=>
      <div  key={key}>
      <div className="card card-body card-ticket">
        <div className="row">
          <div className="col-lg-4">
            <p>Paid Ticket:</p>
            <div className="form-item">
              {/*<p className="formLabel">Early Bird, Regular, VIP</p>*/}
              <input
                type="text"
                name="eventClassName"
                id="eventClassName"
                className="form-style"
                autocomplete="off"
                placeholder="Early Bird, Regular, VIP"
                value={val.eventClassName}
                onChange={(e)=>this.onEnter({e,index:key})}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <p>Maximum Quantity:</p>
            <div className="form-item">
              {/*<p className="formLabel">200</p>*/}
              <input
                type="text"
                name="maxQuantity"
                id="maxQuantity"
                class="form-style"
                autocomplete="off"
                placeholder="200"
                value={val.maxQuantity}
                onChange={(e)=>this.onEnter({e,index:key})}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <p>Price:</p>
            <div className="form-item">
              {/*<p className="formLabel">10,000</p>*/}
              <input
                type="text"
                name="eventPrice"
                id="eventPrice"
                className="form-style"
                autocomplete="off"
                placeholder="10,000"
                value={val.eventPrice}
                onChange={(e)=>this.onEnter({e,index:key})}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <p>Action:</p>
            <div>
            <a
          className="ticket-cog"
          data-toggle="collapse"
          href="#collapseSetting2"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"

        >
              <img className=" ticket-offer__icon mr-2" src="image/setting.png" />
              </a>
              <span onClick={()=>onDeletePaidEvent({type:"paidTicket", index:key})}>
              <img className="ticket-offer__icon" src="/image/delete.png" /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="collapse" id="collapseSetting2">
              <div className="card card-body">
                <div className="">
                
                  <h5 className="form-content__label">Ticket description</h5>
                  <textarea
                  name="ticketSummary"
                  id="ticketSummary"
                  className="form-style ticket-desc"
                  value={val.ticketSummary}
                  onChange={(e)=>this.onEnter({e,index:key})}>
                  
                  </textarea>
                  </div>
                
                <div>
                  <div className="row ticket-label">
                    <div className="col-lg-6">
                      <h5 className="form-content__label">
                        Ticket sale starts
                      </h5>
                      <div className="form-item">
                        <p className="formLabel">
                          
                        </p>
                        <input
                          type="datetime-local"
                          name="ticketStartDate"
                          id="ticketStartDate"
                          className="form-style"
                          autocomplete="off"
                          value={val.ticketStartDate}
                          onChange={(e)=>this.onEnter({e,index:key})}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="form-content__label">Ticket sale ends</h5>
                      <div className="form-item">
                        <p className="formLabel">
                          
                        </p>
                        <input
                          type="datetime-local"
                          name="ticketEndDate"
                          id="ticketEndDate"
                          className="form-style"
                          autocomplete="off"
                          value={val.ticketEndDate}
                          onChange={(e)=>this.onEnter({e,index:key})}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="form-content__label">Ticket status</h5>
                    <button id="event-ticket-public" className="btn btn-light ticket-button__status">
                      Public
                    </button>
                    <button id="event-ticket-private" className="btn btn-light ticket-button__status">
                      Private
                    </button>
                  </div>
                  <div className="col-md-6">
                    <h5 className="form-content__label">Sales channel</h5>
                    <button id="event-ticket-everywhere" className="btn btn-light ticket-button__channel">
                      Everywhere
                    </button>
                    <button id="event-ticket-online" className="btn btn-light ticket-button__channel">
                      Online
                    </button>
                    <button id="event-ticket-outlet" className="btn btn-light ticket-button__channel">
                      Outlets
                    </button>
                  </div>
                </div>
                <div className="tickets-allow">
                  <h5 className="form-content__label">
                    Tickets allow per order
                  </h5>
                  <div className="row">

                    <div className="col-md-3">

                      <div className="form-item">
                        <p className="formLabel">1</p>
                        <input
                          type="number"
                          name="ticketsAllowedMin"
                          id="ticketsAllowedMin"
                          className="form-style"
                          autocomplete="off"
                          value={val.ticketsAllowedMin}
                          onChange={(e)=>this.onEnter({e,index:key})}
                        />
                      </div>
                      <p className="ticket-number">Minimum</p>
                    </div>

                    <div className="col-md-3">

                      <div className="form-item">
                        <p className="formLabel">10</p>
                        <input
                          type="number"
                          name="ticketsAllowedMax"
                          id="ticketsAllowedMax"
                          className="form-style"
                          autocomplete="off"
                          value={val.ticketsAllowedMax}
                          onChange={(e)=>this.onEnter({e,index:key})}
                        />
                      </div>
                      <p className="ticket-number">Maximum</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
    </div>)
    )
  }
}