import React, { Component } from 'react';
//import '../styles/homesearch.css';
import '../../App.css';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom"
class HomeSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keyword: '',
		};
	}

	onEnter = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	// onSubmit = (e) => {
	// 	e.preventDefault();
	// 	const { keyword, state } = this.state;
	// 	this.props.onSearch(keyword, state);
	// };
	render() {
		const { keyword } = this.state;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="s-search">
						<section className="search-section col-12 col-md-8">
							<h4>Find an event near you</h4>
							<form className="form-inline">
								<label className="sr-only" for="question">
									Question
								</label>
								<input
									type="text"
									className="form-control mb-2 search-home"
									id="question"
									name="keyword"
									placeholder="Search for an event by name, state or city..."
									value={keyword}
									onChange={this.onEnter}
								// required
								/>

								<label className="sr-only" for="location">
									Location
								</label>

								<Link to={(`/search/${keyword}`)} className="btn src-btn btn-primary mb-2 search-button">
									Search
								</Link>
							</form>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(HomeSearch);
