import React, { Component } from 'react';
//import BuyTicketModal from './buyTicketModal';
import BuyTicketModal from './buyTicketNew';

import Asis from './asis';
import ShareButton from 'react-social-share-buttons';
import { connect } from 'react-redux';
import { onGetOneEvent } from '../../store/actions/getOneEvent';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import { Link, Route } from "react-router-dom";

import EventDescriptionLeft from './EventDescriptionLeft';
import EventDescriptionPayment from './EventDescriptionPayment';
class EventDescription extends Component {
	state = {
		eventClasses: {},
		hidden: [],
		result: '',
		show: false,
		discounted: '',
		all: [],
	};

	onEnter = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	getFromChild = (values) => {
		this.setState(values);
	}

	displayBuy = (e) => {
		if (this.props.match.params.eventID == "5cc4b0bb8ead0eb2588b45df"
			|| this.props.match.params.eventID == "5cb78ee48ead0eb11c8b46aa"
			|| this.props.match.params.eventID == "5cb7443d8ead0efb138b4630"
			|| this.props.match.params.eventID == "5cb837bb8ead0ef0388b45f0"
			|| this.props.match.params.eventID == "5cb83a608ead0ebd398b45a1"
			|| this.props.match.params.eventID == "5cb83d028ead0efe388b4675"
			|| this.props.match.params.eventID == "5cb8428d8ead0efe388b471d"
			|| this.props.match.params.eventID == "5cb843b68ead0e313b8b4579"
			|| this.props.match.params.eventID == "5cb845478ead0e7e3a8b46e8"
			|| this.props.match.params.eventID == "5cb845548ead0e313b8b45a8"
		) {
			return this.state.result != 0 ? (

				<button
					class="btn btn-buy mr-2 mb-3"
					data-toggle="modal"
					data-target="#buyTicketModal"
				// onClick={this.showModal}
				>

					Register
		</button>
			) : (
					<button
						class="btn btn-buy mr-2 mb-3"

						onClick={this.showError}
					>
						Register
								</button>

				)
		}
		else {
			return this.state.result != 0 ? (

				<button
					class="btn btn-buy mr-2 mb-3"
					data-toggle="modal"
					data-target="#buyTicketModal"
				// onClick={this.showModal}
				>

					Buy Ticket
			</button>
			) : (
					<button
						class="btn btn-buy mr-2 mb-3"

						onClick={this.showError}
					>
						Buy Ticket
									</button>

				)
		}
	}

	handleSelectChange = (event) => {
		this.setState({
			result: event.target.value
		})
		//console.log(this.state.result)
	}

	// showModal = () => {
	// 	this.setState({ show: true });
	//   };
	showError = () => {
		alert("Select event class by selecting the number of ticket you wish to purchase")
	};

	componentDidUpdate(prevProp) {
		//const { event: { response: { event: details, classes } }, event } = this.props;
		let classes = this.props.classes
		
		// console.log('event: ',this.props);
		if (this.props.event.fetched !== prevProp.event.fetched) {
			let eventClasses = {};
			classes && classes.map((dclass) => (eventClasses[dclass._id] = 0));

			this.setState({ eventClasses });
		}
	}

	onClassAmountChanged(id, e) {
		// console.log(id);
		this.setState({ eventClasses: { ...this.state.eventClasses, [id]: e.target.value } });
		this.setState({
			all: {
				count: e.target.value,
				key: id
			}
		})
		this.setState({
			result: e.target.value
		})

	}

	render() {
		//const { event: { response: { event: details, classes } }, event, match } = this.props;
		let match = this.props.match
		let event = this.props.event
		let classes = this.props.classes
		const { name, summary, venue, date, startDate, endDate, bannerImage, _id } = event ? event : {};
		let t = classes ? classes[0] : {};

		let time = event.time;
		if(!time && (t && t.time)) {
			time = t.time
		}

		let ID = this.props.match.params.eventID
		

		const initialView = (
			<div>
				<div className="container-fluid">
					<h4 className="container-fluid event-header">{name}</h4>
				</div>
				<div className="header-section">
					<div className="container-fluid">
						<h4 className="container-fluid event-description ">Event Description</h4>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row">

						<EventDescriptionLeft summary={summary} date={date} venue={venue} startDate={startDate} endDate={endDate} time={time} ID={ID} _id={_id} bannerImage={bannerImage} name={name}/>

						<EventDescriptionPayment classes={classes} event={event} ID={ID} eventUrl={match.url} toParent={this.getFromChild}/>

					</div>
				</div>
			</div>
		);

		const payView = (
			<div>
				<div className="container-fluid">
					<h4 className="container-fluid event-header">{name}</h4>
				</div>
				<div className="header-section">
					<div className="container-fluid">
						<h4 className="container-fluid event-description ">Event Description</h4>
					</div>
				</div>
				<div className="container-fluid">
					<div className="number row">
						<BuyTicketModal  event={event} classes={classes} show={this.state.show} orders={this.props.orders}
							classOrders={this.state.eventClasses} mine={this.state.myOwn} history={this.props.history} />

						<Asis show={this.state.show} orders={this.props.orders} event={event} classes={classes}
							classOrders={this.state.eventClasses} mine={this.state.myOwn} history={this.props.history} />


					</div>
				</div>
			</div>
		);

		const laterView = (<div>

			<Asis show={this.state.show} orders={this.props.orders}
				classOrders={this.state.eventClasses} mine={this.state.myOwn} history={this.props.history} />
		</div>
		)

		return (
			<div>
				<Route exact path={`${match.url}`} render={(theProps) => initialView} />
				<Route exact path={`${match.url}/buyticket`} render={(theProps) => payView} />

			</div>
		);




	}
}

const mapStateToProps = (state) => ({
	oneEvent: state.oneEvent
});

const mapDispatchToProps = (dispatch) => ({
	onGetOneEvent: bindActionCreators(onGetOneEvent, dispatch)
});

//export default connect (mapStateToProps, mapDispatchToProps)
export default withRouter(EventDescription);
