import React, { Component } from 'react'
import '../../App.css'
import { Link } from 'react-router-dom';

export default class Slide extends Component {

  render() {
    let { events } = this.props;
    let ctr = 0;
    console.log('events in slider: ', events);
    return (
      <div id='fullCarousel' className='carousel slide' data-ride='carousel'>
        <div className='carousel-inner'>

          {/* <div className='carousel-item active '>
            <div className='img'>
              <img className='d-block img-fluid' src='https://res.cloudinary.com/dwa8k0pds/image/upload/v1558449759/Nairabox_Nokia_4.2_1350x500.jpg' alt='...' />
            </div>
          </div> */}

          {/* <div className='carousel-item active'>
            <div className='img'>
              <img className='d-block img-fluid' src='https://res.cloudinary.com/dwa8k0pds/image/upload/v1575968581/Website-Event-Banners.gif' alt='...' />
            </div>
          </div> */}

          {/* <div className='carousel-item active'>
            <div className='img'>
              <img className='d-block img-fluid' src='/image/slider images.jpg' alt='...' />
            </div>
            <div className='carousel-caption d-none d-md-block'>
              <h5>Bringing you<br />closer to unforgettable experiences.</h5>
            </div>
          </div> */}
          {
            //start dynamic slider            
            events && events.map(({ bannerImage, name, _id }, key) => {              
              return (
                <div className={`carousel-item ${key == 0 ? "active" : ""}`}>
                
                  <div className='img'>
                    <Link to={`/event/${_id}`} > <img className='d-block img-fluid' src={bannerImage} alt={name} /> </Link>

                  </div>
                </div>
              )
            })

            //end dynamic slider
          }


          {/* <div className='carousel-item '>
            <div className='img'>
              <img className='d-block img-fluid' src='https://res.cloudinary.com/dwa8k0pds/image/upload/v1543325242/Nairabox_GSM_1350_by_500.jpg' alt='...' />
            </div>
          </div> */}
        </div>
        {/* <a
                          className='carousel-control-prev'
                          href='#fullCarousel'
                          role='button'
                          data-slide='prev'><span className='carousel-control-prev-icon' aria-hidden='true' /> <span className='sr-only'>Previous</span></a>
                        <a
                          className='carousel-control-next'
                          href='#fullCarousel'
                          role='button'
                          data-slide='next'><span className='carousel-control-next-icon' aria-hidden='true' /> <span className='sr-only'>Next</span></a> */}
      </div>
    )
  }
}
