import React, { Component } from 'react'
import '../../styles/sidemenu.css'
import { Link } from 'react-router-dom'

export default class SideMenu extends Component {
  render () {
    return (
      <div className='account-settings'>
        <nav className='nav flex-column'>
          <Link to='/account' className='nav-link active' href='#'> Basic Settings
          </Link>
          <Link to='/password' className='nav-link' href='#'> Password
          </Link>
          <Link to='/location' className='nav-link' href='#'> Location Settings
          </Link>
          <Link to='/email' className='nav-link' href='#'> Email Preferences
          </Link>
          <Link to='/closeaccount' className='nav-link' href='#'> Close Account
          </Link>
        </nav>
      </div>
    )
  }
}
